import { useState, useContext } from 'react';
import Layout from '../../components/admin/Layout';
import ContextStates from '../../context/ContextStates';
import ListadoProductos from '../../components/admin/listadoProductos';
import ListadoProductosAlert from '../../components/admin/listadoProductosAlert';
import ListadoProductosVto from '../../components/admin/listadoProductosVto';
import FormBusqueda from '../../components/forms/formBusqueda';
import { Link } from 'react-router-dom';
import { FaPlus } from "react-icons/fa";

const Productos = () => {
	const [tab, setTab] = useState(0)
	const { setFormView, setViewOpen } = useContext(ContextStates)

	const handleAdd = () => {
		setFormView(5)
		setViewOpen(true)
	}

	return (
		<Layout
			pagina={"Productos"}
		>
			<div className='w-full'>
				<div className='w-full flex bg-black px-20 h-14 pt-5'>
					<button className={`text-center p-2 rounded-tl-xl rounded-tr-xl mr-1 ${tab === 0 ? 'bg-white text-black' : 'bg-gray-800 text-white'}`} onClick={() => setTab(0)}>Productos</button>
					<button className={`text-center p-2 rounded-tl-xl rounded-tr-xl mr-1 ${tab === 1 ? 'bg-white text-black' : 'bg-gray-800 text-white'}`} onClick={() => setTab(1)}>Productos con stock bajo</button>
					<button className={`text-center p-2 rounded-tl-xl rounded-tr-xl mr-1 ${tab === 2 ? 'bg-white text-black' : 'bg-gray-800 text-white'}`} onClick={() => setTab(2)}>Productos con Vto Próximo</button>
				</div>
				<div className='w-full p-2 min-h-full'>
					{tab === 0 ? (
						<>
							<h1 className='mt-10 mb-5 text-gray-600 text-3xl text-center'>Productos</h1>
							<div className='w-full h-screen mt-2 bg-white'>
								<div className='w-full h-16 py-2 px-10 bg-slate-50 flex'>
									<Link to={"/admin/altaproductos"} >
										<button type='button'
											className='bg-indigo-600 hover:bg-indigo-400 p-3 rounded-md text-white uppercase flex'
										>
											<FaPlus className='mr-2 text-xl' />Agregar Productos
										</button>
									</Link>
									<div className='w-3/4'>
										<FormBusqueda />
									</div>
								</div>
								<div className='w-full h-full p-10 bg-white'>
									<ListadoProductos />
								</div>
							</div>
						</>
					) : tab === 1 ? (
						<>
							<h2 className='text-center text-gray-700 text-sm md:text-xl'>Productos con stock bajo</h2>
							<div className='w-10/12 mx-auto flex mb-4'>
								<FormBusqueda />
							</div>
							<ListadoProductosAlert />
						</>
					) : (
						<>
							<h2 className='text-center text-gray-700 text-sm md:text-xl'>Productos con stock bajo</h2>
							<div className='w-10/12 mx-auto flex mb-4'>
								<FormBusqueda />
							</div>
							<ListadoProductosVto />
						</>
					)}
				</div>
			</div>
		</Layout>
	)
}

export default Productos