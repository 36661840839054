import { useContext, useRef } from 'react'
import ContextStates from '../../context/ContextStates'
import ReactToPrint from 'react-to-print';
import Barcode from 'react-barcode';
import { FaPrint } from "react-icons/fa";

const FViewProducto = () => {
    const { productoSelected, setProductoSelected, setViewOpen } = useContext(ContextStates)
    const componentRef = useRef();

    return (

        <div className="transition-all w-full pt-5 sm:p-3 mt-5">
            <h2 className='text-center text-gray-700 text-xl'>{productoSelected.descripcion}</h2>
            <div className="w-full mt-5 py-2 px-3 rounded-2xl">
                <div className='print:px-4 print:text-xs print:h-svh' ref={componentRef}>
                    <div className='w-1/2 mx-auto justify-items-center'>
                        <p className='text-center'>{productoSelected.descripcion}</p>
                        <Barcode className="mx-auto" value={productoSelected.codigo} />
                        <p className='text-center text-2xl'>$ {parseFloat(productoSelected.precio).toLocaleString("es-AR", { maximumFractionDigits: 2 })}</p>
                    </div>
                </div>
                <div className="w-full rounded-lg px-3">
                    <ReactToPrint
                        trigger={() =>
                            <button
                                type="button"
                                className="w-1/3 sm:w-1/4 flex shadow-md p-3 ml-3 bg-indigo-500 text-white hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                            >
                                <FaPrint className='mx-auto mt-1' />
                                <p className='mx-auto text-center'>
                                    Imprimir Código
                                </p>
                            </button>}
                        content={() => componentRef.current}
                    />
                    <button
                        type="button"
                        className={`w-full shadow-md p-3 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5 bg-orange-600`}
                        onClick={()=>setViewOpen(false)}
                    >
                        Salir
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FViewProducto