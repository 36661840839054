import { useState, useContext } from 'react';
import Layout from '../../components/admin/Layout';
import ContextStates from '../../context/ContextStates';
import ListadoMarcas from '../../components/admin/listadoMarcas';
import FormBusquedaMarca from '../../components/forms/formBusquedaMarca';
import { FaPlus } from "react-icons/fa";

const Marcas = () => {
    const { setFormView, setViewOpen } = useContext(ContextStates)

    const handleAdd = () => {
        setFormView(4)
        setViewOpen(true)
    }

    return (
        <Layout
            pagina={"Productos"}
        >
            <div className='w-full'>
                <div className='w-full p-2 min-h-full'>
                    <h2 className='mt-10 mb-5 text-gray-600 text-3xl text-center'>Marcas</h2>
                    <div className='w-full h-16 py-2 px-10 bg-slate-50 flex'>
                        <div className='w-1/4'>
                            <button type='button'
                                className='bg-indigo-600 hover:bg-indigo-400 p-3 rounded-md text-white uppercase flex'
                                onClick={() => handleAdd()}
                            >
                                <FaPlus className='mr-2 text-xl' />Agregar Marcas
                            </button>
                        </div>
                        <div className='w-3/4'>
                            <FormBusquedaMarca />
                        </div>
                    </div>
                    <div className='w-full h-full p-10 bg-white'>
                        <ListadoMarcas />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Marcas