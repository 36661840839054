import React, { useContext, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import authContext from '../../context/auth/authContext';
import ContextStates from '../../context/ContextStates';
import SideBar from './SideBar';
import Login from '../../pages/admin/Login';
import axios from 'axios';
import { Url } from '../Url';

const Layout = ({ children, pagina }) => {
	const { setMail, setViewSearch, mail, idUsuario, setIdUsuario, setRoles, setPuntoVenta } = useContext(ContextStates);
	const AuthContext = useContext(authContext);
	const { autenticado, usuarioAutenticado } = AuthContext;
	//const id=localStorage.getItem('pvp_id');

	useEffect(() => {
		const getRoles = async () => {
			if (idUsuario) {
				const formData = new FormData()

				formData.append('idusuario', idUsuario);

				try {
					const response = await axios({
						url: Url + 'api/rolesusrLayout.php',
						method: 'POST',
						data: formData,
					})
					//console.log("roles ", response)
					setRoles(response.data.results.roles)
				} catch (e) {
					console.log(e)
				}
			}
		}
		getRoles()
	}, [idUsuario])

	useEffect(() => {
		setViewSearch(false)
		usuarioAutenticado()
		if (localStorage.getItem('pvp_id'))
			setIdUsuario(localStorage.getItem('pvp_id'));
		else
			setMail(null)
		if (localStorage.getItem('pvp_em'))
			setMail(localStorage.getItem('pvp_em'));
		else
			setMail(null)
		if (localStorage.getItem('pvp_pv'))
			setPuntoVenta(localStorage.getItem('pvp_pv'));
	}, [autenticado]);

	return (
		<HelmetProvider>
			<Helmet>
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
				<title>Gestión - Punto de Venta {pagina}</title>
				<meta name="description" content="" />
				<meta property="og:title" content="Gestión - Punto de Venta" />
				<meta property="og:description" content="" />
				<meta property="og:url" content="https://mirx.com.ar" />
				<meta property="og:site_name" content="Gestión - Punto de Venta" />
				<meta property="og:locale" content="es_AR" />
				<meta property="og:type" content="article" />
			</Helmet>
			<div>
				{
					mail ? (
						<>
							<div className='flex'>
								<SideBar />
								{children}
							</div>
						</>
					) : (
						<Login />
					)
				}
			</div>
		</HelmetProvider>
	)
}

export default Layout