import { useState } from 'react';
import Layout from '../../components/admin/Layout';
import FormLocal from '../../components/admin/formLocal';
import FormPerfil from '../../components/admin/formPerfil';
import FormFacturacion from '../../components/admin/formFacturacion';
import ListadoPuntos from '../../components/admin/listadoPuntos';
import FormPunto from '../../components/admin/formPunto';

const Configuracion = () => {
	const [tab, setTab] = useState(0)
	return (
		<Layout
			pagina={"Configuración"}
		>
			<div className='w-full'>
				<div className='w-full flex bg-black'>
					<button className={`text-center p-2 rounded-tl-xl rounded-tr-xl mr-1 ${tab === 0 ? 'bg-white text-black' : 'bg-gray-800 text-white'}`} onClick={() => setTab(0)}>Datos del Local</button>
					<button className={`text-center p-2 rounded-tl-xl rounded-tr-xl mr-1 ${tab === 1 ? 'bg-white text-black' : 'bg-gray-800 text-white'}`} onClick={() => setTab(1)}>Puntos de Venta</button>
					<button className={`text-center p-2 rounded-tl-xl rounded-tr-xl mr-1 ${tab === 2 ? 'bg-white text-black' : 'bg-gray-800 text-white'}`} onClick={() => setTab(2)}>Facturación</button>
					<button className={`text-center p-2 rounded-tl-xl rounded-tr-xl mr-1 ${tab === 3 ? 'bg-white text-black' : 'bg-gray-800 text-white'}`} onClick={() => setTab(3)}>Perfil</button>
				</div>
				<div className='w-full p-2 min-h-full'>
					{tab === 0 ? (
						<FormLocal />
					): tab === 1 ? (
						<ListadoPuntos />
					): tab === 2 ? (
						<FormFacturacion />
					) : tab === 3 ? (
						<FormPerfil />
					) : null }
				</div>
			</div>
		</Layout>
	)
}

export default Configuracion