import { useState } from 'react';
import Layout from '../../components/admin/Layout';
import FormPrecios from '../../components/admin/formPrecios';

const GestionArticulos = () => {
	const [ tab, setTab ] = useState(0)
	return (
		<Layout
			pagina={"Configuración"}
		>
			<div className='w-full'>
			<div className='w-full flex bg-black'>
				<button className={`text-center p-2 rounded-tl-xl rounded-tr-xl mr-1 ${tab===0 ? 'bg-white text-black':'bg-gray-800 text-white'}`} onClick={()=>setTab(0)}>Precios</button>
				<button className={`text-center p-2 rounded-tl-xl rounded-tr-xl mr-1 ${tab===1 ? 'bg-white text-black':'bg-gray-800 text-white'}`} onClick={()=>setTab(1)}></button>
				<button className={`text-center p-2 rounded-tl-xl rounded-tr-xl mr-1 ${tab===2 ? 'bg-white text-black':'bg-gray-800 text-white'}`} onClick={()=>setTab(2)}></button>
			</div>
			<div className='w-full p-5 min-h-full'>
				{ tab===0 ? (
					<FormPrecios/>
				):tab===1 ? (
					<></>
				):(
					<></>
				)}
			</div>
			</div>
		</Layout>
	)
}

export default GestionArticulos