import React, { useContext, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "./Header"
import Footer from "./Footer"
import LoginAdmin from '../pages/admin/Login';
import authContext from '../context/auth/authContext';
import ContextStates from '../context/ContextStates';
import SearchBar from './SearchBar';
import axios from 'axios';
import { Url } from './Url';

const Layout = ({ children, pagina }) => {
	const { setMail, setViewSearch, mail, idUsuario, setIdUsuario, setRoles, setPuntoVenta, idJornada, setIdJornada, setEstadoJornada, setNombre } = useContext(ContextStates);
	const AuthContext = useContext(authContext);
	const { autenticado, usuarioAutenticado } = AuthContext;

	useEffect(() => {
        const query = async () => {
            const formData = new FormData()
            try {
                const response = await axios({
                    url: Url + 'api/getPV.php',
                    method: 'POST',
                    data: formData,
                })
                if (typeof response.data.results!='undefined') {
                    setNombre(response.data.results.nombre)
                }
            } catch (e) {
                console.log(e)
            }
        }
        query();
    }, [])

	useEffect(() => {
		const getRoles = async () => {
			if (idUsuario) {
				const formData = new FormData()

				formData.append('idusuario', idUsuario);

				try {
					const response = await axios({
						url: Url + 'api/rolesusrLayout.php',
						method: 'POST',
						data: formData,
					})
					//console.log("resultado inesperado ",response)
					//console.log("roles ", response.data.results)
					if (typeof response.data.results!=='undefined')
						setRoles(response.data.results.roles)
				} catch (e) {
					console.log(e)
				}
			}
		}
		getRoles()
	}, [idUsuario])

	useEffect(() => {
        const getJornada = async () => {
            const formData = new FormData()
            formData.append('idusuario', idUsuario);
			if (idUsuario!==0) {
				try {
					const results = await axios({
						url: Url + 'api/getJornada.php',
						method: 'POST',
						data: formData,
					})
					console.log("resultado jornada ",results)
					if (typeof results.data.results!=='undefined') {
						if (results.data.results.estado==='2') {
							localStorage.removeItem("pvp_jnd")
							setEstadoJornada(0)
							localStorage.removeItem("pvp_st_jnd")
							setIdJornada(0)        
						}                    
					}
					else {
						localStorage.removeItem("pvp_jnd")
						setEstadoJornada(0)
						localStorage.removeItem("pvp_st_jnd")
						setIdJornada(0)
					}
	
				} catch (e) {
					console.log(e)
				}	
			}
        }
        getJornada()
    }, [idUsuario])

	useEffect(() => {
		setViewSearch(false)
		usuarioAutenticado()
		if (localStorage.getItem('pvp_id'))
			setIdUsuario(localStorage.getItem('pvp_id'));
		else
			setMail(null)
		if (localStorage.getItem('pvp_em'))
			setMail(localStorage.getItem('pvp_em'));
		else
			setMail(null)
		if (localStorage.getItem('pvp_pv'))
			setPuntoVenta(localStorage.getItem('pvp_pv'));
		if (localStorage.getItem('pvp_jnd'))
			setIdJornada(localStorage.getItem('pvp_jnd'))
		if (localStorage.getItem('pvp_st_jnd'))
			setEstadoJornada(localStorage.getItem('pvp_st_jnd'))
	}, [autenticado]);

	return (
		<HelmetProvider>
			<Helmet>
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
				<title>Punto de Venta {pagina}</title>
				<meta name="description" content="" />
				<meta property="og:title" content="Punto de Venta" />
				<meta property="og:description" content="" />
				<meta property="og:image" content="" />
				<meta property="og:url" content="https://mirx.com.ar" />
				<meta property="og:site_name" content="Punto de Venta" />
				<meta property="og:locale" content="es_AR" />
				<meta property="og:type" content="article" />
			</Helmet>
			<div>
				{
					mail ? (
						<>
							<Header />
							<SearchBar />
							{children}
							<Footer />
						</>
					) : (
						<LoginAdmin />
					)
				}


			</div>
		</HelmetProvider>
	)
}

export default Layout