import { useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'


const FDelPrestamo = () => {
    const { prestamoSelected, setViewOpen, modify, setModify } = useContext(ContextStates)


    const setPrestamo = async () => {
        const formData = new FormData()
        formData.append('id', prestamoSelected.id);

        try {
            const results = await axios({
                url: Url + 'api/delPrestamo.php',
                method: 'POST',
                data: formData,
            })
            let aux = modify + 1;
            setModify(aux)
            setViewOpen(false)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="w-full py-5 px-20 rounded-2xl">
            <div className="transition-all w-full pt-5 sm:p-3 mt-5">
                <h2 className='text-center text-gray-700 text-2xl'>Eliminar Prestamo de Envases</h2>
                <p className='p-1 text-left text-lg text-gray-600'><span className='font-bold'>Cliente: </span>{`${prestamoSelected.apellido}, ${prestamoSelected.nombre}`}</p>
                <p className='p-1 text-left text-lg text-gray-600'><span className='font-bold'>Domicilio: </span>{prestamoSelected.domicilio}</p>
                <p className='p-1 text-left text-lg text-gray-600'><span className='font-bold'>Teléfono: </span>{prestamoSelected.telefono}</p>
                <p className='p-1 text-left text-lg text-gray-600'><span className='font-bold'>Tipo de Envase: </span>{prestamoSelected.envase}</p>
                <p className='p-1 text-left text-lg text-gray-600'><span className='font-bold'>Cantidad: </span>{prestamoSelected.cantidad}</p>
            </div>
            <div className="pb-10">
                <div className="w-full rounded-lg px-3">
                    <button
                        type="button"
                        className={`w-full shadow-md p-3 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5 bg-orange-600`}
                        onClick={() => setPrestamo()}
                    >
                        Eliminar Prestamo
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FDelPrestamo