import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Url } from "./Url";
import ContextStates from "../context/ContextStates";
import { Link } from "react-router-dom"
import { motion } from 'framer-motion';
import { areaAnimateLR } from './Animations';


const SearchList = () => {
    const [ resultado, setResultado ] = useState([])
    const { textSearch } = useContext(ContextStates);

    useEffect(() => {
		const checkPedidos = async() => {
            const formData=new FormData()
            formData.append('search',textSearch);
            try{
                const results = await axios({
                    url: Url+'api/searchList.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(results)
                setResultado(results.data.results);
            } catch (e) {
                console.log(e)
            }
		}
		checkPedidos()
	},[textSearch])
    
    return (
        <div className="w-10/12 mx-auto mt-5 mb-20">
            <h1 className="text-center text-gray-600 text-2xl">Resultado de la búsqueda </h1>
            <div className="w-full"></div>
            { resultado && resultado.map((item,index)=>(
                <motion.div 
                    className="sm:w-1/3 w-full mx-auto" key={index}
                    initial={"offscreen"}
                    whileInView={"onscreen"}
                    viewport={{once:true, amount: 0.1}}
                    variants={areaAnimateLR}
                >
                    <Link to={`/producto/${item.id}`}>
                        <img className="" src={Url+item.imagen}/>
                        <h3 className="text-center text-2xl font-semibold uppercase">{item.marca}</h3>
                        <p className="text-center text-xl">{item.descripcionproducto}</p>
                        <p className="text-center text-lg">{item.descripcion}</p>
                    </Link>
                </motion.div>
            ))}
        </div>
    )
}

export default SearchList