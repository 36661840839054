import { useState, useContext } from 'react';
import Layout from '../../components/admin/Layout';
import ContextStates from '../../context/ContextStates';
import ListadoRubros from '../../components/admin/listadoRubros';
import ListadoJornadas from '../../components/admin/listadoJornadas';
import { FaPlus } from "react-icons/fa";

const JornadasAdmin = () => {
    const { setFormView, setViewOpen } = useContext(ContextStates)

    const handleAdd = () => {
        setFormView(6)
        setViewOpen(true)
    }

    return (
        <Layout
            pagina={"Jornadas"}
        >
            <div className='w-full'>
                <div className='w-full p-2 min-h-full'>
                    <h2 className='mt-10 mb-5 text-gray-600 text-3xl text-center'>Jornadas</h2>
                    <div className='w-full h-full p-10 bg-white'>
                        <ListadoJornadas />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default JornadasAdmin