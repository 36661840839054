import { useState, useEffect, useRef, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from 'axios'
import { Url } from '../Url'
import { FaPlus } from "react-icons/fa";


const FEditRubro = () => {
    const { rubroSelected, setFormView, setViewOpen, modify, setModify } = useContext(ContextStates)



    const formik = useFormik({
        initialValues: {
            descripcion: '',
        },
        validationSchema: Yup.object({
            descripcion: Yup.string()
                .required("La descripcion es obligatoria"),
        }),
        onSubmit: async (valores, { resetForm }) => {
            const formData = new FormData()
            formData.append('id', rubroSelected.id);
            formData.append('descripcion', valores.descripcion);
            try {
                const results = await axios({
                    url: Url + 'api/setRubros.php',
                    method: 'POST',
                    data: formData,
                })
                let aux=modify+1;
                setModify(aux)
                //setValMarca(results.data.results.id)
                resetForm()
                setViewOpen(false)
            } catch (e) {
                console.log(e)
            }
        }
    });

    useEffect(() => {
        formik.setFieldValue("descripcion",rubroSelected.descripcion)
    }, [])


    return (
        <form
            className="w-full py-5 px-20 rounded-2xl"
            onSubmit={formik.handleSubmit}
        >
            <div className="transition-all w-full pt-5 sm:p-3 mt-5">
                <h2 className='text-center text-gray-700 text-xl'>Editar Rubros</h2>
                <div className="sm:items-start">

                    <label htmlFor="descripcion" className="w-full block mt-5 text-black uppercase font-bold">Descripción</label>
                    <input
                        className="py-2 px-3 w-full border border-gray-300 rounded"
                        id="descripcion"
                        placeholder="Descripción"
                        type="text"
                        value={formik.values.descripcion}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoFocus
                    />
                    {formik.touched.descripcion && formik.errors.descripcion ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.descripcion}</p>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="pb-10">
                <div className="w-full rounded-lg px-3">
                    <input
                        type="submit"
                        className="w-full shadow-md p-3 bg-orange-600 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5"
                        value="Guardar Datos"
                    />
                </div>
            </div>
        </form>
    )
}

export default FEditRubro