import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import { Link } from 'react-router-dom'
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from 'axios'
import { Url } from '../Url'
import { FaPlus } from "react-icons/fa";


const FormProducto = () => {
    const [marcas, setMarcas] = useState([])
    const [marca, setMarca] = useState(0)
    const [rubros, setRubros] = useState([])
    const [rubro, setRubro] = useState(0)
    const [unidades, setUnidades] = useState([])
    const [unidad, setUnidad] = useState(1)
    const [guardar, setGuardar] = useState(false)
    const { modify, setModify, setFormView, setViewOpen, valMarca, valRubro } = useContext(ContextStates)

    useEffect(() => {
        const consultaMarcas = async () => {
            const url = Url + 'api/listadomarcas.php';
            const resultado = await axios.get(url);
            console.log(resultado);
            setMarcas(resultado.data.results);
            setMarca(resultado.data.results[0].id)
        }
        consultaMarcas()
    }, [valMarca])

    useEffect(() => {
        const consultaRubros = async () => {
            const url = Url + 'api/listRubros.php';
            const resultado = await axios.get(url);
            setRubros(resultado.data.results);
            setRubro(resultado.data.results[0].id)
        }
        consultaRubros()
    }, [valRubro])

    useEffect(() => {
        const consultaUnidades = async () => {
            const url = Url + 'api/listunidades.php';
            const resultado = await axios.get(url);
            setUnidades(resultado.data.results);
            setUnidad(resultado.data.results[0].id)
        }
        consultaUnidades()
    }, [])


    const formik = useFormik({
        initialValues: {
            codigo: '',
            descripcion: '',
            descripcionExt: '',
            precio: '',
            precioCosto: '',
            precioPromo: '',
            precioMayor: '',
            fechaVto: '',
            stock: '',
            stockMin: ''
        },
        validationSchema: Yup.object({
            codigo: Yup.string()
                .required("El código es necesario"),
            descripcion: Yup.string()
                .required("La descripcion es necesaria"),
            descripcionExt: Yup.string(),
            precio: Yup.string()
                .required("El precio es necesario"),
            precioCosto: Yup.string(),
            precioPromo: Yup.string(),
            precioMayor: Yup.string(),
            stock: Yup.string()
                .required("El stock es necesario"),
            stockMin: Yup.string()
                .required("El stock mínimo es necesario"),
        }),
        onSubmit: async (valores, { resetForm }) => {
            if (guardar) {
                const formData = new FormData()
                formData.append('codigo', valores.codigo);
                formData.append('descripcion', valores.descripcion);
                formData.append('descripcionext', valores.descripcionExt);
                formData.append('idmarca', marca);
                formData.append('idrubro', rubro);
                formData.append('unidad', unidad);
                formData.append('precio', valores.precio);
                formData.append('preciocosto', valores.precioCosto);
                formData.append('preciopromo', valores.precioPromo);
                formData.append('preciomayor', valores.precioMayor);
                formData.append('fechavto', valores.fechaVto);
                formData.append('stock', valores.stock);
                formData.append('stockMin', valores.stockMin);
                try {
                    const results = await axios({
                        url: Url + 'api/altaProductos.php',
                        method: 'POST',
                        data: formData,
                    })
                    let aux = modify + 1;
                    setModify(aux)

                    ///console.log(results)
                    //setValProducto(results.data.results.id);
                    resetForm()
                } catch (e) {
                    console.log(e)
                }

            }
        }
    });

    useEffect(() => {
        const checkProducto = async() => {
            const formData = new FormData()
                formData.append('codigo', formik.values.codigo);
                
                try {
                    const results = await axios({
                        url: Url + 'api/checkProducto.php',
                        method: 'POST',
                        data: formData,
                    })
                    if (results.data.results)
                    {
                        formik.setFieldValue('descripcion',results.data.results.descripcion);
                        formik.setFieldValue('descripcionExt',results.data.results.descripcionext);
                        setRubro(results.data.results.idrubro)
                        setMarca(results.data.results.idmarca)
                        formik.setFieldValue('precio',results.data.results.precio);
                        formik.setFieldValue('precioCosto',results.data.results.preciocosto);
                        formik.setFieldValue('stock',results.data.results.stock);
                        formik.setFieldValue('stockMin',results.data.results.alerta);
                        formik.setFieldValue('precioPromo',results.data.results.preciopromocion);
                        formik.setFieldValue('precioMayor',results.data.results.preciopormayor);
                        formik.setFieldValue('fechaVto',results.data.results.fechavto);
                        setUnidad(results.data.results.unidadmedida);
                    }
                    else
                    {
                        formik.setFieldValue('descripcion','');
                        formik.setFieldValue('descripcionExt','');
                        setRubro(1)
                        setMarca(1)
                        formik.setFieldValue('precio','');
                        formik.setFieldValue('precioCosto','');
                        formik.setFieldValue('stock','');
                        formik.setFieldValue('stockMin','');
                        formik.setFieldValue('precioPromo','');
                        formik.setFieldValue('precioMayor','');
                        formik.setFieldValue('fechaVto','');
                        setUnidad(1);
                    }
                    
                } catch (e) {
                    console.log(e)
                }
        }
        checkProducto()
    }, [formik.values.codigo])

    useEffect(() => {
        const checkForm = () => {
            let error = 0;
            if (formik.values.descripcion === '')
                error = 1;
            if (formik.values.precio === '')
                error = 1;
            if (formik.values.codigo === '')
                error = 1;
            if (formik.values.stock === '')
                error = 1;
            if (rubro === 0)
                error = 1;
            if (unidad === 0)
                error = 1;

            if (error === 0)
                setGuardar(true)
            else
                setGuardar(false)
        }
        checkForm()
    }, [formik.values.descripcion, formik.values.codigo, formik.values.precio, formik.values.stock, rubro, unidad])


    const handleAdd = (form) => {
        setFormView(form)
        setViewOpen(true)
    }

    return (

        <div className="transition-all w-full pt-5 sm:p-3 mt-5">
            <h2 className='text-center text-gray-700 text-xl'>Alta de Productos</h2>
            <form
                className="w-full mt-5 py-2 px-3 rounded-2xl"
                onSubmit={formik.handleSubmit}
            >
                <div className='flex'>
                    <div className='w-1/2 mx-auto m-3 p-2'>
                        <div className='p-3 bg-white border border-gray-200 rounded-lg shadow-xl'>
                            <h4 className='text-center text-gray-700 uppercase'>Producto, rubro y marca</h4>
                            <div className="sm:items-start">
                                <label htmlFor="codigo" className="block mt-5 text-gray-600 uppercase">Código</label>
                                <input
                                    className="py-2 px-3 w-full border border-gray-300 rounded"
                                    id="codigo"
                                    placeholder="Código de producto"
                                    type="text"
                                    value={formik.values.codigo}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    autoFocus
                                />
                                {formik.touched.codigo && formik.errors.codigo ? (
                                    <div className='w-full block text-red-500'>
                                        <p>{formik.errors.codigo}</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className="sm:items-start">
                                <label htmlFor="descripcion" className="block mt-5 text-gray-600 uppercase">Descripción</label>
                                <input
                                    className="py-2 px-3 w-full border border-gray-300 rounded"
                                    id="descripcion"
                                    placeholder="Descripción"
                                    type="text"
                                    value={formik.values.descripcion}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.descripcion && formik.errors.descripcion ? (
                                    <div className='w-full block text-red-500'>
                                        <p>{formik.errors.descripcion}</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className="sm:items-start mt-5">
                                <label htmlFor="descripcionExt" className="block text-gray-600 uppercase">Descripción detallada</label>
                                <input
                                    className="py-2 px-3 w-full border border-gray-300 rounded"
                                    id="descripcionExt"
                                    placeholder="Ingrese una descripción detallada"
                                    type="text"
                                    value={formik.values.descripcionExt}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.descripcionExt && formik.errors.descripcionExt ? (
                                    <div className='w-full block text-red-500'>
                                        <p>{formik.errors.descripcionExt}</p>
                                    </div>
                                ) : null}
                            </div>
                            <label htmlFor="sRubro" className="w-full block mt-5 text-gray-600 uppercase">Rubro</label>

                            <div className='flex'>
                                <select
                                    className='py-2 px-3 w-full rounded bg-slate-100 mr-2'
                                    id='sRubro'
                                    value={rubro}
                                    onChange={(e) => setRubro(e.target.value)}
                                >
                                    {rubros && (
                                        rubros.map(item => (
                                            <option key={item.id} value={item.id}>{item.descripcion}</option>))
                                    )}
                                </select>
                                <button
                                    type='button'
                                    className=' bg-violet-600 p-3 rounded-lg text-white'
                                    onClick={() => handleAdd(6)}
                                ><FaPlus /></button>
                            </div>
                            <label htmlFor="sMarca" className="w-full block mt-5 text-gray-600 uppercase">Marca</label>
                            <div className='flex'>
                                <select
                                    className='py-2 px-3 w-full rounded bg-slate-100 mr-2'
                                    id='sMarca'
                                    value={marca}
                                    onChange={(e) => setMarca(e.target.value)}
                                >
                                    {marcas && (
                                        marcas.map(item => (
                                            <option key={item.id} value={item.id}>{item.descripcion}</option>))
                                    )}
                                </select>
                                <button
                                    type='button'
                                    className=' bg-violet-600 p-3 rounded-lg text-white'
                                    onClick={() => handleAdd(4)}
                                ><FaPlus /></button>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/2 mx-auto m-3 p-2'>
                        <div className='p-3 bg-white border border-gray-200 rounded-lg shadow-xl'>
                            <h4 className='text-center text-gray-700 uppercase'>Precio, unidad y Stock</h4>
                            <div className="sm:items-start">

                                <label htmlFor="precio" className="block mt-5 text-gray-600 uppercase">Precio de venta</label>
                                <input
                                    className="py-2 px-3 w-full border border-gray-300 rounded"
                                    id="precio"
                                    placeholder="Precio de venta"
                                    type="text"
                                    value={formik.values.precio}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.precio && formik.errors.precio ? (
                                    <div className='w-full block text-red-500'>
                                        <p>{formik.errors.precio}</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className="sm:items-start">

                                <label htmlFor="precioCosto" className="block mt-5 text-gray-600 uppercase">Precio de costo</label>
                                <input
                                    className="py-2 px-3 w-full border border-gray-300 rounded"
                                    id="precioCosto"
                                    placeholder="Precio de costo"
                                    type="text"
                                    value={formik.values.precioCosto}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.precioCosto && formik.errors.precioCosto ? (
                                    <div className='w-full block text-red-500'>
                                        <p>{formik.errors.precioCosto}</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className="sm:items-start">

                                <label htmlFor="precioMayor" className="block mt-5 text-gray-600 uppercase">Precio por mayor</label>
                                <input
                                    className="py-2 px-3 w-full border border-gray-300 rounded"
                                    id="precioMayor"
                                    placeholder="Precio por mayor"
                                    type="text"
                                    value={formik.values.precioMayor}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.precioMayor && formik.errors.precioMayor ? (
                                    <div className='w-full block text-red-500'>
                                        <p>{formik.errors.precioMayor}</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className="sm:items-start">
                                <label htmlFor="precioPromo" className="block mt-5 text-gray-600 uppercase">Precio de Promoción</label>
                                <input
                                    className="py-2 px-3 w-full border border-gray-300 rounded"
                                    id="precioPromo"
                                    placeholder="Precio de Promoción"
                                    type="text"
                                    value={formik.values.precioPromo}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.precioPromo && formik.errors.precioPromo ? (
                                    <div className='w-full block text-red-500'>
                                        <p>{formik.errors.precioPromo}</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className="sm:items-start">
                                <label htmlFor="fechaVto" className="block mt-5 text-gray-600 uppercase">Fecha de Vencimiento</label>
                                <input
                                    className="py-2 px-3 w-full border border-gray-300 rounded"
                                    id="fechaVto"
                                    placeholder="Fecha de Vencimiento"
                                    type="date"
                                    value={formik.values.fechaVto}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>

                            <label htmlFor="sUnidad" className="w-full block mt-5 text-gray-600 uppercase">Unidad de medida</label>
                            <select
                                className='py-2 px-3 w-full rounded bg-slate-100'
                                id='sUnidad'
                                value={unidad}
                                onChange={(e) => setUnidad(e.target.value)}
                            >
                                {unidades && (
                                    unidades.map(item => (
                                        <option key={item.id} value={item.id}>{item.descripcion}</option>))
                                )}
                            </select>
                            <div className="sm:items-start">

                                <label htmlFor="stock" className="block mt-5 text-gray-600 uppercase">Stock</label>
                                <input
                                    className="py-2 px-3 w-full border border-gray-300 rounded"
                                    id="stock"
                                    placeholder="Stock"
                                    type="text"
                                    value={formik.values.stock}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.stock && formik.errors.stock ? (
                                    <div className='w-full block text-red-500'>
                                        <p>{formik.errors.stock}</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className="sm:items-start">

                                <label htmlFor="stockMin" className="block mt-5 text-gray-600 uppercase">Stock mínimo</label>
                                <input
                                    className="py-2 px-3 w-full border border-gray-300 rounded"
                                    id="stockMin"
                                    placeholder="Stock mínimo"
                                    type="text"
                                    value={formik.values.stockMin}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.stockMin && formik.errors.stockMin ? (
                                    <div className='w-full block text-red-500'>
                                        <p>{formik.errors.stockMin}</p>
                                    </div>
                                ) : null}
                            </div>

                        </div>
                    </div>
                </div>
                <div className="mt-auto">
                    <input
                        type="submit"
                        className={`"shadow-md p-3 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5" ${guardar ? 'bg-orange-600' : 'bg-orange-300'}`}
                        value="Guardar Datos"
                    />
                    <Link to={"/admin/productos"} >
                        <button
                            type='button'
                            className='ml-5 shadow-md p-3 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5 bg-gray-500'
                        >Volver a Productos</button>
                    </Link>
                </div>

            </form>
        </div>
    )
}

export default FormProducto