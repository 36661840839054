
import Layout from '../../components/admin/Layout';
import ListadoEnvases from '../../components/admin/listadoEnvases';

const Envases = () => {
	

	return (
		<Layout
			pagina={"Envases"}
		>
			<div className='w-full p-5 min-h-full'>
				<h2 className='text-center text-gray-700 text-xl md:text-2xl'>Gestión de Envases</h2>
				<ListadoEnvases />
			</div>
		</Layout>
	)
}

export default Envases