import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import { FaEye, FaPen, FaTrash } from "react-icons/fa";
import axios from 'axios'
import { Url } from '../Url'


const ListadoCompras = () => {
    const [compras, setCompras] = useState([])
    const { modify, setFormView, setViewOpen, setCompraSelected } = useContext(ContextStates)

    useEffect(() => {
        const consultaCompras = async () => {
            const formData = new FormData()
            try {
                const response = await axios({
                    url: Url + 'api/listCompras.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response)
                setCompras(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        consultaCompras()
    }, [modify])

    const handleView = (item, action) => {
        setCompraSelected(item)
        setFormView(action)
        setViewOpen(true)
    }


    return (
        <div className="w-full py-5 px-2 md:px20 rounded-2xl">

            <div className="sm:items-start">
                <table className='w-full'>
                    <thead>
                        <tr className='p-1 bg-blue-400 text-white'>
                            <th className='px-2'>Proveedor</th>
                            <th className='px-2'>Fecha</th>
                            <th className='px-2'>Total</th>
                            <th className='px-2'>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {typeof compras!=='undefined' && (
                            compras.map((item, index) => (
                                <tr key={index} className={`border border-gray-300 p-2 hover:cursor-pointer ${index % 2 === 0 ? 'bg-white hover:bg-slate-200' : 'bg-slate-100 hover:bg-slate-200'}`}>
                                    <td className='text-center'>{item.proveedor}</td>
                                    <td className='text-center'>{item.fecha}</td>
                                    <td className='text-center'>$ {parseFloat(item.total).toLocaleString("es-AR", { maximumFractionDigits: 2 })}</td>
                                    <td className='p-1 flex justify-center'>
                                        <button className='bg-blue-500 ml-2 hover:bg-blue-300 rounded p-2 text-white' onClick={() => handleView(item, 95)}><FaEye /></button>
                                        {/*}<button className='bg-gray-400 ml-2 hover:bg-gray-300 rounded p-2 text-white' onClick={() => handleView(item, 96)}><FaPen /></button>
                                        <button className='bg-red-500 ml-2 hover:bg-red-400 rounded p-2 text-white' onClick={() => handleView(item, 97)}><FaTrash /></button>{*/}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ListadoCompras