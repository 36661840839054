import { useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import { FaPen, FaTrash } from 'react-icons/fa'

const FViewCompra = () => {
    const { compraSelected, setFormView, setViewOpen } = useContext(ContextStates)


    return (
        <div className="w-full rounded mt-12 p-3">
            <h2 className='text-center text-gray-700 text-xl uppercase'>Detalle de Compra</h2>
            {typeof compraSelected.productos !== 'undefined' && (
                <table className='w-full mx-auto'>
                    <thead>
                        <tr className='p-1 bg-blue-400 text-white text-center'>
                            <th>Cantidad</th>
                            <th>Código</th>
                            <th>Descripción</th>
                            <th>Precio costo Uni.</th>
                            <th>Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            compraSelected.productos.map((item, index) => (
                                <tr key={index} className='border border-gray-300 p-2 text-center'>
                                    <td>{item.cantidad}</td>
                                    <td>{item.codigo}</td>
                                    <td>{item.descripcion}</td>
                                    <td>$ {(parseFloat(item.preciounitario)).toLocaleString("es-AR", { maximumFractionDigits: 2 })}</td>
                                    <td>$ {parseFloat(item.monto).toLocaleString("es-AR", { maximumFractionDigits: 2 })}</td>
                                </tr>
                            ))
                        }
                        <tr className='border border-gray-300 p-2 text-center'>
                            <th colSpan={4}>Total</th>
                            <th>$ {parseFloat(compraSelected.total).toLocaleString("es-AR", { maximumFractionDigits: 2 })}</th>
                            <th></th>
                        </tr>
                    </tbody>
                </table>
            )}
                <div className='w-1/3 mx-auto mt-5'>
                    <button
                        type='button'
                        className='w-full p-2 bg-gray-400 text-white uppercase font-semibold rounded'
                        onClick={() => setViewOpen(false)}
                    >
                        Salir
                    </button>
                </div>
        </div>
    )
}

export default FViewCompra