import Layout from '../components/Layout';
import SearchList from '../components/SearchList';


const Search = () => {

	return (
		<Layout
			pagina={"Búsqueda"}
		>
			<div className='w-full pt-20 pb-20'>
                <SearchList/>
			</div>
		</Layout>
	)
}

export default Search