import FormLogin from '../../components/forms/FormLogin';

const LoginAdmin = () => {

    return (
        <main className='w-full h-screen bg-slate-50'>
            <div className='w-full h-full pb-40 pt-2 mt-0'>
                <h1 className='mt-5 text-gray-600 text-5xl text-center'>Iniciar Sesión</h1>
                <div>
                    <div>
                        <div className="w-full mx-auto rounded-lg px-11 mb-5">
                            <FormLogin />
                        </div>
                    </div>
                </div>
            </div>
        </main>
  )
}

export default LoginAdmin