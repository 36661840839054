import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'


const FormLocal = () => {
    const [nombre, setNombre] = useState('')
    const [razonSocial, setRazonSocial] = useState('')
    const [cuit, setCuit] = useState('')
    const [fechaInicio, setFechaInicio] = useState('')
    const [logo, setLogo] = useState('')
    const [habilitado, setHabilitado] = useState(false)


    useEffect(() => {
        const getDatos = async () => {
            const formData = new FormData()
            formData.append('consulta', 1);
            try {
                const results = await axios({
                    url: Url + 'api/datosLocal.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(results)
                setNombre(results.data.results.nombre)
                setRazonSocial(results.data.results.razonsocial)
                setCuit(results.data.results.cuit)
                setFechaInicio(results.data.results.fechainicio)
                setLogo(results.data.results.logo)
                setHabilitado(false)
            } catch (e) {
                console.log(e)
            }
        }
        getDatos()
    }, [])

    useEffect(() => {
        setHabilitado(true)
    },[nombre, razonSocial, cuit, fechaInicio])


    const handleChange = async () => {
        const formData = new FormData()
        formData.append('nombre', nombre);
        formData.append('razonsocial', razonSocial);
        formData.append('cuit', cuit);
        formData.append('fechainicio', fechaInicio);
        try {
            const results = await axios({
                url: Url + 'api/setLocal.php',
                method: 'POST',
                data: formData,
            })
            console.log(results)
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <div className="transition-all w-full pt-5 sm:p-3 mt-5">
            <h2 className='text-center text-gray-700 text-4xl mb-4'>Datos del Local</h2>
            <div className='w-full md:w-2/3 mx-auto border-2xl p-3 bg-slate-100 mb-10 rounded-xl'>
                <label htmlFor='nombre' className='uppercase text-md text-black'>Nombre del Local</label>
                <input type='text' id='nombre' value={nombre} placeholder='Nombre del Local' className='mb-3 w-full text-sm p-2 rounded' onChange={(e) => setNombre(e.target.value)} />

                <label htmlFor='razonSocial' className='uppercase text-md text-black'>Razón Social</label>
                <input type='text' id='razonSocial' value={razonSocial} placeholder='Razón Social' className='mb-3 w-full text-sm p-2 rounded' onChange={(e) => setRazonSocial(e.target.value)} />

                <label htmlFor='cuit' className='uppercase text-md text-black'>CUIT</label>
                <input type='text' id='cuit' value={cuit} placeholder='CUIT' className='mb-3 w-full text-sm p-2 rounded' onChange={(e) => setCuit(e.target.value)} />

                <label htmlFor='fechaInicio' className='uppercase text-md text-black'>Fecha de Inicio</label>
                <input type='text' id='fechaInicio' value={fechaInicio} placeholder='CUIT' className='mb-3 w-full text-sm p-2 rounded' onChange={(e) => setFechaInicio(e.target.value)} />
            </div>
            <div className='w-full md:w-2/3 mx-auto'>
                <button
                    className={`text-white p-3 rounded text-center ${habilitado ? 'bg-blue-500':'bg-blue-200'} `}
                    onClick={() => handleChange()}
                    disabled={!habilitado}
                >Actualizar Datos
                </button>
            </div>
        </div>
    )
}

export default FormLocal