import React from 'react'
import Layout from '../../components/admin/Layout'
import ValoresInicio from '../../components/admin/valoresInicio';

const IndexAdmin = () => {
    return (
        <Layout
            pagina="Administración del Sistema"
        >
            <div className='w-full h-full bg-white'>
                <ValoresInicio />
            </div>
        </Layout>
    )
}

export default IndexAdmin