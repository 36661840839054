import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import ContextStates from '../../context/ContextStates'
import { Url } from '../Url'

const FPagoCliente = () => {
    const [medios, setMedios] = useState([])
    const [medioPago, setMedioPago] = useState('1')
    const [importe, setImporte] = useState("")
    const { clienteSelected, idUsuario, setViewOpen, modify, setModify } = useContext(ContextStates);

    useEffect(() => {
        const getMedios = async () => {
            const formData = new FormData()
            formData.append('out', 1)
            try {
                const results = await axios({
                    url: Url + 'api/listmediospago.php',
                    method: 'POST',
                    data: formData,
                })
                setMedios(results.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        getMedios()
    }, [])

    const registrarPago = async () => {
        const formData = new FormData()
        formData.append('idcliente', clienteSelected.id);
        formData.append('mediopago', medioPago);
        formData.append('idusuario', idUsuario);
        formData.append('importe', importe);
        try {
            const response = await axios({
                url: Url + 'api/registrarPago.php',
                method: 'POST',
                data: formData,
            })
            //console.log(response)
            setViewOpen(false)
            let aux = modify + 1;
            setModify(aux)

        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div
            className="w-full mx-auto p-5 rounded-2xl"
        >
            <div className="transition-all w-full sm:p-3">
                <h2 className='text-3xl uppercase mb-3 text-center'>Registrar pago de saldo adeudado</h2>
                <div className='p-5'>
                    <div className="sm:items-start sm:w-1/2 w-full mr-2">
                        <label className="block mt-5 text-gray-600 uppercase font-bold">Apellido y Nombre</label>
                        <p className='font-bold text-xl'>{`${clienteSelected.apellido}, ${clienteSelected.nombre}`}</p>
                    </div>
                    <div className="sm:items-start w-full mr-2 bg-orange-500 p-4 text-center text-white rounded-xl">
                        <p className='font-bold text-3xl'>Saldo: $ {clienteSelected.saldo ? clienteSelected.saldo : 0}</p>
                    </div>
                </div>
                <div className='bg-gray-50 rounded-lg p-5'>
                    {typeof medios !== 'undefined' && medios.map((item, index) =>
                        <div key={index} className="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="medioPago"
                                    value={item.id}
                                    checked={medioPago === item.id}
                                    onChange={() => setMedioPago(item.id)}
                                    className='mr-5'
                                />
                                {item.descripcion}
                            </label>
                        </div>
                    )}
                </div>
                <div className='p-5'>
                    <div className="sm:items-start sm:w-1/2 w-full mr-2">
                        <label htmlFor="importe" className="block mt-5 text-gray-600 uppercase font-bold">Importe</label>
                        <input
                            className="py-2 px-3 w-full rounded bg-slate-100"
                            id="importe"
                            placeholder="Ingrese el importe"
                            type="number"
                            value={importe}
                            onChange={(e) => setImporte(e.target.value)}
                            autoFocus
                        />
                        {importe === '' ? (
                            <div className='w-full block mb-2 text-red-500'>
                                <p>{'Debe ingresar un importe'}</p>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>

            <div className="pb-1">
                <div className="w-full rounded-lg px-3 flex justify-center">
                    <button
                        type="button"
                        className={`w-1/4 sm:w-1/6 shadow-md p-3 ml-3  text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg ${importe!=='' ? 'bg-green-500' : 'bg-green-200'} `}
                        onClick={() => registrarPago()}
                    >
                        Registrar Pago
                    </button>
                    <button
                        type="button"
                        className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                        onClick={() => setViewOpen(false)}
                    >
                        Salir
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FPagoCliente