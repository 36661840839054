import React, { useEffect, useContext } from 'react'
import axios from 'axios';
import ContextStates from '../../context/ContextStates';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import { MdAttachMoney } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { Url } from '../Url';


const ListadoPuntos = () => {
    const {
        puntos, setPuntos,
        roles, modify, setFormView, setViewOpen, setPuntoSelected } = useContext(ContextStates);

    useEffect(() => {
        const consultaPuntos = async () => {
            const formData = new FormData()
            //formData.append('idusuario', idUsuario)
            //formData.append('busqueda', buscarPunto)
            try {
                const response = await axios({
                    url: Url + 'api/listPuntos.php',
                    method: 'POST',
                    data: formData,
                })
                setPuntos(response.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaPuntos()
    }, [modify])

    const asignarPunto = (id) => {
        puntos.forEach(element => {
            if (element.id === id) {
                setPuntoSelected(element)
            }
        });
    }

    const handleAdd = async () => {
        setFormView(40)
        setViewOpen(true);
    }

    const editarPunto = async (id) => {
        asignarPunto(id);
        setFormView(42)
        setViewOpen(true);
    }

    const eliminarPunto = async (id) => {
        asignarPunto(id);
        setFormView(43)
        setViewOpen(true);
    }

    return (
        <div>
            <h2 className='text-center text-gray-700 text-sm md:text-xl my-10'>Gestión de Puntos de Venta</h2>
            <div className='w-full mx-auto flex mb-4'>
                <div className='w-1/4'>
                    <button type='button'
                        className='p-3 bg-blue-600 text-white uppercase rounded flex mr-5'
                        onClick={() => handleAdd()}
                    >
                        <FaPlus className='mr-2 text-xl' />Agregar Punto de Venta
                    </button>
                </div>
            </div>

            <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                <thead>
                    <tr className='uppercase bg-blue-400 text-white'>
                        <th className='border-gray-400 p-2'>Nombre</th>
                        <th className='border-gray-400 p-2'>Descripción</th>
                        <th className='border-gray-400 p-2'>Acciones</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        typeof puntos !== 'undefined' ? (
                            puntos.map((item, index) => (

                                <tr
                                    key={item.id}
                                    className={`hover:bg-slate-200 border-b border-gray-200 ${index % 2 === 0 ? 'bg-white' : 'bg-slate-100'}`}
                                >
                                    <td className='border-gray-400 p-1 text-center'>{item.nombre}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.descripcion}</td>
                                    <td className='p-1 flex justify-center'>
                                        {(roles.includes("1")) && (
                                            <>
                                                <button
                                                    className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                                    onClick={() => editarPunto(item.id)}
                                                >
                                                    <FaPen />
                                                </button>
                                                <button
                                                    className='bg-red-500 p-3 rounded-md text-white ml-3'
                                                    onClick={() => eliminarPunto(item.id)}
                                                >
                                                    <FaTrash />
                                                </button>
                                            </>
                                        )}
                                    </td>
                                </tr>

                            ))
                        ) : (null)
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ListadoPuntos