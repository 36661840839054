import { useState, useEffect, useContext } from 'react'
import ContextStates from '../context/ContextStates';
import axios from 'axios';
import { Url } from './Url';


const FormCloseJornada = () => {
    const { idJornada, setIdJornada, idUsuario, setEstadoJornada, puntoVenta, setViewOpen } = useContext(ContextStates)

    const closeJornada = async() => {
        const formData = new FormData()
        formData.append('idusuario', idUsuario);
        formData.append('idjornada', idJornada);
        try {
            const results = await axios({
                url: Url + 'api/closeJornada.php',
                method: 'POST',
                data: formData,
            })
            //console.log(results)
            localStorage.removeItem("pvp_jnd")
            setEstadoJornada(0)
            localStorage.removeItem("pvp_st_jnd")
            setIdJornada(0)
            setViewOpen(false)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className='w-full'>
            <div className="transition-all w-full mt-3">
                <h2 className='text-center text-3xl'>Cerrar Jornada de Caja</h2>
                <div className="w-full rounded-lg p-5 justify-center">
                    <div className='w-1/3 sm:w-1/4 mx-auto'>
                        <button
                            type='button'
                            className="w-full flex shadow-md p-3 ml-3 bg-orange-500 text-white hover:bg-orange-400 transition-all uppercase font-bold rounded-lg"
                            onClick={() => closeJornada()}
                        >
                            <p className='w-full text-center'>
                                Confirmar cierre de Jornada
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormCloseJornada