import { useState, useContext } from 'react'
import { FaSearch } from 'react-icons/fa'
import ContextStates from '../context/ContextStates';
import { useNavigate } from 'react-router-dom';


const SearchBar = () => {
    const [ contenido, setContenido ] = useState('');
    
    const { setTextSearch, viewSearch, setViewSearch } = useContext(ContextStates);
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        if (contenido!=='')
        {
            setTextSearch(contenido);
            navigate("/search", { replace: true });
        }
    }

    return (
        <div className={`w-full absolute z-50 bg-blue-500/40 py-2 ${!viewSearch && 'hidden'}`}>
            <div className='w-10/12 sm:w-1/2 mx-auto bg-white h-10 rounded-3xl'>
                <form 
                    className='flex px-10 py-1'
                    onSubmit={handleSubmit}
                >
                    <input 
                        type='text' 
                        className='w-11/12 h-8 outline-none'
                        value={contenido}
                        onChange={(e)=>setContenido(e.target.value)}
                    />
                    <button 
                        className='ml-auto'
                        type='submit'
                    >
                        <FaSearch/>
                    </button>
                </form>
            </div>
        </div>
    )
}

export default SearchBar;