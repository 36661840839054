import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom"
import Layout from '../components/Layout';
import ContextStates from '../context/ContextStates';
import { motion } from 'framer-motion';

import { useParams } from 'react-router-dom'
import { Url } from '../components/Url';
import { areaAnimateLR, areaAnimateRL, areaAnimateS } from '../components/Animations'


const Marca = () => {
	const [ productos, setProductos ] = useState([]);

	let marca=useParams()
	useEffect(()=>{
		const getProducto = async() => {
			const url = `${Url}api/productosmarca.php?idmarca=${marca.id}`;
            const resultado = await axios.get(url);
            console.log(resultado)
            setProductos(resultado.data.results);
		}
		getProducto()
	},[])


	return (
		<Layout
			pagina={"Categoría"}
		>
            <div className="w-10/12 mx-auto mt-20 mb-20 sm:flex">
                { productos ? productos.map((item,index)=>(
                    <motion.div 
                        className="sm:w-1/6 w-full mx-auto" key={index}
                        initial={"offscreen"}
                        whileInView={"onscreen"}
                        viewport={{once:true, amount: 0.1}}
                        variants={areaAnimateLR}
                    >
                        <Link to={`/producto/${item.id}`}>
                            <img className="" src={Url+item.imagen}/>
                            <h3 className="text-center text-2xl font-semibold uppercase">{item.marca}</h3>
                            <p className="text-center text-xl">{item.descripcionproducto}</p>
                            <p className="text-center text-lg">{item.descripcion}</p>
                        </Link>
                    </motion.div>
                )):(
                    <motion.div 
                        className="sm:w-1/6 w-full mx-auto"
                        initial={"offscreen"}
                        whileInView={"onscreen"}
                        viewport={{once:true, amount: 0.1}}
                        variants={areaAnimateLR}
                    >
                        <h3 className='text-center text-3xl uppercase'>No hay resultados</h3>
                    </motion.div>
                )}
            </div>
		</Layout>
	)
}

export default Marca