import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import ContextStates from '../../context/ContextStates'
import { Url } from '../Url'

const FViewCliente = () => {
    const [tab, setTab] = useState(0)
    const { clienteSelected, setViewOpen } = useContext(ContextStates);


    return (
        <div
            className="w-full mx-auto p-5 rounded-2xl"
        >
            <div className="transition-all w-full sm:p-3">
                <h2 className='text-3xl uppercase mb-3 text-center'>Cliente</h2>
                <div className='p-5'>
                    <div className="sm:items-start sm:w-1/2 w-full mr-2">
                        <label className="block mt-5 text-gray-600 uppercase font-bold">Apellido y Nombre</label>
                        <p className='font-bold text-xl'>{`${clienteSelected.apellido}, ${clienteSelected.nombre}`}</p>
                    </div>
                    <div className={`w-full p-4 rounded-xl mt-5 ${clienteSelected.saldo ? 'bg-orange-500 text-white' : 'bg-white text-gray-600'}`}>
                        <p className='font-bold text-4xl text-center'>Saldo: $ {clienteSelected.saldo ? clienteSelected.saldo : 0}</p>
                    </div>
                    <div className='w-full flex my-5'>
                        <button className={`text-center p-2 rounded-lg mr-1 ${tab === 0 ? 'bg-green-200 text-gray-700 shadow-lg' : 'bg-gray-200 text-gray-600'}`} onClick={() => setTab(0)}>Ver Deudas</button>
                        <button className={`text-center p-2 rounded-lg mr-1 ${tab === 1 ? 'bg-green-200 text-gray-700 shadow-lg' : 'bg-gray-200 text-gray-600'}`} onClick={() => setTab(1)}>Ver Pagos</button>
                    </div>
                </div>
                {tab === 0 ? (
                    <div className='p-5'>
                        {clienteSelected.saldo && (
                            <>
                                <h3 className='text-3xl uppercase mb-3 text-center'>Detalle del saldo</h3>
                                <div className="sm:items-start w-full mr-2">
                                    {typeof clienteSelected.detalleSaldo !== 'undefined' && (
                                        <table className='w-full'>
                                            <thead>
                                                <tr className='p-1 bg-blue-400 text-white'>
                                                    <th className='px-2 text-center'>Comprobante</th>
                                                    <th className='px-2 text-center'>Fecha</th>
                                                    <th className='px-2 text-center'>Hora</th>
                                                    <th className='px-2 text-center'>Capital Inicial</th>
                                                    <th className='px-2 text-center'>Saldo</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    clienteSelected.detalleSaldo.map((item, index) => (
                                                        <tr key={index} className={`border border-gray-300 p-2 hover:cursor-pointer ${item.stockmin > item.stock ? 'bg-red-100' : index % 2 === 0 ? 'bg-white hover:bg-slate-200' : 'bg-slate-100 hover:bg-slate-200'}`}>
                                                            <td className='text-center'>{item.numerocomprobante}</td>
                                                            <td className='text-center'>{item.fecha}</td>
                                                            <td className='text-center'>{item.hora}</td>
                                                            <td className='text-center'>$ {Math.round(item.capital)}</td>
                                                            <td className='text-center'>$ {Math.round(item.saldo)}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </>
                        )}
                    </div>

                ) : (
                    <div className='p-5'>
                        {clienteSelected.pagos && (
                            <>
                                <h3 className='text-3xl uppercase mb-3 text-center'>Detalle del saldo</h3>
                                <div className="sm:items-start w-full mr-2">
                                    {typeof clienteSelected.pagos !== 'undefined' && (
                                        <table className='w-full'>
                                            <thead>
                                                <tr className='p-1 bg-blue-400 text-white'>
                                                    <th className='px-2 text-center'>Fecha</th>
                                                    <th className='px-2 text-center'>Hora</th>
                                                    <th className='px-2 text-center'>Monto</th>
                                                    <th className='px-2 text-center'>Medio</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    clienteSelected.pagos.map((item, index) => (
                                                        <tr key={index} className={`border border-gray-300 p-2 hover:cursor-pointer ${item.stockmin > item.stock ? 'bg-red-100' : index % 2 === 0 ? 'bg-white hover:bg-slate-200' : 'bg-slate-100 hover:bg-slate-200'}`}>
                                                            <td className='text-center'>{item.fecha}</td>
                                                            <td className='text-center'>{item.hora}</td>
                                                            <td className='text-center'>$ {Math.round(item.monto)}</td>
                                                            <td className='text-center'>{item.mediopago}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </>
                        )}
                    </div>

                )}
            </div>

            <div className="pb-1">
                <div className="w-full rounded-lg px-3 flex justify-center">
                    <button
                        type="button"
                        className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                        onClick={() => setViewOpen(false)}
                    >
                        Salir
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FViewCliente