import { areaAnimateRL, areaAnimateS } from './Animations';


const Footer = () => {

    const today = new Date();
    const year = today.getFullYear();


    return (
        <footer className="text-center lg:text-left text-gray-200 mt-auto">
            <div 
                className="text-center p-6"
            >
                <a className="text-gray-300 font-semibold" href="https://mirx.com.ar" target={"_blank"}><span className='font-light'>Diseño y Desarrollo </span>Mirx Software</a>
            </div>
        </footer>  
    )
}

export default Footer
