import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { FaEye, FaPen, FaTrash } from "react-icons/fa";
import { Url } from '../Url'


const ListadoPromociones = () => {
    const [promociones, setPromociones] = useState([])
    const [fechaInicio, setFechaInicio] = useState("")
    const [fechaFin, setFechaFin] = useState("")
    const { setPromoSelected, setFormView, setViewOpen, modify } = useContext(ContextStates)

    useEffect(() => {
        const getPromociones = async () => {
            const formData = new FormData()
            formData.append('fechaInicio', fechaInicio);
            formData.append('fechaFin', fechaFin);
            try {
                const results = await axios({
                    url: Url + 'api/listPromociones.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(results)
                setPromociones(results.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        getPromociones()
    }, [modify, fechaInicio, fechaFin])


    const handleView = (item) => {
        setPromoSelected(item)
        setFormView(70)
        setViewOpen(true)
    }
    const handleEdit = (item) => {
        setPromoSelected(item)
        setFormView(71)
        setViewOpen(true)
    }
    const handleDelete = (item) => {
        setPromoSelected(item)
        setFormView(72)
        setViewOpen(true)
    }



    return (
        <div className="w-full py-5 px-2">


            <div className="w-full md:w-11/12 mx-auto bg-slate-200 mt-5 mb-5 rounded-xl">
                <div className='w-full flex'>
                    <div className='w-1/2 p-3'>
                        <label htmlFor='fechainicio'>Fecha de Inicio</label>
                        <input type='date' className='w-full p-3 rounded-lg' id='fechainicio' placeholder='Fecha de Inicio' value={fechaInicio} onChange={(e) => setFechaInicio(e.target.value)} />
                    </div>
                    <div className='w-1/2 p-3 ml-5 rounded-xl'>
                        <label htmlFor='fechafin'>Fecha de Fin</label>
                        <input type='date' className='w-full p-3 rounded-lg' id='fechafin' placeholder='Fecha de Fin' value={fechaFin} onChange={(e) => setFechaFin(e.target.value)} />
                    </div>
                </div>
            </div>

            {typeof promociones !== 'undefined' && (
                <table className='w-full mx-auto'>
                    <thead>
                        <tr className='p-1 bg-blue-400 text-white text-center'>
                            <th>Descripcion</th>
                            <th>Fecha de Inicio</th>
                            <th>Fecha de Fin</th>
                            <th>Tipo de promoción</th>
                            <th>Estado</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            promociones.map((item, index) => (
                                <tr key={index} className={`hover:cursor-pointer hover:bg-slate-200 border-b border-gray-200 text-center ${index % 2 === 0 ? 'bg-white ' : 'bg-slate-100'} )`}>
                                    <td>{item.descripcion}</td>
                                    <td>{item.fechainicio}</td>
                                    <td>{item.fechafin}</td>
                                    <td>{item.tipopromocion}</td>
                                    <td className={`${item.estado === 1 ? 'bg-green-400 text-white' : 'bg-red-400 text-white'}`}>{item.estado === 1 ? 'Activa' : 'Vencida'}</td>
                                    <td className='text-center'>
                                        <button className='bg-blue-500 ml-2 hover:bg-blue-300 rounded p-2 text-white' onClick={() => handleView(item)}><FaEye /></button>
                                        <button className='bg-gray-400 ml-2 hover:bg-gray-300 rounded p-2 text-white' onClick={() => handleEdit(item)}><FaPen /></button>
                                        <button className='bg-red-500 ml-2 hover:bg-red-400 rounded p-2 text-white' onClick={() => handleDelete(item)}><FaTrash /></button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            )}

        </div>
    )
}

export default ListadoPromociones