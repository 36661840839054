import React, { useEffect, useContext } from 'react'
import axios from 'axios';
import ContextStates from '../../context/ContextStates';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import { MdAttachMoney } from "react-icons/md";
import { Url } from '../Url';


const ListadoProveedores = () => {
    const {
        proveedores, setProveedores,
        buscarProveedor, roles, modify, setFormView, setViewOpen, setProveedorSelected } = useContext(ContextStates);

    useEffect(() => {
        const consultaProveedores = async () => {
            const formData = new FormData()
            //formData.append('idusuario', idUsuario)
            formData.append('busqueda', buscarProveedor)
            try {
                const response = await axios({
                    url: Url + 'api/listProveedores.php',
                    method: 'POST',
                    data: formData,
                })
                setProveedores(response.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaProveedores()
    }, [modify, buscarProveedor])
    
    const asignarProveedor = (id) => {
        proveedores.forEach(element => {
            if (element.id===id)
            {
                setProveedorSelected(element)
            }
        });
    }

    const verProveedor = async (id) => {
        asignarProveedor(id);
        setFormView(33)
        setViewOpen(true);
    }

    const editarProveedor = async (id) => {
        asignarProveedor(id);
        setFormView(81)
        setViewOpen(true);
    }

    const eliminarProveedor = async (id) => {
        asignarProveedor(id);
        setFormView(82)
        setViewOpen(true);
    }

    return (
        <div>
            <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                <thead>
                    <tr className='uppercase bg-blue-400 text-white'>
                        <th className='border-gray-400 p-2'>Nombre</th>
                        <th className='border-gray-400 p-2'>Contacto</th>
                        <th className='border-gray-400 p-2'>Tel.</th>
                        <th className='border-gray-400 p-2'>E-mail</th>
                        <th className='border-gray-400 p-2'>Acciones</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        typeof proveedores !== 'undefined' ? (
                            proveedores.map((item, index) => (

                                <tr
                                    key={item.id}
                                    className={`hover:bg-slate-200 border-b border-gray-200 ${index % 2 === 0 ? 'bg-white' : 'bg-slate-100'}`}
                                >
                                    <td className='border-gray-400 p-1 text-center'>{item.nombre}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.contacto}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.telefono}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.email}</td>
                                    <td className='p-1 flex justify-center'>
                                        <button
                                            className='bg-indigo-500 p-3 rounded-md text-white ml-3'
                                            onClick={() => verProveedor(item.id)}
                                        >
                                            <FaEye />
                                        </button>
                                        {(roles.includes("1")) && (
                                            <>
                                                <button
                                                    className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                                    onClick={() => editarProveedor(item.id)}
                                                >
                                                    <FaPen />
                                                </button>
                                                <button
                                                    className='bg-red-500 p-3 rounded-md text-white ml-3'
                                                    onClick={() => eliminarProveedor(item.id)}
                                                >
                                                    <FaTrash />
                                                </button>
                                            </>
                                        )}
                                    </td>
                                </tr>

                            ))
                        ) : (null)
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ListadoProveedores