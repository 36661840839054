import { useState, useEffect, useContext } from "react";
import { useFormik } from "formik"
import * as Yup from "yup"
import { motion } from 'framer-motion';
import authContext from '../../context/auth/authContext';
import ContextStates from "../../context/ContextStates";
import Alert from '../Alert';
import { Link } from 'react-router-dom';
import { areaAnimateS } from "../Animations";
import axios from "axios";
import { Url } from "../Url";

const FormLogin = () => {
    const [puntos, setPuntos] = useState([])
    const AuthContext = useContext(authContext);
    const { setViewOpen, puntoVenta, setPuntoVenta } = useContext(ContextStates);
    const { autenticado, mensaje, iniciarSesion } = AuthContext;

    useEffect(() => {
        const query = async () => {
            const formData = new FormData()
            try {
                const response = await axios({
                    url: Url + 'api/getPV.php',
                    method: 'POST',
                    data: formData,
                })
                if (typeof response.data.results!='undefined') {
                    setPuntos(response.data.results);
                    setPuntoVenta(response.data.results.puntos[0].id)
                    
                }
            } catch (e) {
                console.log(e)
            }
        }
        query();
    }, [])

    useEffect(() => {
        if (puntoVenta!==0) {
            localStorage.setItem('pvp_pv',puntoVenta)
        }
    }, [puntoVenta])

    useEffect(() => {
        if (autenticado) {
            if (puntoVenta===0) {
                setPuntoVenta(1)
            }
            setViewOpen(false)
        }
    }, [autenticado])

    const formik = useFormik({
        initialValues: {
            mail: '',
            password: ''
        },
        validationSchema: Yup.object({
            mail: Yup.string()
                .required("El usuario o email es necesario"),
            password: Yup.string()
                .required("Debe ingresar un Password")

        }),
        onSubmit: valores => {
            //onsole.log(valores)
            iniciarSesion(valores)
        }
    });

    //const cancelButtonRef = useRef(null)

    return (
        <motion.div
            className="w-full"
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: false, amount: 0.1 }}
            variants={areaAnimateS}
        >
            {mensaje ? <Alert /> : null}
            <form
                className="w-full md:w-1/3 mx-auto mt-10 py-5 px-5 bg-white rounded-2xl"
                onSubmit={formik.handleSubmit}
            >
                <div className="transition-all w-full pt-5 sm:p-3 mt-5">
                    <div className="sm:items-start">
                        <label htmlFor="mail" className="w-full block mt-5 text-black uppercase font-bold">Usuario o E-mail</label>
                        <input
                            className="py-2 px-3 w-full rounded border border-gray-200"
                            id="mail"
                            placeholder="Ingrese su Usuario o E-mail"
                            type="text"
                            value={formik.values.mail}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoFocus
                        />
                        {formik.touched.mail && formik.errors.mail ? (
                            <div className='w-full block mb-2 text-red-500'>
                                <p>{formik.errors.mail}</p>
                            </div>
                        ) : null}
                    </div>
                    <div className="sm:items-start mt-5">
                        <label htmlFor="password" className="block text-black uppercase font-bold">Contraseña</label>
                        <input
                            className="py-2 px-3 w-full rounded border border-gray-200"
                            id="password"
                            placeholder="Ingrese su Contraseña"
                            type="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div className='w-full block mb-2 text-red-500'>
                                <p>{formik.errors.password}</p>
                            </div>
                        ) : null}
                    </div>
                    {typeof puntos !== 'undefined' && (
                        <div className="sm:items-start mt-5">
                            {puntos.cantidad > 1 && (
                                <>
                                    <label htmlFor="sPuntos" className="w-full block mt-5 text-gray-600 uppercase">Punto de Venta</label>
                                    <select
                                        className='py-2 px-3 w-full rounded bg-slate-100'
                                        id='sPuntos'
                                        onChange={(e) => setPuntoVenta(e.target.value)}
                                    >
                                        {puntos.puntos && (
                                            puntos.puntos.map(item => (
                                                <option key={item.id} value={item.id}>{item.nombre}</option>))
                                        )}
                                    </select>
                                </>
                            )}
                        </div>
                    )}
                </div>
                <div className="pb-10">
                    <div className="w-full rounded-lg px-3">
                        <input
                            type="submit"
                            className="w-full shadow-md p-3 bg-orange-600 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5"
                            value="Iniciar Sesión"
                        />
                    </div>
                </div>
            </form>
        </motion.div>
    )
}

export default FormLogin