import { useContext, useEffect, useState, useRef } from 'react';
import ContextStates from '../context/ContextStates';
import axios from 'axios';
import ReactToPrint from 'react-to-print';
import { FaPrint } from 'react-icons/fa'
import { Url } from './Url';
import Select from 'react-select';
import jsPDF from 'jspdf'

const FinalizarVenta = () => {
    const [total, setTotal] = useState(0)
    const [cliente, setCliente] = useState(0)
    const [fecha, setFecha] = useState('')
    const [hora, setHora] = useState('')
    const [nombre, setNombre] = useState('')
    const [razonSocial, setRazonSocial] = useState('')
    const [cuit, setCuit] = useState('')
    const [fechaInicio, setFechaInicio] = useState('')
    const [medios, setMedios] = useState([])
    const [medioPago, setMedioPago] = useState('1')
    const [efectivo, setEfectivo] = useState('')
    const [procesando, setProcesando] = useState(false)
    const [datosFactura, setDatosFactura] = useState('')
    const [finVenta, setFinVenta] = useState(false)
    const [auxVenta, setAuxVenta] = useState([])
    const [auxTotal, setAuxTotal] = useState(0)
    const [prestamo, setPrestamo] = useState(false)
    const [envases, setEnvases] = useState([])
    const [envase, setEnvase] = useState(0)
    const [cantidadEnv, setCantidadEnv] = useState("")
    const [listEnvases, setListEnvases] = useState([])
    const [descuentos, setDescuentos] = useState([])

    const { venta, precio, setVenta, setFormView, setViewOpen, modify, setModify, idUsuario, clientes, setClientes, setFormPrev, puntoVenta } = useContext(ContextStates);
    const componentRef = useRef();

    useEffect(() => {
        const calcTotal = () => {
            let aux = 0;
            let rData = [...descuentos]
            venta.forEach(element => {
                if (!precio) {
                    if (element.tipopromo === '1' || element.tipopromo === '2') {
                        aux = aux + parseFloat(element.preciopromocion * element.cantidad);
                        let montoDesc = parseFloat(element.precio * element.cantidad) - parseFloat(element.preciopromocion * element.cantidad);
                        let descuento = {
                            id: element.id,
                            descripcion: element.descripcion,
                            monto: montoDesc
                        }
                        rData.push(descuento)
                    }
                    else {
                        if (element.tipopromo === '3') {
                            let precio = 0;
                            if (element.cantidad >= element.cantidadminima) {
                                let cantPromos = parseFloat(element.cantidad) / parseFloat(element.cantidadminima);
                                let cantPagos = element.cantidad - (parseFloat(element.cantidaddescuento) * cantPromos);
                                precio = cantPagos * parseFloat(element.precio);
                                let montoDesc = parseFloat(element.precio * element.cantidad) - precio;
                                let descuento = {
                                    id: element.id,
                                    descripcion: element.descripcion,
                                    monto: montoDesc
                                }
                                rData.push(descuento)
                            } else {
                                precio = parseFloat(element.cantidad) * parseFloat(element.precio);
                            }
                            aux = aux + precio;
                        } else {
                            aux = aux + parseFloat(element.precio * element.cantidad);
                        }
                    }
                }
                else {
                    aux = aux + parseFloat(element.preciopormayor * element.cantidad);
                }
            });
            setDescuentos(rData)
            setTotal(aux)
        }
        calcTotal()
    }, [venta])

    useEffect(() => {
        const getMedios = async () => {
            const formData = new FormData()
            try {
                const results = await axios({
                    url: Url + 'api/listmediospago.php',
                    method: 'POST',
                    data: formData,
                })
                setMedios(results.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        getMedios()
    }, [])

    useEffect(() => {
        const consultaClientes = async () => {
            const formData = new FormData()
            //formData.append('idusuario', idUsuario)
            formData.append('busqueda', "")
            try {
                const response = await axios({
                    url: Url + 'api/listClientes.php',
                    method: 'POST',
                    data: formData,
                })
                setClientes(response.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaClientes()
    }, [modify])

    useEffect(() => {
        const getEnvases = async () => {
            const formData = new FormData()
            //formData.append('idusuario', idUsuario)
            //formData.append('busqueda', "")
            try {
                const response = await axios({
                    url: Url + 'api/getEnvases.php',
                    method: 'POST',
                    data: formData,
                })
                setEnvases(response.data.results);
                setEnvase(response.data.results[0].id)
            } catch (e) {
                console.log(e)
            }
        }
        getEnvases()
    }, [])

    const facturaAfip = async () => {
        if (total > 0 && medioPago === '5') {
            setProcesando(true)
            const formData = new FormData()
            formData.append('total', total);
            try {
                const response = await axios({
                    url: Url + 'api/factura.php',
                    method: 'POST',
                    data: formData,
                })
                setProcesando(false)
                setDatosFactura(response.data.results.cae)
            } catch (e) {
                console.log(e)
            }
        }
    }

    const finalizarVenta = async () => {
        let tipoPrecio = 0;
        if (precio) {
            tipoPrecio = 1;
        }
        const auxVenta = JSON.stringify(venta)
        const auxEnvases = JSON.stringify(listEnvases)
        const auxDescuentos = JSON.stringify(descuentos)
        const formData = new FormData()
        formData.append('venta', auxVenta);
        formData.append('mediopago', medioPago);
        formData.append('idusuario', idUsuario);
        formData.append('cliente', cliente);
        formData.append('total', total);
        formData.append('puntoventa', puntoVenta);
        formData.append('tipoprecio', tipoPrecio);
        formData.append('envases', auxEnvases);
        formData.append('descuentos', auxDescuentos);
        try {
            const response = await axios({
                url: Url + 'api/finVenta.php',
                'Content-Type': 'application/json',
                method: 'POST',
                data: formData,
                dataType: 'JSON',
            })
            console.log(response)
            setFecha(response.data.results.fecha);
            setHora(response.data.results.hora);
            setNombre(response.data.results.nombre)
            setRazonSocial(response.data.results.razonsocial)
            setCuit(response.data.results.cuit)
            setFechaInicio(response.data.results.fechainicio)
            setAuxVenta(venta)
            setAuxTotal(total)

            setVenta([]);
            setFinVenta(true)

            let aux = modify + 1;
            setModify(aux)
            setDatosFactura('')

        } catch (e) {
            console.log(e)
        }
    }

    const handleSelect = (item) => {
        setCliente(item.id)
        //console.log(item)
    }

    const handleAdd = () => {
        setFormPrev(2)
        setFormView(30)
    }

    const addEnvase = () => {
        if (cantidadEnv !== '') {
            let rData = [...listEnvases];
            let nombre = "";
            let encontrado = 0;
            envases.forEach(element => {
                if (element.id === envase) {
                    nombre = element.nombre
                }
            });
            rData.forEach(element => {
                if (element.id === envase) {
                    element.cantidad = parseInt(element.cantidad) + parseInt(cantidadEnv);
                    encontrado = 1;
                }
            })
            if (encontrado === 0) {
                rData.push({
                    id: envase,
                    nombre,
                    cantidad: cantidadEnv,
                })
            }
            setListEnvases(rData)
            setCantidadEnv("")
        }
    }
    const quitEnvases = (envase) => {
        let rData = [...listEnvases];
        let rAux = [];
        rData.forEach(element => {
            if (element.id !== envase.id) {
                rAux.push(element)
            }
        });
        setListEnvases(rAux);
    }

    const calcTotal = (item) => {
        let precio = 0;
        if (item.cantidad >= item.cantidadminima) {
            let cantPromos = parseFloat(item.cantidad) / parseFloat(item.cantidadminima);
            let cantPagos = item.cantidad - (parseFloat(item.cantidaddescuento) * cantPromos);
            precio = cantPagos * parseFloat(item.precio);
        } else {
            precio = parseFloat(item.cantidad) * parseFloat(item.precio);
        }
        return (precio.toLocaleString("es-AR", { maximumFractionDigits: 2 }));
    }


    return (
        <div className="w-full mx-auto">
            {!finVenta ? (
                <div className='w-10/12 mx-auto'>
                    <div className='w-full mx-auto'>
                        <p className='text-center bg-orange-200 p-4 rounded-lg text-5xl font-light'>Ésta venta ${total}</p>
                    </div>
                    <div className='w-full sm:flex'>
                        <div className='bg-gray-50 rounded-lg p-5 sm:w-1/2'>
                            {typeof medios !== 'undefined' && medios.map((item, index) =>
                                <div key={index} className="radio">
                                    <label>
                                        <input
                                            type="radio"
                                            name="medioPago"
                                            value={item.id}
                                            checked={medioPago === item.id}
                                            onChange={() => setMedioPago(item.id)}
                                            className='mr-5'
                                        />
                                        {item.descripcion}
                                    </label>
                                </div>
                            )}
                        </div>
                        <div className='bg-gray-50 rounded-lg p-5 sm:w-1/2'>
                            {!prestamo ? (
                                <button
                                    className='w-full bg-blue-500 p-3 rounded-xl text-white uppercase'
                                    onClick={() => setPrestamo(true)}
                                >
                                    Agregar envases
                                </button>
                            ) : (
                                <div>
                                    <div className='w-full'>
                                        <h3 className='text-center text-xl mb-4'>Préstamo de Envases</h3>
                                        <div className='w-full flex'>
                                            <select className='p-2 w-6/12 rounded-lg mb-5 border border-gray-300 bg-white' value={envase} onChange={(e) => setEnvase(e.target.value)}>
                                                {typeof envases !== 'undefined' && envases.map((item, index) => (
                                                    <option key={index} value={item.id}>{item.nombre}</option>
                                                ))}
                                            </select>
                                            <input
                                                type='number'
                                                className='p-2 w-3/12 rounded-lg mb-5 border border-gray-300 bg-white'
                                                placeholder='Cantidad'
                                                value={cantidadEnv}
                                                onChange={(e) => setCantidadEnv(e.target.value)}
                                            />
                                            <button type='button'
                                                className={`w-3/12 mb-5 ml-1 text-white rounded ${cantidadEnv !== '' ? 'bg-blue-500' : 'bg-blue-200'}`}
                                                onClick={() => addEnvase()}
                                            >
                                                Agregar
                                            </button>
                                        </div>
                                        <div className='w-full'>
                                            {typeof listEnvases !== 'undefined' && listEnvases.map((item, index) => (
                                                <div className='flex mt-1'>
                                                    <p key={index}>{`${item.cantidad} - ${item.nombre}`}</p>
                                                    <button
                                                        className='bg-red-600 ml-auto text-white rounded p-1'
                                                        onClick={() => quitEnvases(item)}
                                                    >Eliminar</button>
                                                </div>
                                            ))}
                                        </div>
                                        <div className='w-full mt-1 mb-5 bg-gray-50 rounded-lg'>
                                            <div className="sm:items-start">
                                                <Select
                                                    options={clientes}
                                                    placeholder="Seleccione Cliente"
                                                    onChange={(value) => handleSelect(value)}
                                                    name='sCliente'
                                                />
                                                <button type='button'
                                                    className='p-3 bg-purple-500 text-white uppercase font-semibold rounded'
                                                    onClick={() => handleAdd()}
                                                >
                                                    Agregar Nuevo Cliente
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                    <button
                                        className='w-full bg-orange-500 p-3 rounded-xl text-white uppercase'
                                        onClick={() => setPrestamo(false)}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        {medioPago === '1' && (
                            <div className='w-full mt-5 mb-10 bg-blue-200 rounded-lg p-5'>
                                <div className="sm:items-start">
                                    <label className="block text-gray-800 text-2xl uppercase font-bold" htmlFor='efectivo'>Efectivo</label>
                                    <input
                                        type='number'
                                        className='p-2 rounded border border-gray-400 w-full'
                                        value={efectivo}
                                        id="efectivo"
                                        onChange={(e) => setEfectivo(e.target.value)}
                                        autoFocus
                                    />
                                </div>
                                <p className='text-4xl text-center font-bold mt-5'>Cambio: ${efectivo >= total && parseFloat(efectivo - total).toFixed(2)}</p>
                            </div>
                        )}
                        {medioPago === '5' ? (
                            <div className='w-full mt-5 mb-10 bg-gray-50 rounded-lg p-5'>
                                <div className="sm:items-start">
                                    {datosFactura !== '' ? (
                                        <>
                                            <p className='bg-green-300 text-green-800 border border-green-800 rounded-xl p-4 w-full'>Se ha generado la factura con CAE {datosFactura}</p>
                                        </>
                                    ) : (
                                        !procesando ? (
                                            <button type='button'
                                                className='w-full p-3 bg-blue-500 text-white uppercase font-semibold rounded'
                                                onClick={() => facturaAfip()}
                                            >
                                                Generar Factura Electrónica
                                            </button>

                                        ) : (
                                            <p className='bg-gray-300 text-gray-800 border border-gray-800 rounded-xl p-4 w-full'>Aguarde mientras se genera la factura, por favor</p>
                                        )

                                    )}
                                </div>
                                <p className='text-xl font-bold mt-5'>Cambio: ${efectivo > total && parseFloat(efectivo - total)}</p>
                            </div>
                        ) : medioPago === '6' && (
                            <div className='w-full mt-5 mb-10 bg-gray-50 rounded-lg p-5'>
                                <div className="sm:items-start">
                                    <Select
                                        options={clientes}
                                        placeholder="Seleccione Cliente"
                                        onChange={(value) => handleSelect(value)}
                                        name='sCliente'
                                    />
                                    <button type='button'
                                        className='p-3 bg-orange-500 text-white uppercase font-semibold rounded'
                                        onClick={() => handleAdd()}
                                    >
                                        Agregar Cliente
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='w-1/3 mx-auto'>
                        <button type='button'
                            className='w-full p-3 bg-green-500 text-white uppercase font-semibold rounded'
                            onClick={() => finalizarVenta()}
                        >
                            Finalizar venta
                        </button>
                    </div>
                </div>

            ) : (
                <div className='w-10/12 mx-auto'>
                    <div className='print:px-4 print:text-xs print:h-svh' ref={componentRef}>
                        <div className='w-full mx-auto'>
                            <p className='text-center text-2xl font-bold print:text-lg'>{nombre}</p>
                            <p className='text-left print:pl-2 rounded-lg text-xl print:text-xs'>{`Razón Social: ${razonSocial}`}</p>

                            <p className='text-left print:pl-2 rounded-lg text-xl print:text-xs'>{`Inicio de Act.: ${fechaInicio}`}</p>
                            <div className='w-full mt-3 mb-7'>
                                <div className='w-full print:pl-2'>
                                    <p className='text-left'>Fecha {fecha}</p>
                                </div>
                                <div className='w-full print:pl-2'>
                                    <p className='text-left'>Hora {hora}</p>
                                </div>
                            </div>
                        </div>
                        {typeof auxVenta !== 'undefined' && auxVenta.map((item, index) => (
                            <div key={index} className='w-full'>
                                <div className='w-full print:pl-2 print:pr-10'>
                                    <p className='text-left print:text-xs'>{`${item.cantidad} x ${item.codigo} - ${item.descripcion}`}</p>
                                </div>
                                <div className='w-full print:pr-10'>
                                    <p className='text-right print:text-xs'>${precio ? (parseFloat(item.preciopormayor) * parseFloat(item.cantidad)).toLocaleString("es-AR", { maximumFractionDigits: 2 }) : item.tipopromo === '3' ? calcTotal(item) : (item.tipopromo === '1' || item.tipopromo === '2') ? (parseFloat(item.preciopromocion) * parseFloat(item.cantidad)).toLocaleString("es-AR", { maximumFractionDigits: 2 }): (parseFloat(item.precio) * parseFloat(item.cantidad)).toLocaleString("es-AR", { maximumFractionDigits: 2 })}</p>
                                </div>
                            </div>
                        ))}
                        <div className='w-full print:pl-2'>
                            <p className='text-left font-bold uppercase text-3xl print:text-sm'>Total</p>
                        </div>
                        <div className='w-full print:pr-10'>
                            <p className='text-right font-bold uppercase text-3xl print:text-sm'>${parseFloat(auxTotal).toLocaleString("es-AR", { maximumFractionDigits: 2 })}</p>
                        </div>
                        {typeof descuentos !== 'undefined' && descuentos.length>0 && (
                        <div className='w-full print:pl-2'>
                            <p className='text-left font-bold uppercase text-3xl print:text-sm'>Descuentos</p>
                        </div>
                        )}
                        {typeof descuentos !== 'undefined' && descuentos.map((item, index) => (
                            <div key={index} className='w-full'>
                                <div className='w-full print:pl-2 print:pr-10'>
                                    <p className='text-left print:text-xs'>{`${item.descripcion}`}</p>
                                </div>
                                <div className='w-full print:pr-10'>
                                    <p className='text-right print:text-xs'>$ {parseFloat(item.monto).toLocaleString("es-AR", { maximumFractionDigits: 2 })}</p>
                                </div>
                            </div>
                        ))}

                    </div>
                    <div className="w-full rounded-lg px-3 flex justify-center">
                        <ReactToPrint
                            trigger={() =>
                                <button
                                    type="button"
                                    className="w-1/3 sm:w-1/4 flex shadow-md p-3 ml-3 bg-indigo-500 text-white hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                                >
                                    <FaPrint className='mx-auto mt-1' />
                                    <p className='mx-auto text-center'>
                                        Imprimir Comprobante
                                    </p>
                                </button>}
                            content={() => componentRef.current}
                        />
                        <button
                            type="button"
                            className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                            onClick={() => setViewOpen(false)}
                        >
                            Salir
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default FinalizarVenta