import { useState } from 'react';
import Layout from '../../components/admin/Layout';
import ListadoCompras from '../../components/admin/listadoCompras';
import FormCompra from '../../components/admin/formCompra';

const Compras = () => {
	const [tab, setTab] = useState(0)

	return (
		<Layout
			pagina={"Compras"}
		>
			<div className='w-full p-5 min-h-full'>
				<div className='flex w-10/12 mx-auto'>
					<button 
						className={`p-2 m-2 rounded-xl ${tab===1 ? 'border border-indigo-700 text-indigo-700':'bg-indigo-700 text-white'}`}
						onClick={()=>setTab(0)}
					>
						Listado de Compras
					</button>
					<button 
						className={`p-2 m-2 rounded-xl ${tab===0 ? 'border border-indigo-700 text-indigo-700':'bg-indigo-700 text-white'}`}
						onClick={()=>setTab(1)}
					>
						Nueva Compra
					</button>
				</div>
				{tab === 0 ? (
					<ListadoCompras/>
				):(
					<FormCompra/>
				)}
			</div>
		</Layout>
	)
}

export default Compras