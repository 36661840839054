import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import { FaPen, FaTrash } from "react-icons/fa";
import axios from 'axios'
import { Url } from '../Url'


const ListadoProductosAlert = () => {
    const { modify, productos, setProductos, setProductoSelected, setFormView, setViewOpen, productoSearch } = useContext(ContextStates)

    useEffect(() => {
        const consultaProductos = async () => {
            const formData = new FormData()
            formData.append("producto", productoSearch)
                formData.append("alerta", 1)
            try {
                const response = await axios({
                    url: Url + 'api/listadoproductos.php',
                    method: 'POST',
                    data: formData,
                })
                setProductos(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        consultaProductos()
    }, [modify, productoSearch])

    const handleView = (item, action) => {
        setProductoSelected(item)
        setFormView(action)
        setViewOpen(true)
    }


    return (
        <div className="w-full py-5 px-2 md:px20 rounded-2xl">
            <div className="sm:items-start">
                <table className='w-full'>
                    <thead>
                        <tr className='p-1 bg-blue-400 text-white'>
                            <th className='px-2'>Código</th>
                            <th className='px-2'>Descripción</th>
                            <th className='px-2'>Marca</th>
                            <th className='px-2'>Precio</th>
                            <th className='px-2'>Stock</th>
                            <th className='px-2'>Minimo</th>
                            <th className='px-2'>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productos && (
                            productos.map((item, index) => (
                                <tr key={index} className={`border border-gray-300 p-2 hover:cursor-pointer ${item.stockmin > item.stock ? 'bg-red-100' : index % 2 === 0 ? 'bg-white hover:bg-slate-200' : 'bg-slate-100 hover:bg-slate-200'}`}>
                                    <td className='text-center'>{item.codigo}</td>
                                    <td className='text-center'>{item.descripcion}</td>
                                    <td className='text-center'>{item.marca}</td>
                                    <td className='text-center'>{item.precio}</td>
                                    <td className='text-center'>{item.stock}</td>
                                    <td className='text-center'>{item.stockmin}</td>
                                    <td className='text-center flex'>
                                        <button className='bg-gray-400 ml-2 hover:bg-gray-300 rounded p-2 text-white' onClick={() => handleView(item, 8)}><FaPen /></button>
                                        <button className='bg-red-500 ml-2 hover:bg-red-400 rounded p-2 text-white' onClick={() => handleView(item, 9)}><FaTrash /></button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ListadoProductosAlert