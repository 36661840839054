import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'


const FormPromociones = () => {
    const [tiposPromos, setTiposPromos] = useState([])
    const [tipoPromo, setTipoPromo] = useState(0)
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaFin, setFechaFin] = useState('')
    const [descripcion, setDescripcion] = useState("")
    const [coeficiente, setCoeficiente] = useState("")
    const [cantidadMinima, setCantidadMinima] = useState("")
    const [cantidadDescuento, setCantidadDescuento] = useState("")
    const [guardar, setGuardar] = useState(false)
    const { modify, setModify } = useContext(ContextStates)

    useEffect(() => {
        const getTiposPromos = async () => {
            const formData = new FormData()
            try {
                const results = await axios({
                    url: Url + 'api/getTiposPromos.php',
                    method: 'POST',
                    data: formData,
                })
                setTiposPromos(results.data.results)
                setTipoPromo(1)
            } catch (e) {
                console.log(e)
            }
        }
        getTiposPromos()
    }, [])

    useEffect(() => {
        let habilitado = true;
        if (fechaInicio === '' || fechaFin === '' || descripcion === '')
            habilitado = false;
        if (tipoPromo === '2') {
            if (coeficiente === '')
                habilitado = false;
        }
        else {
            if (tipoPromo === '3') {
                if (cantidadMinima === '' || cantidadDescuento === '')
                    habilitado = false;
            }
        }
        setGuardar(habilitado)
    }, [tipoPromo, fechaInicio, fechaFin, descripcion, coeficiente, cantidadMinima, cantidadDescuento])

    const addPromocion = async () => {
        const formData = new FormData()
        formData.append('tipoPromo', tipoPromo);
        formData.append('fechaInicio', fechaInicio);
        formData.append('fechaFin', fechaFin);
        formData.append('descripcion', descripcion);
        formData.append('coeficiente', coeficiente);
        formData.append('cantidadMinima', cantidadMinima);
        formData.append('cantidadDescuento', cantidadDescuento);
        try {
            const results = await axios({
                url: Url + 'api/addPromocion.php',
                method: 'POST',
                data: formData,
            })
            setTipoPromo(1)
            setFechaInicio('')
            setFechaFin('')
            setDescripcion('')
            setCoeficiente('')
            setCantidadMinima('')
            setCantidadDescuento('')
            let aux = modify + 1;
            setModify(aux)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className='w-full'>
            <div className="transition-all w-full pt-5 sm:p-3 mt-5">
                <div className="sm:items-start">
                    <div className='w-full flex'>
                        <div className='w-1/2 p-4'>
                            <label htmlFor="fechaInicio" className="w-full block mt-5 text-black uppercase font-bold">Fecha de Inicio</label>
                            <input
                                className="py-2 px-3 w-full rounded border border-gray-300"
                                id="fechaInicio"
                                placeholder="Fecha de Inicio"
                                type="date"
                                value={fechaInicio}
                                onChange={(e) => setFechaInicio(e.target.value)}
                            />
                        </div>
                        <div className='w-1/2 p-4'>
                            <label htmlFor="fechaFin" className="w-full block mt-5 text-black uppercase font-bold">Fecha de Fin</label>
                            <input
                                className="py-2 px-3 w-full rounded border border-gray-300"
                                id="fechaFin"
                                placeholder="Fecha de Fin"
                                type="date"
                                value={fechaFin}
                                onChange={(e) => setFechaFin(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='w-full p-4'>
                        <label htmlFor="descripcion" className="block mt-5 text-black uppercase font-bold">Descripición o detalle de la Promoción</label>
                        <input
                            className="py-2 px-3 w-full rounded border border-gray-300"
                            id="descripcion"
                            placeholder="Ingrese la descripción de la promoción"
                            type="text"
                            value={descripcion}
                            onChange={(e) => setDescripcion(e.target.value)}
                        />
                        <label htmlFor="sTiposPromos" className="w-full block mt-5 text-black uppercase font-bold">Tipo de Promoción</label>
                        <select
                            className='py-2 px-3 w-full rounded bg-slate-100'
                            id='sTiposPromos'
                            value={tipoPromo}
                            onChange={(e) => setTipoPromo(e.target.value)}
                        >
                            {tiposPromos && (
                                tiposPromos.map(item => (
                                    <option key={item.id} value={item.id}>{item.descripcion}</option>))
                            )}
                        </select>
                        {tipoPromo === '2' ? (
                            <>
                                <label htmlFor="porcentaje" className="block mt-5 text-black uppercase font-bold">Porcentaje de Descuento (%)</label>
                                <input
                                    className="py-2 px-3 w-full rounded border border-gray-300"
                                    id="porcentaje"
                                    placeholder="Ingrese el porcentaje de descuento"
                                    type="number"
                                    value={coeficiente}
                                    onChange={(e) => setCoeficiente(e.target.value)}
                                />
                            </>
                        ) : tipoPromo === '3' && (
                            <>
                                <label htmlFor="cantMin" className="block mt-5 text-black uppercase font-bold">Cantidad mínima</label>
                                <input
                                    className="py-2 px-3 w-full rounded border border-gray-300"
                                    id="cantMin"
                                    placeholder="Ingrese la cantidad mínima para el descuento"
                                    type="number"
                                    value={cantidadMinima}
                                    onChange={(e) => setCantidadMinima(e.target.value)}
                                />
                                <label htmlFor="cantDesc" className="block mt-5 text-black uppercase font-bold">Cantidad a descontar</label>
                                <input
                                    className="py-2 px-3 w-full rounded border border-gray-300"
                                    id="cantDesc"
                                    placeholder="Ingrese la cantidad que se descontará"
                                    type="number"
                                    value={cantidadDescuento}
                                    onChange={(e) => setCantidadDescuento(e.target.value)}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-auto">
                <div className="w-full rounded-lg px-3">
                    <button
                        type="button"
                        className={`w-full shadow-md p-3 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5 ${guardar ? 'bg-orange-600' : 'bg-gray-300'} `}
                        onClick={() => addPromocion()}
                    >
                        Agregar Promoción
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FormPromociones