
import Layout from '../../components/admin/Layout';
import FormPrecios from '../../components/admin/formPrecios';

const Precios = () => {
	

	return (
		<Layout
			pagina={"Productos"}
		>
			<div className='w-full p-5 min-h-full'>
				<h2 className='text-center text-gray-700 text-sm md:text-xl'>Gestión de Precios</h2>
				<FormPrecios/>
			</div>
		</Layout>
	)
}

export default Precios