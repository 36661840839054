import { useState, useEffect, useRef, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from 'axios'
import { Url } from '../Url'


const FormRubro = () => {
    const [valor, setValor] = useState('')
    const { setFormView, setValRubro, formPrev } = useContext(ContextStates)


    const formik = useFormik({
        initialValues: {
            descripcion: '',
        },
        validationSchema: Yup.object({
            descripcion: Yup.string()
                .required("La descripcion es obligatoria"),
        }),
        onSubmit: async (valores, { resetForm }) => {
            const formData = new FormData()
            formData.append('descripcion', valores.descripcion);
            try {
                const results = await axios({
                    url: Url + 'api/altaRubros.php',
                    method: 'POST',
                    data: formData,
                })
                setValRubro(results.data.results.id)
                resetForm()
            } catch (e) {
                console.log(e)
            }
        }
    });

    useEffect(() => {
        const check = async() => {
            const formData = new FormData()
            formData.append('descripcion', formik.values.descripcion);
            try {
                const results = await axios({
                    url: Url + 'api/checkRubro.php',
                    method: 'POST',
                    data: formData,
                })
                console.log("rubros ",results.data.results.descripcion)
                setValor(results.data.results.descripcion)
            } catch (e) {
                console.log(e)
            }
        }
        check()
    }, [formik.values.descripcion])

    return (
        <form
            className="w-full py-5 px-20 rounded-2xl"
            onSubmit={formik.handleSubmit}
        >
            <div className="transition-all w-full pt-5 sm:p-3 mt-5">
                <h2 className='text-center text-gray-700 text-xl'>Agregar Rubros</h2>
                <div className="sm:items-start">
                    <label htmlFor="descripcion" className="w-full block mt-5 text-black uppercase font-bold">Descripción</label>
                    <input
                        className="py-2 px-3 w-full border border-gray-300 rounded"
                        id="descripcion"
                        placeholder="Descripción"
                        type="text"
                        value={formik.values.descripcion}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoFocus
                    />
                    {formik.touched.descripcion && formik.errors.descripcion ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.descripcion}</p>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="pb-10">
                { valor!=='' && formik.values.descripcion!='' && (
                    <p className='bg-red-200 border border-red-500 rounded p-3 text-red-500'>Existe un rubro con el nombre {valor}</p>
                )}
                <div className="w-full rounded-lg px-3">
                    <input
                        type="submit"
                        className="w-full shadow-md p-3 bg-orange-600 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5"
                        value="Guardar Datos"
                    />
                    {formPrev !== 0 && (
                        <button
                            className="w-full shadow-md p-3 bg-gray-600 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5"
                            onClick={()=>setFormView(formPrev)}
                        >
                            Volver
                        </button>

                    )}
                </div>
            </div>
        </form>
    )
}

export default FormRubro