import { useState, useEffect, useRef, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'
import { FaPlus } from "react-icons/fa";
import Select from 'react-select';


const FormProductoPromo = () => {
    const [productosPromo, setProductosPromo] = useState([])
    const [productos, setProductos] = useState([])
    const [producto, setProducto] = useState(0)
    const { promoSelected, setViewOpen, modify, setModify } = useContext(ContextStates)

    useEffect(() => {
        const getProductos = async () => {
            const formData = new FormData()
            try {
                const results = await axios({
                    url: Url + 'api/listadoproductos.php',
                    method: 'POST',
                    data: formData,
                })
                setProductos(results.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getProductos();
    }, [])

    useEffect(() => {
        let rData = [...promoSelected.productos]
        setProductosPromo(rData)
    }, [])

    const addProductoPromo = async () => {
        if (producto!==0) {
            const formData = new FormData()
            formData.append('idproducto', producto);
            formData.append('idpromo', promoSelected.id);
            try {
                const results = await axios({
                    url: Url + 'api/addProductoPromo.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(results)
                setProductosPromo(results.data.results.productos)
    
                let aux = modify + 1;
                setModify(aux)    
            } catch (e) {
                console.log(e)
            }    
        }
    }

    const delProductoPromo = async (id) => {
        console.log("id ", id)
        const formData = new FormData()
        formData.append('id', id);
        try {
            const results = await axios({
                url: Url + 'api/delProductoPromo.php',
                method: 'POST',
                data: formData,
            })
            //console.log(results)

            let aux = modify + 1;
            setModify(aux)

            let rData = [];
            productosPromo.forEach(element => {
                if (element.id !== id) {
                    rData.push(element)
                }
            });
            setProductosPromo(rData)
        } catch (e) {
            console.log(e)
        }
    }

    const handleSelect = (element) => {
        setProducto(element.id)
    }

    return (
        <div className="w-full px-20 rounded-2xl">
            <div className="transition-all w-full">
                <h2 className='text-center text-gray-700 text-2xl'>Promoción - Productos</h2>
                <p className='font-bold text-xl text-gray-700 mt-5'>Promoción: {promoSelected.descripcion}</p>
                <div className="sm:items-start mt-10">
                    <label htmlFor="" className="w-full block mt-5 text-black uppercase font-bold">Producto a agregar a la promoción actual</label>
                    <Select
                        options={productos}
                        placeholder="Seleccione Producto para agregar a la promoción"
                        onChange={(value) => handleSelect(value)}
                        name='sProducto'
                    />
                    <button
                        type='button'
                        className={`mt-2 text-white p-3 rounded font-bold ${producto!==0 ? 'bg-blue-600' : 'bg-blue-200'}`}
                        onClick={() => addProductoPromo()}
                    >Agregar Producto seleccionado a la promoción</button>
                </div>
            </div>
            <div className="pb-10 mt-10">
                <table className='w-full mx-auto'>
                    <thead>
                        <tr className='p-1 bg-blue-400 text-white text-center'>
                            <th>Descripcion</th>
                            <th>Precio</th>
                            <th>Promoción</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>

                        {typeof productosPromo !== 'undefined' && productosPromo.map((item, index) => (
                            <tr key={index} className={`hover:cursor-pointer hover:bg-slate-200 border-b border-gray-200 text-center ${index % 2 === 0 ? 'bg-white ' : 'bg-slate-100'} )`}>
                                <td>{item.descripcion}</td>
                                <td>{item.precio}</td>
                                <td>{item.promo}</td>
                                <td className='text-center'>
                                    <button
                                        className='bg-red-600 hover:bg-blue-400 rounded-lg py-1 px-3 text-white'
                                        onClick={() => delProductoPromo(item.id)}
                                    >Eliminar
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="w-full rounded-lg px-3">
                    <button
                        type="button"
                        className="w-full shadow-md p-3 bg-gray-600 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5"
                        onClick={() => setViewOpen(false)}
                    >
                        Cerrar
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FormProductoPromo