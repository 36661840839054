import { useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'


const FDeleteLinCompra = () => {
    const { modify, setModify, setViewOpen, detalleCompra, setDetalleCompra, lineaCompra } = useContext(ContextStates)

    const modificarLinea = async () => {
        const formData = new FormData()
        formData.append('id', lineaCompra.id);
        formData.append('stock', lineaCompra.stock);
        
        try {
            const results = await axios({
                url: Url + 'api/delLineaCompra.php',
                method: 'POST',
                data: formData,
            })
            console.log(results)
            let aux = modify + 1;
            setModify(aux)
            let rData = [];
            detalleCompra.forEach(element => {
                if (element.id!==lineaCompra.id) {
                    rData.push(element)
                }
            });
            setDetalleCompra(rData)
            setViewOpen(false)
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <div className="transition-all w-full pt-5 sm:p-3 mt-5 mb-5">
            <h2 className='text-center text-gray-700 text-xl'>{lineaCompra.descripcion}</h2>
            <div className="pb-1">
                <div className="w-full rounded-lg px-3 flex justify-center">
                    <button
                        type='button'
                        className='w-full p-2 bg-red-500 text-white uppercase font-semibold rounded'
                        onClick={() => modificarLinea()}
                    >
                        Eliminar
                    </button>
                    <button
                        type="button"
                        className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                        onClick={() => setViewOpen(false)}
                    >
                        Salir
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FDeleteLinCompra