import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Url } from '../Url'

const Alertas = () => {
    const [valores, setValores] = useState([])

    useEffect(() => {
        const getValores = async () => {
            const formData = new FormData()
            try {
                const response = await axios({
                    url: Url + 'api/valoresInicio.php',
                    method: 'POST',
                    data: formData,
                })
                setValores(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getValores()

    }, [])

    return (
        <div>
            <div className='flex p-5'>
                {valores.alerta > 0 && (
                    <Link to={"/admin/productos"} >
                        <p className='bg-fuchsia-500 p-1 rounded-xl text-white font-semibold'>Stock bajo: {valores.alerta}</p>
                    </Link>
                )}
                {valores.vto === 0 && (
                    <Link to={"/admin/productos"} >
                        <p className='ml-5 bg-red-600 p-1 rounded-xl text-white font-semibold'>Vto. Próximo: {valores.vto}</p>
                    </Link>
                )}
            </div>
        </div>
    )
}

export default Alertas