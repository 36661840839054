import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Layout from '../components/Layout';
import ContextStates from '../context/ContextStates';
import { motion } from 'framer-motion';

import { useParams } from 'react-router-dom'
import { Url } from '../components/Url';
import { areaAnimateLR, areaAnimateS } from '../components/Animations'


const Producto = () => {
	const [ producto, setProducto ] = useState({});
	const { setViewOpen, subModelo, setSubModelo , setFormView } = useContext(ContextStates);

	let modelo=useParams()
	useEffect(()=>{
		const getProducto = async() => {
			setSubModelo({})
			const url = `${Url}api/producto.php?idmodelo=${modelo.id}`;
            const resultado = await axios.get(url);
            setProducto(resultado.data.results);
		}
		getProducto()
	},[])

	const handleSelect = (item) => {
		setSubModelo({
			id:item.id,
			descripcion:item.descripcion,
			marca:producto.marca,
			descripcionproducto:producto.descripcionproducto,
			imagen:producto.imagen,
			precio:item.precio,
			stock: parseInt(item.stock),
			pausado:item.pausado
		})
		
	}

	const handleAdd = () => {
		setFormView(1);
		setViewOpen(true);
	}

	return (
		<Layout
			pagina={"Producto"}
		>
			<div className='w-full pt-20 pb-20'>
				<div className='md:w-9/12 w-full mx-auto flex shadow-xl rounded-xl'>
					<div className='w-2/3 mt-10 mb-10'>
						<motion.img 
							className="mx-auto" src={Url+producto.imagen}
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{once:true, amount: 0.1}}
							variants={areaAnimateLR}
						/>
						<motion.h3 
							className="text-center text-2xl font-semibold uppercase"
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{once:true, amount: 0.1}}
							variants={areaAnimateLR}
						>{producto.marca}</motion.h3>
                        <motion.p 
							className="text-center text-xl"initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{once:true, amount: 0.1}}
							variants={areaAnimateLR}
						>{producto.descripcionproducto}</motion.p>
                        <motion.p 
							className="text-center text-lg"initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{once:true, amount: 0.1}}
							variants={areaAnimateLR}
						>{producto.descripcion}</motion.p>
					</div>
					<motion.div 
						className='w-1/3 bg-slate-100 rounded-r-xl sm:p-10 p-1'
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{once:true, amount: 0.1}}
						variants={areaAnimateS}
					>
						<div className='w-full'>
						{ producto.modelos ? producto.modelos.map((item,index)=>(
                        	<p 
								key={index}
								className="text-center text-md mb-2 hover:cursor-pointer"
								onClick={()=>handleSelect(item)}
							>
								{item.descripcion}
							</p>
            			)):(null)}
						</div>
						{ subModelo.precio && (
						<div className='w-full mt-20'>
							<p className='text-xl text-center font-semibold'>{subModelo.descripcion}</p>
							<p className='text-xl text-center mt-5'>${subModelo.precio}</p>
							{ subModelo.pausado!=='1' ? (
							<>
								<p className='text-sm text-center text-gray-400 mt-5'>{subModelo.stock} Disponibles</p>
								<button 
									className='mt-5 w-full bg-blue-500 text-md uppercase text-white p-2 rounded-xl' 
									type='button'
									onClick={()=>handleAdd()}
								>Agregar al carro
								</button>							
							</>
							):(<p className='bg-orange-200 w-full p-3 rounded mt-5 text-center'>Articulo Pausado</p>)}
						</div>
						)}
					</motion.div>
				</div>
				<div className='md:w-2/3 w-full mx-auto mt-10 p-3'>
					<p className='text-center text-md'>{producto.descripcionext}</p>
				</div>
			</div>
		</Layout>
	)
}

export default Producto