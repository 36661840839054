import { useState, useContext, useEffect } from 'react'
import authContext from '../context/auth/authContext';
import FormLogin from '../components/forms/FormLogin';
import Layout from '../components/Layout'
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [verLogin, setVerLogin] = useState(true)
    const AuthContext = useContext(authContext);
    const { autenticado } = AuthContext;
    const navigate = useNavigate();


    useEffect(() => {

        if (autenticado) {
            navigate("/", { replace: true });
        }
    }, [autenticado])

    return (
        <Layout
            pagina="Iniciar Sesión"
        >
            <main className='w-full h-full bg-[url("https://lideraturismo.com.ar/lidera/img/logos/logo.png")] bg-repeat'>
                <div className='bg-indigo-800/90 w-full h-full pb-40 pt-2 mt-0'>
                    <h1 className='mt-5 text-white text-5xl text-center'>Iniciar Sesión</h1>
                    <div>
                        {
                            verLogin ? (
                                <div>
                                    <div className="w-full md:w-1/3 mx-auto rounded-lg px-11 mb-5">
                                        <FormLogin />
                                        <button
                                            type="button"
                                            className="w-full shadow-md p-3 bg-indigo-500 hover:bg-indigo-400 transition-all text-white uppercase font-bold rounded-lg mt-5"
                                            onClick={() => setVerLogin(false)}
                                        >
                                            Crear Cuenta
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="w-full md:w-1/3 mx-auto rounded-lg px-11 mb-5">
                                        <button
                                            type="button"
                                            className="w-full shadow-md p-3 bg-indigo-500 hover:bg-indigo-400 transition-all text-white uppercase font-bold rounded-lg mt-5"
                                            onClick={() => setVerLogin(true)}
                                        >
                                            Ir a Iniciar Sesión
                                        </button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </main>
        </Layout>

    )
}

export default Login