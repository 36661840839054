import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios';
import { Url } from '../Url';
import { FaPen, FaTrash } from 'react-icons/fa'

const DetalleCompra = () => {
    const [total, setTotal] = useState(0)
    const { modify, setModify, detalleCompra, setDetalleCompra, proveedor, idUsuario, setLineaCompra, setFormView, setViewOpen } = useContext(ContextStates)


    useEffect(() => {
        const calcTotal = () => {
            let t = 0;
            if (typeof detalleCompra !== 'undefined') {
                detalleCompra.forEach(element => {
                    t = t + (parseFloat(element.preciocosto) * parseFloat(element.stock))
                });
                setTotal(t)
            }
        }
        calcTotal()
    }, [detalleCompra])

    const finCompra = async () => {
        const auxCompra = JSON.stringify(detalleCompra)
        const formData = new FormData()
        formData.append('compra', auxCompra);
        formData.append('proveedor', proveedor);
        formData.append('idusuario', idUsuario);
        formData.append('total', total);


        //formData.append('cantidad', producto.cantidad);
        try {
            const response = await axios({
                url: Url + 'api/finCompra.php',
                'Content-Type': 'application/json',
                method: 'POST',
                data: formData,
                dataType: 'JSON',
            })
            console.log(response)
            let aux = modify + 1;
            setModify(aux)
            setDetalleCompra([])
        } catch (e) {
            console.log(e)
        }

    }

    const editarLinea = (item) => {
        setLineaCompra(item);
        setFormView(90);
        setViewOpen(true);
    }

    const eliminarLinea = (item) => {
        setLineaCompra(item);
        setFormView(91);
        setViewOpen(true);        
    }

    return (
        <div className="w-full border border-blue-800 rounded mt-12 p-3">
            <h2 className='text-center text-gray-700 text-xl uppercase'>Detalle de Compra</h2>
            {typeof detalleCompra !== 'undefined' && detalleCompra.length > 0 && (
                <table className='w-full mx-auto'>
                    <thead>
                        <tr className='p-1 bg-blue-400 text-white text-center'>
                            <th>Cantidad</th>
                            <th>Código</th>
                            <th>Descripción</th>
                            <th>Precio costo Uni.</th>
                            <th>Subtotal</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            detalleCompra.map((item, index) => (
                                <tr key={index} className='border border-gray-300 p-2 text-center'>
                                    <td>{item.stock}</td>
                                    <td>{item.codigo}</td>
                                    <td>{item.descripcion}</td>
                                    <td>$ {parseFloat(item.preciocosto)}</td>
                                    <td>$ {parseFloat(item.preciocosto) * parseFloat(item.stock)}</td>
                                    <td className='p-1 flex justify-center'>
                                        <button
                                            className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                            onClick={() => editarLinea(item)}
                                        >
                                            <FaPen />
                                        </button>
                                        <button
                                            className='bg-red-500 p-3 rounded-md text-white ml-3'
                                            onClick={() => eliminarLinea(item)}
                                        >
                                            <FaTrash />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                        <tr className='border border-gray-300 p-2 text-center'>
                            <th colSpan={4}>Total</th>
                            <th>${total}</th>
                            <th></th>
                        </tr>
                    </tbody>
                </table>
            )}
            {detalleCompra.length > 0 && (
                <div className='w-1/3 mx-auto mt-5'>
                    <button
                        type='button'
                        className='w-full p-2 bg-red-500 text-white uppercase font-semibold rounded'
                        onClick={() => finCompra()}
                    >
                        Finalizar Compra
                    </button>
                </div>

            )}
        </div>
    )
}

export default DetalleCompra