import Layout from '../components/Layout';
import FormJornadas from '../components/formJornadas';

const Jornadas = () => {

	return (
		<Layout
			pagina={"Jornada"}
		>
			<div className='w-full'>
                <FormJornadas />
			</div>
		</Layout>
	)
}

export default Jornadas