import { 
    REGISTRO_EXITOSO,
    REGISTRO_ERROR,
    LIMPIAR_ALERTA,
    RESTABLECER_REGISTRO,
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    USUARIO_AUTENTICADO,
    CERRAR_SESION
 } from "../../types";


export default (state, action) => {
    switch(action.type) {

        case REGISTRO_EXITOSO:
            return {
                ...state,
                mensaje: action.payload,
                registradoOk: true
            }
        case REGISTRO_ERROR:
        case LOGIN_ERROR:
            return {
                ...state,
                mensaje: action.payload
            }
        case LOGIN_EXITOSO:
            localStorage.setItem('pvp_tk',action.payload.token)
            localStorage.setItem('pvp_em',action.payload.mail)
            localStorage.setItem('pvp_id',action.payload.id)
            return {
                ...state,
                token: action.payload,
                autenticado: true
            }
        
        case LIMPIAR_ALERTA:
            return {
                ...state,
                mensaje: null
            }
        case RESTABLECER_REGISTRO:
            return {
                ...state,
                registradoOK: false
            }
        case USUARIO_AUTENTICADO:
            localStorage.setItem('pvp_id',action.payload.id)
            return {
                ...state,
                usuario:action.payload,
                autenticado: true
            }
        case CERRAR_SESION:
            localStorage.removeItem('pvp_tk');
            localStorage.removeItem('pvp_em');
            localStorage.removeItem('pvp_id');
            return {
                ...state,
                usuario: null,
                token: null,
                autenticado: null,
                nivel: null
            }
        default: 
            return state;
    }
}