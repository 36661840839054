import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Url } from '../Url'

const ValoresInicio = () => {
    const [valores, setValores] = useState([])

    useEffect(() => {
        const getValores = async () => {
            const formData = new FormData()
            try {
                const response = await axios({
                    url: Url + 'api/valoresInicio.php',
                    method: 'POST',
                    data: formData,
                })
                setValores(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getValores()

    }, [])

    return (
        <div>
            <div className='flex p-5'>
                <div className='w-full md:w-1/3 p-3'>
                    <Link to={"/admin/productos"}>
                        <div className='bg-slate-50 hover:bg-slate-100 rounded-lg p-5 h-52 border border-slate-50 shadow-lg hover:shadow-2xl'>
                            <h3 className='text-2xl text-center font-semibold text-gray-600 mb-3'>Productos</h3>
                            {typeof valores != 'undefined' && (
                                <>
                                    <p className='text-left ml-10 text-blue-600 font-semibold'>Productos: {valores.cantidad}</p>
                                    <p className='text-left ml-10 text-red-600 font-semibold'>Productos con Stock bajo: {valores.alerta}</p>
                                    <p className='text-left ml-10 text-red-600 font-semibold'>Productos con Vencimiento Próximo: {valores.vto}</p>
                                </>
                            )}
                        </div>
                    </Link>
                </div>
                <div className='w-full md:w-1/3 p-3'>
                    <Link to={"/admin/ventas"}>
                        <div className='bg-slate-50 hover:bg-slate-100 rounded-lg p-5 h-52 border border-slate-50 shadow-lg hover:shadow-2xl'>
                            <h3 className='text-2xl text-center font-semibold text-gray-600 mb-5'>Ventas</h3>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ValoresInicio