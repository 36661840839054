import { useState, useEffect, useRef, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from 'axios'
import { Url } from '../Url'
import { FaPlus } from "react-icons/fa";


const FormMarca = () => {
    const [rubros, setRubros] = useState([])
    const [rubro, setRubro] = useState(0)
    const [valor, setValor] = useState('')
    const { setValMarca, valRubro, setFormView, setViewOpen, setFormPrev } = useContext(ContextStates)

    useEffect(() => {
        const consultaRubros = async () => {
            const url = Url + 'api/listRubros.php';
            const resultado = await axios.get(url);
            setRubros(resultado.data.results);
            setRubro(resultado.data.results[0].id)
        }
        consultaRubros()
    }, [valRubro])


    const formik = useFormik({
        initialValues: {
            descripcion: '',
        },
        validationSchema: Yup.object({
            descripcion: Yup.string()
                .required("La descripcion es obligatoria"),
        }),
        onSubmit: async (valores, { resetForm }) => {
            const formData = new FormData()
            formData.append('descripcion', valores.descripcion);
            formData.append('rubro', rubro);
            try {
                const results = await axios({
                    url: Url + 'api/altaMarcas.php',
                    method: 'POST',
                    data: formData,
                })
                setValMarca(results.data.results.id)
                resetForm()
                setViewOpen(false)
                setFormPrev(0)
            } catch (e) {
                console.log(e)
            }
        }
    });

    useEffect(() => {
        const check = async () => {
            const formData = new FormData()
            formData.append('descripcion', formik.values.descripcion);
            try {
                const results = await axios({
                    url: Url + 'api/checkMarca.php',
                    method: 'POST',
                    data: formData,
                })
                setValor(results.data.results.descripcion)
            } catch (e) {
                console.log(e)
            }
        }
        check()
    }, [formik.values.descripcion])

    const handleAdd = (form) => {
        setFormPrev(4)
        setFormView(form)
        setViewOpen(true)
    }



    return (
        <form
            className="w-full py-5 px-20 rounded-2xl"
            onSubmit={formik.handleSubmit}
        >
            <div className="transition-all w-full pt-5 sm:p-3 mt-5">
                <h2 className='text-center text-gray-700 text-xl'>Agregar Marcas</h2>
                <div className="sm:items-start">
                    <label htmlFor="sRubro" className="w-full block mt-5 text-gray-600 uppercase">Rubro</label>

                    <div className='flex'>
                        <select
                            className='py-2 px-3 w-full rounded bg-slate-100 mr-2'
                            id='sRubro'
                            onChange={(e) => setRubro(e.target.value)}
                        >
                            {rubros && (
                                rubros.map(item => (
                                    <option key={item.id} value={item.id}>{item.descripcion}</option>))
                            )}
                        </select>
                        <button
                            type='button'
                            className=' bg-violet-600 p-3 rounded-lg text-white'
                            onClick={() => handleAdd(6)}
                        ><FaPlus /></button>
                    </div>

                    <label htmlFor="descripcion" className="w-full block mt-5 text-black uppercase font-bold">Descripción</label>
                    <input
                        className="py-2 px-3 w-full rounded"
                        id="descripcion"
                        placeholder="Descripción"
                        type="text"
                        value={formik.values.descripcion}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoFocus
                    />
                    {formik.touched.descripcion && formik.errors.descripcion ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.descripcion}</p>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="pb-10">
                {valor !== '' && formik.values.descripcion!='' && (
                    <p className='bg-red-200 border border-red-500 rounded p-3 text-red-500'>Existe un rubro con el nombre {valor}</p>
                )}

                <div className="w-full rounded-lg px-3">
                    <input
                        type="submit"
                        className="w-full shadow-md p-3 bg-orange-600 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5"
                        value="Guardar Datos"
                    />
                </div>
            </div>
        </form>
    )
}

export default FormMarca