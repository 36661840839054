import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'


const FormPrecios = () => {
    const [marcas, setMarcas] = useState([])
    const [marca, setMarca] = useState(0)
    const [rubros, setRubros] = useState([])
    const [rubro, setRubro] = useState(0)
    const [precioCosto, setPrecioCosto] = useState(true)
    const [precioVenta, setPrecioVenta] = useState(true)
    const [porcentaje, setPorcentaje] = useState('')
    const { modify, setModify } = useContext(ContextStates)

    useEffect(() => {
        const consultaMarcas = async () => {
            const url = Url + 'api/listadomarcas.php?marcacero=1';
            const resultado = await axios.get(url);
            setMarcas(resultado.data.results);
            setMarca(resultado.data.results[0].id)
        }
        consultaMarcas()
    }, [])
    useEffect(() => {
        const consultaRubros = async () => {
            const url = Url + 'api/listRubros.php?rubrocero=1';
            const resultado = await axios.get(url);
            setRubros(resultado.data.results);
        }
        consultaRubros()
    }, [])

    const setPrecios = async () => {
        if (porcentaje !== 0) {
            let costo=0
            let venta=0
            if (precioCosto)
                costo=1
            if (precioVenta)
                venta=1
            const formData = new FormData()
            formData.append('porcentaje', porcentaje);
            formData.append('idmarca', marca);
            formData.append('idrubro', rubro);
            formData.append('precioCosto', costo);
            formData.append('precioVenta', venta);

            try {
                const results = await axios({
                    url: Url + 'api/setPrecios.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(results)
                let aux = modify+1;
                setModify(aux)
                setMarca(0)
                setPorcentaje('')
            } catch (e) {
                console.log(e)
            }
        }
    }

    return (
        <div>
            <div className="transition-all w-full pt-5 sm:p-3 mt-5">
                <div className="sm:items-start">
                    <label className="w-full block mt-5 text-black uppercase font-bold">
                        <input className='mr-1' type='checkbox' onChange={() => setPrecioCosto(!precioCosto)} checked={precioCosto} />
                        Precio de Costo
                    </label>
                    <label className="w-full block mt-5 text-black uppercase font-bold">
                        <input className='mr-1' type='checkbox' onChange={() => setPrecioVenta(!precioVenta)} checked={precioVenta} />
                        Precio de Venta
                    </label>

                    <label htmlFor="sMarca" className="w-full block mt-5 text-black uppercase font-bold">Marca</label>
                    <select
                        className='py-2 px-3 w-full rounded bg-slate-100'
                        id='sMarca'
                        value={marca}
                        onChange={(e) => setMarca(e.target.value)}
                    >
                        {marcas && (
                            marcas.map(item => (
                                <option key={item.id} value={item.id}>{item.descripcion}</option>))
                        )}
                    </select>
                    <label htmlFor="sRubro" className="w-full block mt-5 text-black uppercase font-bold">Rubro</label>
                    <select
                        className='py-2 px-3 w-full rounded bg-slate-100'
                        id='sRubro'
                        value={rubro}
                        onChange={(e) => setRubro(e.target.value)}
                    >
                        {rubros && (
                            rubros.map(item => (
                                <option key={item.id} value={item.id}>{item.descripcion}</option>))
                        )}
                    </select>
                    <label htmlFor="porcentaje" className="block mt-5 text-black uppercase font-bold">Porcentaje de Incremento (%)</label>
                    <input
                        className="py-2 px-3 w-full rounded border border-gray-300"
                        id="porcentaje"
                        placeholder="Ingrese el porcentaje de incremento"
                        type="text"
                        value={porcentaje}
                        onChange={(e) => setPorcentaje(e.target.value)}
                        autoFocus
                    />
                </div>
            </div>
            <div className="mt-auto">
                <div className="w-full rounded-lg px-3">
                    <button
                        type="button"
                        className={`w-full shadow-md p-3 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5 ${((porcentaje !== '0') && (porcentaje !== '')) ? 'bg-orange-600' : 'bg-gray-300'} `}
                        onClick={() => setPrecios()}
                    >
                        Realizar Cambios
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FormPrecios