import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import ContextStates from '../../context/ContextStates';
import BarChartGr from './charts/barChart';
import { Url } from '../Url';

const FViewReporting = () => {
    const [ventas, setVentas] = useState([])
    const [productos, setProductos] = useState([])
    const [recaudacion, setRecaudacion] = useState([])
    const [vMedios, setVMedios] = useState([])
    const [recMedios, setRecMedios] = useState({})
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaFin, setFechaFin] = useState('')
    const [puntos, setPuntos] = useState([])
    const [punto, setPunto] = useState(0)

    const [usuarios, setUsuarios] = useState([])
    const [usuario, setUsuario] = useState(0)

    const { } = useContext(ContextStates);

    useEffect(() => {
        const getPuntos = async () => {
            const formData = new FormData()
            formData.append('puntocero', 1);
            try {
                const results = await axios({
                    url: Url + 'api/listPuntos.php',
                    method: 'POST',
                    data: formData,
                })
                setPuntos(results.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        getPuntos()
    }, [])

    useEffect(() => {
        const getUsuarios = async () => {
            const formData = new FormData()
            formData.append('usuariocero', 1);
            try {
                const results = await axios({
                    url: Url + 'api/listUsrs.php',
                    method: 'POST',
                    data: formData,
                })
                setUsuarios(results.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        getUsuarios()
    }, [])

    useEffect(() => {
        const getVentas = async () => {
            const formData = new FormData()
            formData.append('fechainicio', fechaInicio);
            formData.append('fechafin', fechaFin);
            formData.append('punto', punto)
            formData.append('idusuario', usuario)
            try {
                const response = await axios({
                    url: Url + 'api/ventasRep.php',
                    method: 'POST',
                    data: formData,
                })
                setVentas(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getVentas()
    }, [fechaInicio, fechaFin, punto, usuario])

    useEffect(() => {
        const getRecaudacion = async () => {
            const formData = new FormData()
            formData.append('fechainicio', fechaInicio);
            formData.append('fechafin', fechaFin);
            formData.append('punto', punto)
            formData.append('idusuario', usuario)
            try {
                const response = await axios({
                    url: Url + 'api/recaudacionRep.php',
                    method: 'POST',
                    data: formData,
                })
                setRecaudacion(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getRecaudacion()
    }, [fechaInicio, fechaFin, punto, usuario])

    useEffect(() => {
        const getVMedios = async () => {
            const formData = new FormData()
            formData.append('fechainicio', fechaInicio);
            formData.append('fechafin', fechaFin);
            formData.append('punto', punto)
            formData.append('idusuario', usuario)
            try {
                const response = await axios({
                    url: Url + 'api/ventasMedRep.php',
                    method: 'POST',
                    data: formData,
                })
                //console.log(response)
                setVMedios(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getVMedios()
    }, [fechaInicio, fechaFin, punto, usuario])

    useEffect(() => {
        const getVRecMedios = async () => {
            const formData = new FormData()
            formData.append('fechainicio', fechaInicio);
            formData.append('fechafin', fechaFin);
            formData.append('punto', punto)
            formData.append('idusuario', usuario)
            try {
                const response = await axios({
                    url: Url + 'api/recMedRep.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response)
                setRecMedios(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getVRecMedios()
    }, [fechaInicio, fechaFin, punto, usuario])


    useEffect(() => {
        const getProductos = async () => {
            const formData = new FormData()
            formData.append('fechainicio', fechaInicio);
            formData.append('fechafin', fechaFin);
            formData.append('punto', punto)
            formData.append('idusuario', usuario)
            try {
                const response = await axios({
                    url: Url + 'api/productosRep.php',
                    method: 'POST',
                    data: formData,
                })
                //console.log(response)
                setProductos(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getProductos()
    }, [fechaInicio, fechaFin, punto, usuario])


    return (
        <div className='w-full mt-10 mb-10 px-10'>
            <h1 className='text-center text-gray-500 text-3xl'>Modulo de Reportes</h1>
            <div className='flex'>
                <div className='w-1/2 p-5'>
                    <label htmlFor="fechaInicio" className="block text-gray-600 uppercase font-bold">Fecha de Inicio</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-50 border border-gray-300"
                        id="fechaInicio"
                        placeholder="Fecha de inicio"
                        type="date"
                        value={fechaInicio}
                        onChange={(e) => setFechaInicio(e.target.value)}
                    />
                </div>
                <div className='w-1/2 p-5'>
                    <label htmlFor="fechaFin" className="block text-gray-600 uppercase font-bold">Fecha de Fin</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-50 border border-gray-300"
                        id="fechaFin"
                        placeholder="Fecha de fin"
                        type="date"
                        value={fechaFin}
                        onChange={(e) => setFechaFin(e.target.value)}
                    />
                </div>
            </div>
            {puntos && puntos.length > 2 && (
                <div className='w-full p-5'>
                    <select
                        className='py-2 px-3 w-full rounded bg-slate-100 mr-2'
                        id='sPunto'
                        value={punto}
                        onChange={(e) => setPunto(e.target.value)}
                    >
                        {puntos.map((item, index) => (
                            <option key={index} value={item.id}>{item.nombre}</option>))
                        }
                    </select>
                </div>
            )}
            {usuarios && (
                <div className='w-full p-5'>
                    <select
                        className='py-2 px-3 w-full rounded bg-slate-100 mr-2'
                        id='sUsuario'
                        value={usuario}
                        onChange={(e) => setUsuario(e.target.value)}
                    >
                        {usuarios.map((item, index) => (
                            <option key={index} value={item.id}>{item.nombre}</option>))
                        }
                    </select>
                </div>
            )}
            <div className='w-full mt-10 border-t-2'>
                <div className='w-full py-5 px-3'>
                    <div className='w-full h-full bg-white'>
                        <h4 className='text-black text-center text-4xl my-5 uppercase'>Recaudación por Medios de Pago</h4>
                        <table className='w-full'>
                            <thead>
                                <tr className='p-1 bg-blue-400 text-white'>
                                    <th className='px-2'>Medio de Pago</th>
                                    <th className='px-2'>Monto recaudado</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className={`border border-gray-300 p-2 hover:cursor-pointer`}>
                                    <td className='text-center'>Efectivo</td>
                                    <td className='text-center'>{recMedios.Efectivo}</td>
                                </tr>
                                <tr className={`border border-gray-300 p-2 hover:cursor-pointer`}>
                                    <td className='text-center'>Tarjeta de débito</td>
                                    <td className='text-center'>{recMedios.Debito}</td>
                                </tr>
                                <tr className={`border border-gray-300 p-2 hover:cursor-pointer`}>
                                    <td className='text-center'>Tarjeta de crédito</td>
                                    <td className='text-center'>{recMedios.Credito}</td>
                                </tr>
                                <tr className={`border border-gray-300 p-2 hover:cursor-pointer`}>
                                    <td className='text-center'>Transferencia</td>
                                    <td className='text-center'>{recMedios.Transf}</td>
                                </tr>
                                <tr className={`border border-gray-300 p-2 hover:cursor-pointer`}>
                                    <td className='text-center'>Otro</td>
                                    <td className='text-center'>{recMedios.Otro}</td>
                                </tr>
                                <tr className={`border border-gray-300 p-2 hover:cursor-pointer font-bold uppercase`}>
                                    <td className='text-center'>Total</td>
                                    <td className='text-center'>{recMedios.Total}</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <div className='w-full flex mt-10'>
                <div className='w-full md:w-1/2 h-96 py-5 px-3 md:px-3 bg-slate-50 rounded-xl'>
                    <div className='w-full h-full bg-white'>
                        <h4 className='text-black text-center text-3xl my-10 uppercase'>Cantidad de Ventas</h4>
                        {ventas &&
                            (
                                <BarChartGr data={ventas} graph={1} />
                            )}
                    </div>
                </div>
                <div className='w-full md:w-1/2 h-96 py-5 px-3 md:px-3 bg-slate-50 rounded-xl'>
                    <div className='w-full h-full bg-white'>
                        <h4 className='text-black text-center text-3xl my-10 uppercase'>Recaudación</h4>
                        {recaudacion &&
                            (
                                <BarChartGr data={recaudacion} graph={2} />
                            )}
                    </div>
                </div>
            </div>
            <div className='w-full flex mt-10 mb-40'>
                <div className='w-full md:w-1/2 h-96 py-5 px-3 md:px-3 bg-slate-50 rounded-xl'>
                    <div className='w-full h-full bg-white'>
                        <h4 className='text-black text-center text-3xl my-10 uppercase'>Cantidad de Ventas por Medios de Pago</h4>
                        {ventas &&
                            (
                                <BarChartGr data={vMedios} graph={3} />
                            )}
                    </div>
                </div>
            </div>
            <div className='w-full mt-10 border-t-2'>
                <div className='w-full py-5 px-3'>
                    <div className='w-full h-full bg-white'>
                        <h4 className='text-black text-center text-4xl my-5 uppercase'>Productos mas vendidos</h4>
                        <table className='w-full'>
                            <thead>
                                <tr className='p-1 bg-blue-400 text-white'>
                                    <th className='px-2'>Código</th>
                                    <th className='px-2'>Descripción</th>
                                    <th className='px-2'>Cantidad</th>
                                </tr>
                            </thead>
                            <tbody>
                                {typeof productos.cantidades !== 'undefined' && (
                                    productos.cantidades.map((item, index) => (
                                        <tr key={index} className={`border border-gray-300 p-2 hover:cursor-pointer ${item.stockmin > item.stock ? 'bg-red-100' : index % 2 === 0 ? 'bg-white hover:bg-slate-200' : 'bg-slate-100 hover:bg-slate-200'}`}>
                                            <td className='text-center'>{item.codigo}</td>
                                            <td className='text-center'>{item.descripcion}</td>
                                            <td className='text-center'>{parseFloat(item.cantidad).toFixed(0)}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <div className='w-full mt-10 border-t-2'>
                <div className='w-full py-5 px-3'>
                    <div className='w-full h-full bg-white'>
                        <h4 className='text-black text-center text-4xl my-5 uppercase'>Productos con mayor recaudación</h4>
                        <table className='w-full'>
                            <thead>
                                <tr className='p-1 bg-blue-400 text-white'>
                                    <th className='px-2'>Código</th>
                                    <th className='px-2'>Descripción</th>
                                    <th className='px-2'>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {typeof productos.totales !== 'undefined' && (
                                    productos.totales.map((item, index) => (
                                        <tr key={index} className={`border border-gray-300 p-2 hover:cursor-pointer ${item.stockmin > item.stock ? 'bg-red-100' : index % 2 === 0 ? 'bg-white hover:bg-slate-200' : 'bg-slate-100 hover:bg-slate-200'}`}>
                                            <td className='text-center'>{item.codigo}</td>
                                            <td className='text-center'>{item.descripcion}</td>
                                            <td className='text-center'>${parseFloat(item.total).toFixed(0)}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <div className='w-full mt-10 border-t-2'>
                <div className='w-full py-5 px-3'>
                    <div className='w-full h-full bg-white'>
                        <h4 className='text-black text-center text-4xl my-5 uppercase'>Márgenes de ganancia</h4>
                        <div className='bg-gray-50 rounded-xl p-5 shadow-xl'>
                            <p className={`text-3xl text-center font-semibold ${productos.margen > 0 ? 'text-green-600' : 'text-red-600'}`}>Total de ganancias: ${productos.margen}</p>
                            <p className={`text-3xl text-center font-semibold mt-10 ${productos.porcentaje > 0 ? 'text-green-600' : 'text-red-600'}`}>Porcentaje de ganancias: {productos.porcentaje} %</p>
                            <p className={`text-lg font-semilight mt-20 text-center text-gray-700`}>(*) Tenga en cuenta que, para obtener datos precisos de márgenes de ganancia, debe mantener siempre actualizados los precios de costo y los precios de venta.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full mt-10 border-t-2'>
                <div className='w-full py-5 px-3'>
                    <div className='w-full h-full bg-white'>
                        <h4 className='text-black text-center text-4xl my-5 uppercase'>Márgenes de ganancia por producto</h4>
                        <table className='w-full'>
                            <thead>
                                <tr className='p-1 bg-blue-400 text-white'>
                                    <th className='px-2'>Código</th>
                                    <th className='px-2'>Descripción</th>
                                    <th className='px-2'>Total</th>
                                    <th className='px-2'>Porcentaje</th>
                                </tr>
                            </thead>
                            <tbody>
                                {typeof productos.margenes !== 'undefined' && (
                                    productos.margenes.map((item, index) => (
                                        <tr key={index} className={`border border-gray-300 p-2 hover:cursor-pointer ${item.stockmin > item.stock ? 'bg-red-100' : index % 2 === 0 ? 'bg-white hover:bg-slate-200' : 'bg-slate-100 hover:bg-slate-200'}`}>
                                            <td className='text-center'>{item.codigo}</td>
                                            <td className='text-center'>{item.descripcion}</td>
                                            <td className='text-center'>$ {parseFloat(item.total).toFixed(0)}</td>
                                            <td className='text-center'>{item.porcentaje} %</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FViewReporting