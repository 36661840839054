import React, { useContext } from 'react'
import axios from 'axios'
import ContextStates from '../../context/ContextStates'
import { Url } from '../Url'

const FDeletePunto = () => {
   
    const { puntoSelected, setViewOpen, modify, setModify } = useContext(ContextStates);
    
    const eliminarPunto = async () => {
        const formData=new FormData()
        formData.append('id',puntoSelected.id);
        try{
            const response = await axios({
                url: Url+'api/delPuntos.php',
                method: 'POST',
                data: formData,
            })
            
            let aux = modify + 1;
            setModify(aux);
            setViewOpen(false)
        } catch (e) {
            console.log(e)
        }
    }
 
    return (
    <div className='mt-10 mb-10 px-10'>
        <h3 className='text-2xl text-red-600 uppercase'>Eliminar Punto de Venta</h3>
        <p className='text-xl mt-4 text-gray-600'>Nombre: {puntoSelected.nombre}</p>
        <p className='text-xl text-gray-600'>Descripción: {puntoSelected.descripcion!=='' ? puntoSelected.descripcion : 'Sin Descripción'}</p>
        
        <p className='text-2xl mt-10 text-center text-red-600'>¿Confirma que desea eliminar el Punto de Venta?</p>
        <div className="w-full rounded-lg mt-3 px-3 flex justify-center">
            <button
                type="button"
                className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-red-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                onClick={() => eliminarPunto()}
            >
                Eliminar
            </button>
            <button
                type="button"
                className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                onClick={() => setViewOpen(false)}
            >
                Salir
            </button>
        </div>
    </div>
    )
}

export default FDeletePunto