import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'


const FormEdit = () => {
    const [cantidad, setCantidad] = useState(0)
    const [precioUnitario, setPrecioUnitario] = useState(0)
    const { productoSelected, venta, setVenta, setViewOpen, precio } = useContext(ContextStates)

    useEffect(() => {
        setCantidad(productoSelected.cantidad)
        if (!precio)
            setPrecioUnitario(productoSelected.precio)
        else
            setPrecioUnitario(productoSelected.preciopormayor)
    }, [])

    const handleSet = () => {
        let rData = [...venta]
        //console.log(cantidad)
        rData.forEach(element => {
            if (productoSelected.id === element.id && cantidad !== 0 && cantidad!=='') {
                element.cantidad = cantidad;
                if (!precio)
                    element.precio = precioUnitario;
                else
                    element.preciopormayor = precioUnitario;
            }
        });
        setVenta(rData)
        setViewOpen(false)
    }

    return (
        <div className='p-10 w-10/12 mx-auto'>
            <h3 className="text-center text-2xl font-semibold uppercase">{productoSelected.descripcion}</h3>
            <div className='w-full mx-auto mt-10 mb-10 flex'>
                <label className='mt-2 text-xl' htmlFor='cantidad'>Cantidad</label>
                <input
                    type='text'
                    className='ml-3 p-2 rounded-md bg-blue-50 border border-gray-300'
                    value={cantidad}
                    id='cantidad'
                    onChange={(e) => setCantidad(e.target.value)}
                    autoFocus
                />
                <label className='mt-2 ml-5 text-xl' htmlFor='cantidad'>Precio unitario</label>
                <input
                    type='text'
                    className='ml-3 p-2 rounded-md bg-blue-50 border border-gray-300'
                    value={precioUnitario}
                    id='precio'
                    onChange={(e) => setPrecioUnitario(e.target.value)}
                    autoFocus
                />
            </div>

            <button
                type='button'
                className='mt-5 w-full bg-red-500 text-xl uppercase text-white p-4 rounded-xl'
                onClick={() => handleSet()}
            >Modificar</button>
        </div>
    )
}

export default FormEdit