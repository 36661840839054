import { useState, useEffect, useRef, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'
import { FaPlus } from "react-icons/fa";


const FEditPrestamo = () => {
    const [cantidadEnv, setCantidadEnv] = useState("")
    const { prestamoSelected, setViewOpen, modify, setModify } = useContext(ContextStates)


    const setPrestamo = async () => {
        if (cantidadEnv!=='') {
            const formData = new FormData()
            formData.append('id', prestamoSelected.id);
            formData.append('cantidad', cantidadEnv);
    
            try {
                const results = await axios({
                    url: Url + 'api/setPrestamo.php',
                    method: 'POST',
                    data: formData,
                })
                let aux = modify + 1;
                setModify(aux)
                setViewOpen(false)
            } catch (e) {
                console.log(e)
            }    
        }
    }

    return (
        <div className="w-full py-5 px-20 rounded-2xl">
            <div className="transition-all w-full pt-5 sm:p-3 mt-5">
                <h2 className='text-center text-gray-700 text-2xl'>Devolución de Envases</h2>
                <p className='p-1 text-left text-lg text-gray-600'><span className='font-bold'>Cliente: </span>{`${prestamoSelected.apellido}, ${prestamoSelected.nombre}`}</p>
                <p className='p-1 text-left text-lg text-gray-600'><span className='font-bold'>Domicilio: </span>{prestamoSelected.domicilio}</p>
                <p className='p-1 text-left text-lg text-gray-600'><span className='font-bold'>Teléfono: </span>{prestamoSelected.telefono}</p>
                <p className='p-1 text-left text-lg text-gray-600'><span className='font-bold'>Tipo de Envase: </span>{prestamoSelected.envase}</p>
                <p className='p-1 text-left text-lg text-gray-600'><span className='font-bold'>Cantidad: </span>{prestamoSelected.cantidad}</p>
                <div className="sm:items-start">
                    <label htmlFor="cantidad" className="w-full block mt-5 text-gray-600 uppercase">Cantidad a devolver</label>
                    <input
                        type='number'
                        className='p-2 w-3/12 rounded-lg mb-5 border border-gray-300 bg-white'
                        placeholder='Cantidad'
                        value={cantidadEnv}
                        onChange={(e) => setCantidadEnv(e.target.value)}
                    />
                </div>
            </div>
            <div className="pb-10">
                <div className="w-full rounded-lg px-3">
                    <button
                        type="button"
                        className={`w-full shadow-md p-3 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5 ${cantidadEnv!=='' ? 'bg-orange-600':'bg-orange-200'}`}
                        onClick={()=>setPrestamo()}
                    >
                        Guardar Datos
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FEditPrestamo