import { useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'


const FDelProducto = () => {
    const { productoSelected, modify, setModify, setViewOpen } = useContext(ContextStates)
    

    const handleDelete = async() => {
        const formData=new FormData()
        formData.append('id',productoSelected.id);
        try{
            const results = await axios({
                url: Url+'api/delProducto.php',
                method: 'POST',
                data: formData,
            })
            let aux = modify+1;
            setModify(aux)
            setViewOpen(false)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="w-5/6 mx-auto py-5 px-20 rounded-2xl bg-slate-50">
            <h2 className='text-center text-gray-700 text-xl'>Eliminar Producto</h2>
                <div className="p-5">
                    <p>Código: {productoSelected.codigo}</p>
                    <p>Marca: {productoSelected.marca}</p>
                    <p>Producto: {productoSelected.descripcion}</p>
                </div>
            <div className="pb-10">
                <div className="w-full rounded-lg px-3">
                    <button
                        type="button"
                        className="w-full shadow-md p-3 bg-red-500 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5"
                        onClick={()=>handleDelete()}
                    >Eliminar</button>
                </div>
            </div>
        </div>
)
}

export default FDelProducto