import React, { useEffect, useContext } from 'react'
import axios from 'axios';
import ContextStates from '../../context/ContextStates';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import { MdAttachMoney } from "react-icons/md";
import { Url } from '../Url';


const ListadoClientes = () => {
    const {
        clientes, setClientes,
        buscarCliente, roles, modify, setFormView, setViewOpen, setClienteSelected } = useContext(ContextStates);

    useEffect(() => {
        const consultaClientes = async () => {
            const formData = new FormData()
            //formData.append('idusuario', idUsuario)
            formData.append('busqueda', buscarCliente)
            try {
                const response = await axios({
                    url: Url + 'api/listClientes.php',
                    method: 'POST',
                    data: formData,
                })
                setClientes(response.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaClientes()
    }, [modify, buscarCliente])
    
    const asignarCliente = (id) => {
        clientes.forEach(element => {
            if (element.id===id)
            {
                setClienteSelected(element)
            }
        });
    }

    const pagoCliente = async (id) => {
        asignarCliente(id);
        setFormView(34)
        setViewOpen(true);
    }

    const verCliente = async (id) => {
        asignarCliente(id);
        setFormView(33)
        setViewOpen(true);
    }

    const editarCliente = async (id) => {
        asignarCliente(id);
        setFormView(31)
        setViewOpen(true);
    }

    const eliminarCliente = async (id) => {
        asignarCliente(id);
        setFormView(32)
        setViewOpen(true);
    }

    return (
        <div>
            <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                <thead>
                    <tr className='uppercase bg-blue-400 text-white'>
                        <th className='border-gray-400 p-2'>Apellido</th>
                        <th className='border-gray-400 p-2'>Nombre</th>
                        <th className='border-gray-400 p-2'>Documento</th>
                        <th className='border-gray-400 p-2'>CUIT</th>
                        <th className='border-gray-400 p-2'>Domicilio</th>
                        <th className='border-gray-400 p-2'>Tel.</th>
                        <th className='border-gray-400 p-2'>Saldo</th>
                        <th className='border-gray-400 p-2'>Acciones</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        typeof clientes !== 'undefined' ? (
                            clientes.map((item, index) => (

                                <tr
                                    key={item.id}
                                    className={`hover:bg-slate-200 border-b border-gray-200 ${index % 2 === 0 ? 'bg-white' : 'bg-slate-100'}`}
                                >
                                    <td className='border-gray-400 p-1 text-center'>{item.apellido}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.nombre}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.documento}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.cuit}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.domicilio}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.telefono}</td>
                                    <td className='border-gray-400 p-1 text-center'>${ item.saldo ? item.saldo : 0 }</td>
                                    <td className='p-1 flex justify-center'>
                                    {(roles.includes("1")) && (
                                            <button
                                                className='bg-orange-500 p-3 rounded-md text-white'
                                                onClick={() => pagoCliente(item.id)}
                                            >
                                                <MdAttachMoney />
                                            </button>
                                        )}

                                        <button
                                            className='bg-indigo-500 p-3 rounded-md text-white ml-3'
                                            onClick={() => verCliente(item.id)}
                                        >
                                            <FaEye />
                                        </button>
                                        {(roles.includes("1")) && (
                                            <>
                                                <button
                                                    className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                                    onClick={() => editarCliente(item.id)}
                                                >
                                                    <FaPen />
                                                </button>
                                                <button
                                                    className='bg-red-500 p-3 rounded-md text-white ml-3'
                                                    onClick={() => eliminarCliente(item.id)}
                                                >
                                                    <FaTrash />
                                                </button>
                                            </>
                                        )}
                                    </td>
                                </tr>

                            ))
                        ) : (null)
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ListadoClientes