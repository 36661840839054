import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from 'axios'
import { Url } from '../Url'


const FEditProducto = () => {
    const [marcas, setMarcas] = useState([])
    const [marca, setMarca] = useState(0)
    const [rubros, setRubros] = useState([])
    const [rubro, setRubro] = useState(0)
    const [unidades, setUnidades] = useState([])
    const [unidad, setUnidad] = useState(0)
    const [guardar, setGuardar] = useState(false)
    const [modStock, setModStock] = useState(false)
    const [stock, setStock] = useState(0)
    const [stockMin, setStockMin] = useState(0)
    const [stockAdd, setStockAdd] = useState(0)
    const { productoSelected, setProductoSelected, modify, setModify, setViewOpen } = useContext(ContextStates)

    useEffect(() => {
        //console.log("Marca ",productoSelected.idmarca)
        setMarca(productoSelected.idmarca)
        setRubro(productoSelected.idrubro)
        setUnidad(productoSelected.unidadmedida)
        setStock(productoSelected.stock)
        setStockMin(productoSelected.stockmin)
    }, [])

    useEffect(() => {
        if (stock !== '' && stock !== 0 && stockAdd !== '' && stockAdd !== 0) {
            let aux = parseFloat(productoSelected.stock) + parseFloat(stockAdd);
            setStock(aux)

        }
    }, [stockAdd])

    useEffect(() => {
        const consultaMarcas = async () => {
            const url = Url + 'api/listadomarcas.php';
            const resultado = await axios.get(url);
            console.log(resultado);
            setMarcas(resultado.data.results);
        }
        consultaMarcas()
    }, [])

    useEffect(() => {
        const consultaRubros = async () => {
            const url = Url + 'api/listRubros.php';
            const resultado = await axios.get(url);
            setRubros(resultado.data.results);
        }
        consultaRubros()
    }, [])

    useEffect(() => {
        const consultaUnidades = async () => {
            const url = Url + 'api/listunidades.php';
            const resultado = await axios.get(url);
            setUnidades(resultado.data.results);
        }
        consultaUnidades()
    }, [])


    const formik = useFormik({
        initialValues: {
            codigo: productoSelected.codigo,
            descripcion: productoSelected.descripcion,
            descripcionExt: productoSelected.descripcionext,
            precio: productoSelected.precio,
            precioCosto: productoSelected.preciocosto,
            precioPromo: productoSelected.preciopromocion,
            precioMayor: productoSelected.preciopormayor,
            fechaVto: productoSelected.fechavto
        },
        validationSchema: Yup.object({
            codigo: Yup.string()
                .required("El código es necesario"),
            descripcion: Yup.string()
                .required("La descripcion es necesaria"),
            descripcionExt: Yup.string(),
            precio: Yup.string()
                .required("El precio es necesario"),
            precioCosto: Yup.string(),
            precioPromo: Yup.string(),
            precioMayor: Yup.string(),
        }),
        onSubmit: async (valores, { resetForm }) => {
            if (guardar) {
                const formData = new FormData()
                formData.append('id', productoSelected.id);
                formData.append('codigo', valores.codigo);
                formData.append('descripcion', valores.descripcion);
                formData.append('descripcionext', valores.descripcionExt);
                formData.append('idmarca', marca);
                formData.append('idrubro', rubro);
                formData.append('unidad', unidad);
                formData.append('precio', valores.precio);
                formData.append('preciocosto', valores.precioCosto);
                formData.append('preciopromo', valores.precioPromo);
                formData.append('preciomayor', valores.precioMayor);
                formData.append('fechavto', valores.fechaVto);
                try {
                    const results = await axios({
                        url: Url + 'api/setProductos.php',
                        method: 'POST',
                        data: formData,
                    })
                    //console.log(results)
                    let aux = modify + 1;
                    setModify(aux)
                    //setValProducto(results.data.results.id);
                    //resetForm()
                    setViewOpen(false)
                } catch (e) {
                    console.log(e)
                }

            }
        }
    });

    useEffect(() => {
        const checkForm = () => {
            let error = 0;
            if (formik.values.descripcion === '')
                error = 1;
            if (formik.values.precio === '')
                error = 1;
            if (formik.values.codigo === '')
                error = 1;
            if (formik.values.stock === '')
                error = 1;
            if (rubro === 0)
                error = 1;
            if (unidad === 0)
                error = 1;

            if (error === 0)
                setGuardar(true)
            else
                setGuardar(false)
        }
        checkForm()
    }, [formik.values.descripcion, formik.values.codigo, formik.values.precio, formik.values.stock, rubro, unidad])

    const actualizarStock = async () => {
        const formData = new FormData()
        formData.append('id', productoSelected.id);
        formData.append('stock', stock);
        formData.append('stockmin', stockMin);
        try {
            const results = await axios({
                url: Url + 'api/setStock.php',
                method: 'POST',
                data: formData,
            })
            let aux = modify + 1;
            let productoAux = productoSelected;
            productoAux.stock=stock;
            setProductoSelected(productoAux)
            setModify(aux)
            setModStock(false)
        } catch (e) {
            console.log(e)
        }
    }


    return (

        <div className="transition-all w-full pt-5 sm:p-3 mt-5">
            <h2 className='text-center text-gray-700 text-xl'>Alta de Productos</h2>
            <form
                className="w-full mt-5 py-2 px-3 rounded-2xl"
                onSubmit={formik.handleSubmit}
            >
                <div className='flex'>
                    <div className='w-1/2 mx-auto m-3 p-2'>
                        <div className='p-3 bg-white border border-gray-200 rounded-lg shadow-xl'>
                            <h4 className='text-center text-gray-700 uppercase'>Producto, rubro y marca</h4>
                            <div className="sm:items-start">
                                <label htmlFor="codigo" className="block mt-5 text-gray-600 uppercase">Código</label>
                                <input
                                    className="py-2 px-3 w-full border border-gray-300 rounded"
                                    id="codigo"
                                    placeholder="Código de producto"
                                    type="text"
                                    value={formik.values.codigo}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    autoFocus
                                />
                                {formik.touched.codigo && formik.errors.codigo ? (
                                    <div className='w-full block text-red-500'>
                                        <p>{formik.errors.codigo}</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className="sm:items-start">
                                <label htmlFor="descripcion" className="block mt-5 text-gray-600 uppercase">Descripción</label>
                                <input
                                    className="py-2 px-3 w-full border border-gray-300 rounded"
                                    id="descripcion"
                                    placeholder="Descripción"
                                    type="text"
                                    value={formik.values.descripcion}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.descripcion && formik.errors.descripcion ? (
                                    <div className='w-full block text-red-500'>
                                        <p>{formik.errors.descripcion}</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className="sm:items-start mt-5">
                                <label htmlFor="descripcionExt" className="block text-gray-600 uppercase">Descripción detallada</label>
                                <input
                                    className="py-2 px-3 w-full border border-gray-300 rounded"
                                    id="descripcionExt"
                                    placeholder="Ingrese una descripción detallada"
                                    type="text"
                                    value={formik.values.descripcionExt}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.descripcionExt && formik.errors.descripcionExt ? (
                                    <div className='w-full block text-red-500'>
                                        <p>{formik.errors.descripcionExt}</p>
                                    </div>
                                ) : null}
                            </div>
                            <label htmlFor="sRubro" className="w-full block mt-5 text-gray-600 uppercase">Rubro</label>
                            <select
                                className='py-2 px-3 w-full rounded bg-slate-100'
                                id='sRubro'
                                onChange={(e) => setRubro(e.target.value)}
                                value={rubro}
                            >
                                {rubros && (
                                    rubros.map(item => (
                                        <option key={item.id} value={item.id}>{item.descripcion}</option>))
                                )}
                            </select>

                            <label htmlFor="sMarca" className="w-full block mt-5 text-gray-600 uppercase">Marca</label>
                            <select
                                className='py-2 px-3 w-full rounded bg-slate-100'
                                id='sMarca'
                                onChange={(e) => setMarca(e.target.value)}
                                value={marca}
                            >
                                {marcas && (
                                    marcas.map(item => (
                                        <option key={item.id} value={item.id}>{item.descripcion}</option>))
                                )}
                            </select>

                        </div>
                    </div>
                    <div className='w-1/2 mx-auto m-3 p-2'>
                        <div className='p-3 bg-white border border-gray-200 rounded-lg shadow-xl'>
                            <h4 className='text-center text-gray-700 uppercase'>Precio, unidad y Stock</h4>
                            <div className="sm:items-start">

                                <label htmlFor="precio" className="block mt-5 text-gray-600 uppercase">Precio de venta</label>
                                <input
                                    className="py-2 px-3 w-full border border-gray-300 rounded"
                                    id="precio"
                                    placeholder="Precio de venta"
                                    type="text"
                                    value={formik.values.precio}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.precio && formik.errors.precio ? (
                                    <div className='w-full block text-red-500'>
                                        <p>{formik.errors.precio}</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className="sm:items-start">

                                <label htmlFor="precioCosto" className="block mt-5 text-gray-600 uppercase">Precio de costo</label>
                                <input
                                    className="py-2 px-3 w-full border border-gray-300 rounded"
                                    id="precioCosto"
                                    placeholder="Precio de costo"
                                    type="text"
                                    value={formik.values.precioCosto}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.precioCosto && formik.errors.precioCosto ? (
                                    <div className='w-full block text-red-500'>
                                        <p>{formik.errors.precioCosto}</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className="sm:items-start">
                                <label htmlFor="precioPromo" className="block mt-5 text-gray-600 uppercase">Precio de Promoción</label>
                                <input
                                    className="py-2 px-3 w-full border border-gray-300 rounded"
                                    id="precioPromo"
                                    placeholder="Precio de Promoción"
                                    type="text"
                                    value={formik.values.precioPromo}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.precioPromo && formik.errors.precioPromo ? (
                                    <div className='w-full block text-red-500'>
                                        <p>{formik.errors.precioPromo}</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className="sm:items-start">

                                <label htmlFor="precioMayor" className="block mt-5 text-gray-600 uppercase">Precio por mayor</label>
                                <input
                                    className="py-2 px-3 w-full border border-gray-300 rounded"
                                    id="precioMayor"
                                    placeholder="Precio por mayor"
                                    type="text"
                                    value={formik.values.precioMayor}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.precioMayor && formik.errors.precioMayor ? (
                                    <div className='w-full block text-red-500'>
                                        <p>{formik.errors.precioMayor}</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className="sm:items-start">
                                <label htmlFor="fechaVto" className="block mt-5 text-gray-600 uppercase">Fecha de Vencimiento</label>
                                <input
                                    className="py-2 px-3 w-full border border-gray-300 rounded"
                                    id="fechaVto"
                                    placeholder="Fecha de Vencimiento"
                                    type="date"
                                    value={formik.values.fechaVto}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <label htmlFor="sUnidad" className="w-full block mt-5 text-gray-600 uppercase">Unidad de medida</label>
                            <select
                                className='py-2 px-3 w-full rounded bg-slate-100'
                                id='sUnidad'
                                onChange={(e) => setUnidad(e.target.value)}
                                value={unidad}
                            >
                                {unidades && (
                                    unidades.map(item => (
                                        <option key={item.id} value={item.id}>{item.descripcion}</option>))
                                )}
                            </select>
                        </div>
                        <div className='p-3 bg-white border border-gray-200 rounded-lg shadow-xl'>
                            <p className='text-lg font-bold'>Stock Actual: {productoSelected.stock}</p>
                            {modStock ? (
                                <div>
                                    <div className="sm:items-start mt-5">
                                        <label htmlFor="stock" className="block mt-5 text-gray-600 uppercase">Nuevo Stock</label>
                                        <input
                                            className="py-2 px-3 w-full border border-gray-300 rounded"
                                            id="stock"
                                            placeholder="Nuevo Stock"
                                            type="text"
                                            value={stock}
                                            onChange={(e) => setStock(e.target.value)}
                                        />
                                        <label htmlFor="stockAdd" className="block mt-5 text-gray-600 uppercase">Stock a agregar</label>
                                        <input
                                            className="py-2 px-3 w-full border border-gray-300 rounded"
                                            id="stockAdd"
                                            placeholder="Ingrese la cantidad a agregar"
                                            type="text"
                                            value={stockAdd}
                                            onChange={(e) => setStockAdd(e.target.value)}
                                        />
                                    </div>
                                    <div className="sm:items-start">

                                        <label htmlFor="stockMin" className="block mt-5 text-gray-600 uppercase">Stock mínimo</label>
                                        <input
                                            className="py-2 px-3 w-full border border-gray-300 rounded"
                                            id="stockMin"
                                            placeholder="Stock mínimo"
                                            type="text"
                                            value={stockMin}
                                            onChange={(e) => setStockMin(e.target.value)}
                                        />
                                    </div>
                                    <div className='w-full pt-5'>
                                        <button
                                            type='button'
                                            className='p-2 bg-red-500 text-white rounded'
                                            onClick={() => actualizarStock()}
                                        >Modificar</button>
                                        <button
                                            type='button'
                                            className='p-2 bg-gray-500 text-white rounded ml-5'
                                            onClick={() => setModStock(false)}
                                        >Cancelar</button>
                                    </div>
                                </div>

                            ) : (
                                <div className='mt-5'>

                                    <button
                                        type='button'
                                        className='p-2 bg-blue-500 text-white rounded'
                                        onClick={() => setModStock(true)}
                                    >Modificar Stock</button>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
                <div className="mt-auto">
                    <div className="w-full rounded-lg px-3">
                        <input
                            type="submit"
                            className={`"w-full shadow-md p-3 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5" ${guardar ? 'bg-orange-600' : 'bg-orange-300'}`}
                            value="Guardar Datos"
                        />
                    </div>
                </div>

            </form>
        </div>
    )
}

export default FEditProducto