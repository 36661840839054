import React, { useEffect, useContext } from 'react'
import axios from 'axios';
import ContextStates from '../../context/ContextStates';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import { Url } from '../Url';


const ListadoUsuarios = () => {
    const {
        usuarios,
        buscarUsuario,
        setUsuarios,
        idUsuario, roles, modify, setFormView,
        setViewOpen, setUsuarioSelected } = useContext(ContextStates);

    useEffect(() => {
        const consultaUsuarios = async () => {
            const formData = new FormData()
            formData.append('idusuario', idUsuario)
            formData.append('busqueda', buscarUsuario)
            try {
                const response = await axios({
                    url: Url + 'api/listUsuarios.php',
                    method: 'POST',
                    data: formData,
                })
                //console.log("usuarios ", response.data.results)
                setUsuarios(response.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaUsuarios()
    }, [modify, buscarUsuario])
    
    const asignarUsuario = (id) => {
        usuarios.forEach(element => {
            if (element.id===id)
            {
                setUsuarioSelected(element)
            }
        });
    }

    const verUsuario = async (id) => {
        asignarUsuario(id);
        setFormView(20)
        setViewOpen(true);
    }

    const editarUsuario = async (id) => {
        asignarUsuario(id);
        setFormView(21)
        setViewOpen(true);
    }

    const eliminarUsuario = async (id) => {
        asignarUsuario(id);
        setFormView(22)
        setViewOpen(true);
    }

    return (
        <div>
            <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                <thead>
                    <tr className='uppercase bg-blue-400 text-white'>
                        <th className='border-gray-400 p-2'>Apellido</th>
                        <th className='border-gray-400 p-2'>Nombre</th>
                        <th className='border-gray-400 p-2'>Mail</th>
                        <th className='border-gray-400 p-2'>Usuario</th>
                        <th className='border-gray-400 p-2'>Habilitado</th>
                        <th className='border-gray-400 p-2'>Acciones</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        typeof usuarios !== 'undefined' ? (
                            usuarios.map((item, index) => (

                                <tr
                                    key={item.id}
                                    className={`hover:bg-slate-200 border-b border-gray-200 ${index % 2 === 0 ? 'bg-white' : 'bg-slate-100'}`}
                                >
                                    <td className='border-gray-400 p-1 text-center'>{item.apellido}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.nombre}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.mail}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.nombreusuario}</td>
                                    <td className='border-gray-400 p-1 text-center'>{`${item.habilitado === '1' ? "Si" : "No"}`}</td>
                                    <td className='p-1 flex justify-center'>

                                        <button
                                            className='bg-indigo-500 p-3 rounded-md text-white'
                                            onClick={() => verUsuario(item.id)}
                                        >
                                            <FaEye />
                                        </button>
                                        {(roles.includes("1")) && (
                                            <>
                                                <button
                                                    className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                                    onClick={() => editarUsuario(item.id)}
                                                >
                                                    <FaPen />
                                                </button>
                                                <button
                                                    className='bg-red-400 p-3 rounded-md text-white ml-3'
                                                    onClick={() => eliminarUsuario(item.id)}
                                                >
                                                    <FaTrash />
                                                </button>
                                            </>
                                        )}
                                    </td>
                                </tr>

                            ))
                        ) : (null)
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ListadoUsuarios