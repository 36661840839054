import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import { FaPen, FaTrash } from "react-icons/fa";
import axios from 'axios'
import { Url } from '../Url'


const ListadoRubros = () => {
    
    const { setFormView, setViewOpen, rubros, setRubros, rubroSearch, setRubroSelected, modify } = useContext(ContextStates)

    useEffect(() => {
        const consultaRubros = async () => {
            const formData = new FormData()
            formData.append("rubro", rubroSearch)
            try {
                const response = await axios({
                    url: Url + 'api/listRubros.php',
                    method: 'POST',
                    data: formData,
                })
                setRubros(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        consultaRubros()
    }, [rubroSearch, modify])

    const handleView = (item, action) => {
        setRubroSelected(item)
        setFormView(action)
        setViewOpen(true)
    }


    return (
        <div className="w-full py-5 rounded-2xl">
            <div className="sm:items-start">
                <table className='w-full'>
                    <thead>
                        <tr className='p-1 bg-blue-400 text-white'>
                            <th>Rubro</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rubros && (
                            rubros.map((item, index) => (
                                <tr key={index} className={`border border-gray-300 p-2`}>
                                    <td className='text-center'>{item.descripcion}</td>
                                    <td className='text-center'>
                                        <button className='bg-gray-400 ml-2 hover:bg-gray-300 rounded p-2 text-white' onClick={() => handleView(item, 12)}><FaPen /></button>
                                        <button className='bg-red-500 ml-2 hover:bg-red-400 rounded p-2 text-white' onClick={() => handleView(item, 13)}><FaTrash /></button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ListadoRubros