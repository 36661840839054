import React, { useContext, useEffect, useState } from 'react';
import ContextStates from '../context/ContextStates';


const FooterVenta = () => {
    const [ total, setTotal ] = useState(0)
    const { venta, setFormView, setViewOpen, precio } = useContext(ContextStates);

    useEffect(() => {
        const calcTotal = () => {
            let aux = 0;
            if (typeof venta!=='undefined') {
                venta.forEach(element => {
                    if (!precio) {
                        if (element.tipopromo==='1' || element.tipopromo==='2') {
                            aux=aux+parseFloat(element.preciopromocion * element.cantidad);
                        }
                        else {
                            if (element.tipopromo==='3') {
                                let precio = 0;
                                if (element.cantidad>=element.cantidadminima) {
                                    let cantPromos = parseFloat(element.cantidad)/parseFloat(element.cantidadminima);
                                    let cantPagos = element.cantidad-(parseFloat(element.cantidaddescuento)*cantPromos);
                                    precio = cantPagos * parseFloat(element.precio);
                                } else {
                                    precio = parseFloat(element.cantidad) * parseFloat(element.precio);
                                }
                                aux=aux+precio;
                            } else {
                                aux=aux+parseFloat(element.precio * element.cantidad);
                            }
                        }
                    }
                    else {
                        aux=aux+parseFloat(element.preciopormayor * element.cantidad);
                    }
                });
                setTotal(aux)    
            }
        }
        calcTotal()
    },[venta, precio])

    const finalizarVenta = () => {
        if (total>0)
        {
            setFormView(2);
            setViewOpen(true);
        }
    }
    
	return (
		<div className="w-full mt-auto h-20 mx-auto mb-20 bg-gray-200">
            <div className='w-10/12 mx-auto flex'>
                <div className='w-2/3'>
                    <div className='w-1/2 ml-auto p-5'>
                        <p className='text-center text-4xl font-light'>Total: ${total.toLocaleString("es-AR", { maximumFractionDigits: 2 })}</p>
                        
                    </div>
                </div>
                <div className='w-1/3'>
                    <div className='w-1/2 p-4 ml-auto'>
                        <button type='button'
                            className={`w-full p-3 text-white uppercase font-semibold rounded ${total>0 ? 'bg-orange-500':'bg-orange-200'}`}
                            onClick={()=>finalizarVenta()}
                        >
                            Finalizar venta
                        </button>
                    </div>
                </div>
            </div>
        </div>
	)
}

export default FooterVenta