import React, { useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai'

const FormBusquedaRubro = () => {
    const { rubroSearch, setRubroSearch } = useContext(ContextStates)


    return (
        <div className='py-3 rounded-3xl bg-white w-2/3 border border-gray-300 mx-auto px-3 flex'>
            <input 
                type='text'
                className='w-11/12 outline-none'
                value={rubroSearch}
                placeholder='Buscar rubro'
                onChange={(e) => setRubroSearch(e.target.value)}
            />
            <AiOutlineSearch className='ml-auto text-2xl' />
        </div>               
    )
}

export default FormBusquedaRubro