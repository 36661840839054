import { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ContextStates from '../../context/ContextStates';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import { MdAttachMoney } from "react-icons/md";
import { Url } from '../Url';


const ListadoEnvases = () => {
    const [envases, setEnvases] = useState([])
    const { roles, modify, setFormView, setViewOpen, prestamoSelected, setPrestamoSelected } = useContext(ContextStates);

    useEffect(() => {
        const consultaEnvases = async () => {
            const formData = new FormData()
            try {
                const response = await axios({
                    url: Url + 'api/listEnvases.php',
                    method: 'POST',
                    data: formData,
                })
                setEnvases(response.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaEnvases()
    }, [modify])
    
    const handleView = (item, action) => {
        setPrestamoSelected(item)
        setFormView(action)
        setViewOpen(true)
    }

    return (
        <div>
            <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                <thead>
                    <tr className='uppercase bg-blue-400 text-white'>
                        <th className='border-gray-400 p-2'>Apellido</th>
                        <th className='border-gray-400 p-2'>Nombre</th>
                        <th className='border-gray-400 p-2'>Domicilio</th>
                        <th className='border-gray-400 p-2'>Tel.</th>
                        <th className='border-gray-400 p-2'>Envase</th>
                        <th className='border-gray-400 p-2'>Cantidad</th>
                        <th className='border-gray-400 p-2'>Acciones</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        typeof envases !== 'undefined' ? (
                            envases.map((item, index) => (
                                <tr
                                    key={item.id}
                                    className={`hover:bg-slate-200 border-b border-gray-200 ${index % 2 === 0 ? 'bg-white' : 'bg-slate-100'}`}
                                >
                                    <td className='border-gray-400 p-1 text-center'>{item.apellido}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.nombre}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.domicilio}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.telefono}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.envase}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.cantidad}</td>
                                    <td className='p-1 flex justify-center'>
                                        {(roles.includes("1")) && (
                                            <>
                                                <button
                                                    className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                                    onClick={()=>handleView(item, 60)}
                                                >
                                                    <FaPen />
                                                </button>
                                                <button
                                                    className='bg-red-500 p-3 rounded-md text-white ml-3'
                                                    onClick={()=>handleView(item, 61)}
                                                >
                                                    <FaTrash />
                                                </button>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ))
                        ) : (null)
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ListadoEnvases