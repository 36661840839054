import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/admin/Layout';
import ContextStates from '../../context/ContextStates';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai'
import ListadoProveedores from '../../components/admin/listadoProveedores';
import { RiFileExcel2Line } from 'react-icons/ri'

const Proveedores = () => {

    const { setViewOpen, setFormView, roles, idUsuario, setIdUsuario } = useContext(ContextStates);



    const Alta = () => {
        setFormView(80);
        setViewOpen(true);
    }

    return (
        <Layout
            pagina="Proveedores"
        >
            <div className="w-full">
                <h1 className="mt-10 mb-5 text-gray-600 text-3xl text-center">Proveedores</h1>
                <div className='w-full h-screen mt-2 bg-white'>
                    <div className='w-full h-16 py-2 px-10 bg-slate-50 flex'>
                        <button
                            className='bg-indigo-600 hover:bg-indigo-400 p-3 rounded-md text-white uppercase flex'
                            onClick={() => Alta(true)}
                        >
                            <AiOutlinePlus className='text-2xl mr-2' />
                            Agregar Proveedor
                        </button>
                        <div className='py-3 rounded-3xl bg-white w-1/3 border border-gray-300 mx-auto px-3 flex'>
                            <input
                                type="text"
                                className='w-11/12 outline-none'
                                value=""
                                placeholder="Buscar Proveedor"

                            />
                            <AiOutlineSearch className='ml-auto text-2xl' />
                        </div>
                    </div>
                    <div className='w-full h-full p-10 bg-white'>
                        <ListadoProveedores />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Proveedores