import { Fragment, useRef, useState, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ContextStates from '../context/ContextStates'
import FormEdit from './forms/formEdit'
import FinalizarVenta from './finalizarVenta'
import FormLogin from './forms/FormLogin'
import FormMarca from './admin/formMarca'
import FormProducto from './admin/formProducto'
import FormRubro from './admin/formRubro'
import ViewVenta from './admin/viewVenta'
import FEditProducto from './admin/fEditProducto'
import FDelProducto from './admin/fDelProducto'
import FEditMarca from './admin/fEditMarca'
import FDelMarca from './admin/fDelMarca'
import FEditRubro from './admin/fEditRubro'
import FDelRubro from './admin/fDelRubro'
import FEditVenta from './admin/fEditVenta'
import FormUsuario from './admin/formUsuario'
import FEditUsuario from './admin/fEditUsuario'
import FDeleteUsuario from './admin/fDeleteUsuario'
import FormCliente from './admin/formCliente'
import FViewCliente from './admin/fViewCliente'
import FEditCliente from './admin/fEditCliente'
import FDeleteCliente from './admin/fDeleteCliente'
import FPagoCliente from './admin/fPagoCliente'
import FormPunto from './admin/formPunto'
import FEditPunto from './admin/fEditPunto'
import FDeletePunto from './admin/fDeletePunto'
import FormOpenJornada from './formOpenJorna'
import FormCloseJornada from './formCloseJorna'
import FEditPrestamo from './admin/fEditPrestamo'
import FDelPrestamo from './admin/fDelPrestamo'
import FormProductoPromo from './admin/formProductoPromo'
import FormPromociones from './admin/formPromociones'
import FViewProducto from './admin/fViewProducto'
import FEditPromociones from './admin/fEditPromociones'
import FDelPromociones from './admin/fDelPromociones'
import FormProveedor from './admin/formProveedor'
import FEditProveedor from './admin/fEditProveedor'
import FDelProveedor from './admin/fDelProveedor'
import FEditLinCompra from './admin/fEditLinCompra'
import FDeleteLinCompra from './admin/fDeleteLinCompra'
import FViewCompra from './admin/fViewCompra'
import { AiFillCloseCircle } from 'react-icons/ai'

const ModalView = () => {
	const cancelButtonRef = useRef(null)
	const { formView, viewOpen, setViewOpen } = useContext(ContextStates);



	return (
		<>
			<Transition.Root show={viewOpen} as={Fragment}>
				<Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setViewOpen}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 md:w-3/4 w-11/12">
									<div className="px-4 pt-2 sm:p-6">
										<button className='text-3xl' onClick={() => setViewOpen(false)}><AiFillCloseCircle /></button>
										<div className="sm:flex sm:items-start">
											{formView === 1 ? (
												<FormEdit />
											) : formView === 2 ? (
												<FinalizarVenta />
											) : formView === 3 ? (
												<FormLogin />
											) : formView === 4 ? (
												<FormMarca />
											) : formView === 5 ? (
												<FormProducto />
											) : formView === 6 ? (
												<FormRubro />
											) : formView === 7 ? (
												<ViewVenta />
											) : formView === 8 ? (
												<FEditProducto />
											) : formView === 9 ? (
												<FDelProducto />
											) : formView === 10 ? (
												<FEditMarca />
											) : formView === 11 ? (
												<FDelMarca />
											) : formView === 12 ? (
												<FEditRubro />
											) : formView === 13 ? (
												<FDelRubro />
											) : formView === 14 ? (
												<FEditVenta />
											) : formView === 15 ? (
												<FViewProducto />
											) : formView === 20 ? (
												<FormUsuario />
											) : formView === 21 ? (
												<FEditUsuario />
											) : formView === 22 ? (
												<FDeleteUsuario />
											) : formView === 30 ? (
												<FormCliente />
											) : formView === 31 ? (
												<FEditCliente />
											) : formView === 32 ? (
												<FDeleteCliente />
											) : formView === 33 ? (
												<FViewCliente />
											) : formView === 34 ? (
												<FPagoCliente />
											) : formView === 40 ? (
												<FormPunto />
											) : formView === 42 ? (
												<FEditPunto />
											) : formView === 43 ? (
												<FDeletePunto />
											) : formView === 50 ? (
												<FormOpenJornada />
											) : formView === 51 ? (
												<FormCloseJornada />
											) : formView === 60 ? (
												<FEditPrestamo />
											) : formView === 61 ? (
												<FDelPrestamo />
											) : formView === 70 ? (
												<FormProductoPromo />
											) : formView === 75 ? (
												<FormPromociones />
											) : formView === 71 ? (
												<FEditPromociones />
											) : formView === 72 ? (
												<FDelPromociones />
											) : formView === 80 ? (
												<FormProveedor />
											) : formView === 81 ? (
												<FEditProveedor />
											) : formView === 82 ? (
												<FDelProveedor />
											) : formView === 90 ? (
												<FEditLinCompra />
											) : formView === 91 ? (
												<FDeleteLinCompra />
											) : formView === 95 ? (
												<FViewCompra />
											) : null
											}
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	)
}

export default ModalView