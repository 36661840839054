import { useContext } from 'react';
import Layout from '../../components/admin/Layout';
import ContextStates from '../../context/ContextStates';
import ListadoPromociones from '../../components/admin/listadoPromociones';
import { FaPlus } from "react-icons/fa";

const Promociones = () => {
	const { setFormView, setViewOpen } = useContext(ContextStates)

	const handleAdd = () => {
		setFormView(75)
		setViewOpen(true)
	}

	return (
		<Layout
			pagina={"Productos"}
		>
			<div className='w-full p-5 min-h-full'>
			<h1 className="mt-10 mb-5 text-gray-600 text-3xl text-center">Promociones</h1>
                <div className='w-full h-screen mt-2 bg-white'>
                    <div className='w-full h-16 py-2 px-10 bg-slate-50 flex'>
                        <button
                            className='bg-indigo-600 hover:bg-indigo-400 p-3 rounded-md text-white uppercase flex'
                            onClick={() => handleAdd()}
                        >
                            <FaPlus className='text-2xl mr-2' />
                            Agregar Promociones
                        </button>
                    </div>
                    <div className='w-full h-full p-10 bg-white'>
                        <ListadoPromociones />
                    </div>
                </div>
			</div>
		</Layout>
	)
}

export default Promociones