import { useState, useEffect, useContext } from 'react'
import ContextStates from '../context/ContextStates';
import axios from 'axios';
import { Url } from './Url';
import { Link } from 'react-router-dom';


const FormJornadas = () => {
    const [jornada, setJornada] = useState([])
    const [total, setTotal] = useState(0)
    const { estadoJornada, idUsuario, setFormView, setViewOpen, setVentaSelected, setEstadoJornada, setIdJornada } = useContext(ContextStates)

    useEffect(() => {
        const getJornada = async () => {
            const formData = new FormData()
            formData.append('idusuario', idUsuario);
            try {
                const results = await axios({
                    url: Url + 'api/getJornada.php',
                    method: 'POST',
                    data: formData,
                })
                //console.log(results)
                if (typeof results.data.results!=='undefined') {
                    setJornada(results.data.results)
                    if (results.data.results.estado==='2') {
                        localStorage.removeItem("pvp_jnd")
                        setEstadoJornada(0)
                        localStorage.removeItem("pvp_st_jnd")
                        setIdJornada(0)        
                    }                    
                }
                else {
                    localStorage.removeItem("pvp_jnd")
                    setEstadoJornada(0)
                    localStorage.removeItem("pvp_st_jnd")
                    setIdJornada(0)
                }

            } catch (e) {
                console.log(e)
            }
        }
        getJornada()
    }, [idUsuario])

    useEffect(() => {
        const calcTotal = () => {
            let t = 0;
            if (typeof jornada !== 'undefined') {
                if (typeof jornada.ventas !== 'undefined') {
                    jornada.ventas.forEach(element => {
                        t = t + parseInt(element.total)
                    });
                    setTotal(t)
                }
            }
        }
        calcTotal()
    }, [jornada])

    const openJornada = () => {
        setFormView(50)
        setViewOpen(true)
    }

    const closeJornada = () => {
        setFormView(51)
        setViewOpen(true)
    }

    const handleView = (item) => {
        setVentaSelected(item)
        setFormView(7)
        setViewOpen(true)
    }

    return (
        <div className='flex flex-col h-screen justify-between'>
            <div className="transition-all w-full mt-3">
                <h2 className='text-center text-3xl'>Jornada de Caja</h2>
                <div className="w-full rounded-lg p-5 justify-center">
                    {estadoJornada === 0 ? (
                        <div className='w-1/3 sm:w-1/4 mx-auto'>
                            <button
                                type='button'
                                className="w-full flex shadow-md p-3 ml-3 bg-green-500 text-white hover:bg-green-400 transition-all uppercase font-bold rounded-lg"
                                onClick={() => openJornada()}
                            >
                                <p className='w-full text-center'>
                                    Abrir Jornada
                                </p>
                            </button>
                        </div>
                    ) : (
                        <div className='w-full'>
                            {typeof jornada !== 'undefined' && (
                                <div className='w-full mb-10 p-5 bg-slate-50 rounded-xl'>
                                    <p className='text-center text-xl'>Jornada Nº {jornada.numero}</p>
                                    <p className='text-center text-xl'>Hora de Apertura: {jornada.horaapertura}</p>
                                    <p className='text-center text-xl'>Monto inicial de Caja: ${jornada.montoinicial}</p>
                                </div>
                            )}
                            <div className='w-full flex justify-center'>
                                <div className='w-1/3 sm:w-1/4 mx-auto'>
                                    <Link to={"/"}>
                                        <button
                                            type='button'
                                            className="w-full flex shadow-md p-3 ml-3 bg-blue-600 text-white hover:bg-blue-400 transition-all uppercase font-bold rounded-lg"
                                        >
                                            <p className='w-full text-center'>
                                                Regresar a Ventas
                                            </p>
                                        </button>
                                    </Link>
                                </div>
                                <div className='w-1/3 sm:w-1/4 mx-auto'>
                                    <button
                                        type='button'
                                        className="w-full flex shadow-md p-3 ml-3 bg-orange-500 text-white hover:bg-orange-400 transition-all uppercase font-bold rounded-lg"
                                        onClick={() => closeJornada()}
                                    >
                                        <p className='w-full text-center'>
                                            Cerrar Jornada
                                        </p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="w-10/12 mx-auto py-5 px-2">
                    {typeof jornada !== 'undefined' && typeof jornada.ventas !== 'undefined' && (
                        <table className='w-full mx-auto'>
                            <thead>
                                <tr className='p-1 bg-blue-400 text-white text-center'>
                                    <th>Nº</th>
                                    <th>Fecha</th>
                                    <th>Hora</th>
                                    <th>Total</th>
                                    <th>Márgen ganancia</th>
                                    <th>Medio de Pago</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    jornada.ventas.map((item, index) => (
                                        <tr key={index} className={`hover:cursor-pointer hover:bg-slate-200 border-b border-gray-200 text-center ${item.saldo && item.saldo > 0 && item.saldo === item.total ? 'bg-red-300' : item.saldo > 0 && item.saldo < item.total ? 'bg-orange-300' : index % 2 === 0 ? 'bg-white ' : 'bg-slate-100'} )`}>
                                            <td>{item.numerocomprobante}</td>
                                            <td>{item.fecha}</td>
                                            <td>{item.hora}</td>
                                            <td>$ {item.total}</td>
                                            <td>$ {item.margen}</td>
                                            <td>{item.medio}</td>
                                            <td className='text-center'>
                                                <button className='bg-blue-600 hover:bg-blue-400 rounded-lg py-1 px-3 text-white' onClick={() => handleView(item)}>Ver</button>
                                            </td>
                                        </tr>
                                    ))
                                }
                                <tr className='border border-gray-300 p-2 text-center font-bold bg-slate-50'>
                                    <td>Total</td>
                                    <td></td>
                                    <td></td>
                                    <td>${total}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    )
}

export default FormJornadas