import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'


const FDelPromociones = () => {
    const { promoSelected, modify, setModify, setViewOpen } = useContext(ContextStates)


    const delPromocion = async () => {
        const formData = new FormData()
        formData.append('idPromo', promoSelected.id);
        try {
            const results = await axios({
                url: Url + 'api/delPromocion.php',
                method: 'POST',
                data: formData,
            })
            let aux = modify + 1;
            setModify(aux)
            setViewOpen(false)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className='w-full'>
            <div className="transition-all w-full pt-5 sm:p-3 mt-5">
                <h3 className='text-center text-2xl'>¿Seguro desea eliminar la Promoción?</h3>
                <div className="sm:items-start">
                    <div className='w-full p-4'>
                        <p className='text-center text-2xl font-bold'>{promoSelected.descripcion}</p>
                    </div>
                </div>
            </div>
            <div className="mt-auto">
                <div className="w-full rounded-lg px-3">
                    <button
                        type="button"
                        className={`w-full shadow-md p-3 text-white transition-all uppercase font-bold rounded-lg mt-5 bg-red-500 `}
                        onClick={() => delPromocion()}
                    >
                        Eliminar Promoción
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FDelPromociones