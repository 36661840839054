import { useState, useContext } from 'react'
import ContextStates from '../context/ContextStates';
import axios from 'axios';
import { Url } from './Url';
//import { useNavigate } from 'react-router-dom';

const FormOpenJornada = () => {
    const [montoInicial, setMontoInicial] = useState("")
    const { setIdJornada, idUsuario, setEstadoJornada, puntoVenta, setViewOpen } = useContext(ContextStates)
    //const navigate = useNavigate();

    const openJornada = async () => {
        const formData = new FormData()
        formData.append('idusuario', idUsuario);
        formData.append('puntoVenta', puntoVenta);
        formData.append('montoinicial', montoInicial);

        try {
            const results = await axios({
                url: Url + 'api/openJornada.php',
                method: 'POST',
                data: formData,
            })
            //console.log(results)
            localStorage.setItem("pvp_jnd", results.data.results.idjornada)
            setIdJornada(results.data.results.idjornada)
            setEstadoJornada(1)
            localStorage.setItem("pvp_st_jnd", 1)
            setViewOpen(false)
            //navigate("/", { replace: true });
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className='w-full'>
            <div className="transition-all w-full mt-3">
                <h2 className='text-center text-3xl'>Abrir Jornada de Caja</h2>
                <div className="w-full rounded-lg p-5 justify-center">
                    <div className='w-1/3 mx-auto my-10'>
                        <input
                            type='text'
                            className='w-full p-3 rounded-sm border border-gray-300'
                            placeholder='Monto inicial'
                            value={montoInicial}
                            onChange={(e) => setMontoInicial(e.target.value)}
                        />
                    </div>
                    <div className='w-1/3 sm:w-1/4 mx-auto'>
                        <button
                            type='button'
                            className="w-full flex shadow-md p-3 ml-3 bg-green-500 text-white hover:bg-green-400 transition-all uppercase font-bold rounded-lg"
                            onClick={() => openJornada()}
                        >
                            <p className='w-full text-center'>
                                Confirmar apertura de Jornada
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormOpenJornada