import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import ContextStates from '../../context/ContextStates'
import { Url } from '../Url'


const FormRolesUsuario = () => {
    const [rolesUsuario, setRolesUsuario] = useState([])
    const { usuarioSelected, setUsuarioSelected, setViewOpen } = useContext(ContextStates);

    useEffect(() => {
        setRolesUsuario(usuarioSelected.roles)
    },[usuarioSelected])

    const actualizarRol = async (rol, estado) => {
        const formData=new FormData()
        formData.append('idusuario',usuarioSelected.id);
        formData.append('rol',rol);
        formData.append('estado',estado);
        try{
            const response = await axios({
                url: Url+'api/actualizarRol.php',
                method: 'POST',
                data: formData,
            })
        } catch (e) {
            console.log(e)
        }
    }

    const handleChange = (e) => {
        //let auxUsuario=usuarioSelected;
        console.log("viene ",e)
        let rData=[...rolesUsuario];
        rData.forEach(element => {

            if (element.id===e.id)
            {   
                console.log("llega ", element.habilitado)
                if (!element.habilitado)
                {
                    element.habilitado=true;
                    actualizarRol(element.id,1);
                }
                else
                {
                    element.habilitado=false;
                    actualizarRol(element.id,0);
                }
            }
        });
        //auxUsuario.roles=rData;
        setRolesUsuario(rData)
        ///return (setRoles(rData))
    }

    return (
    <div className="w-full mx-auto p-5 rounded-2xl">
        <div className="pb-1">
            <div>
                {
                    typeof rolesUsuario !== 'undefined' && (
                        rolesUsuario.map((item, index) => (
                            <div key={index} className='flex'>
                                <label className='mr-3' ><input type='checkbox' value={item.id} id={item.id}  onChange={(e)=>handleChange(e.target)} checked={item.habilitado} />{item.nombre}</label>
                            </div>
                        ))
                    )
                }
            </div>
            <div className="w-full rounded-lg px-3 flex justify-center">
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setViewOpen(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    </div>               
    )
}

export default FormRolesUsuario