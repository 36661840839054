import { useState, useEffect, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextStates from '../../context/ContextStates'
import { Url } from '../Url'
import { FaPlus, FaTrash } from "react-icons/fa";

const FormProveedor = () => {
    const [rubros, setRubros] = useState([])
    const [rubro, setRubro] = useState(0)
    const [rubrosProveedor, setRubrosProveedor] = useState([])

    const { setViewOpen, modify, setModify, formPrev, setFormPrev, setFormView } = useContext(ContextStates);
    const [errorNombre, setErrorNombre] = useState(false);

    useEffect(() => {
        const consultaRubros = async () => {
            const url = Url + 'api/listRubros.php';
            const resultado = await axios.get(url);
            setRubros(resultado.data.results);
            setRubro(resultado.data.results[0].id)
        }
        consultaRubros()
    }, [])


    const formik = useFormik({
        initialValues: {
            nombre: '',
            contacto: '',
            email: '',
            telefono: ''
        },
        validationSchema: Yup.object({
            nombre: Yup.string()
                .required("El nombre es necesario"),
            contacto: Yup.string(),
            email: Yup.string(),
            telefono: Yup.string()

        }),
        onSubmit: async (valores, { resetForm }) => {
            const formData = new FormData()
            const auxRubros = JSON.stringify(rubrosProveedor)
            if (!errorNombre) {
                formData.append('nombre', valores.nombre);
                formData.append('contacto', valores.contacto);
                formData.append('telefono', valores.telefono);
                formData.append('email', valores.email);
                formData.append('rubros', auxRubros);
                try {
                    const response = await axios({
                        url: Url + 'api/proveedoresAlta.php',
                        method: 'POST',
                        data: formData,
                    })
                    console.log(response)
                    let aux = modify + 1;
                    setModify(aux);
                    if (formPrev !== 0)
                        handleReturn();
                    resetForm({ values: '' });
                } catch (e) {
                    console.log(e)
                }
            }
        }
    });

    useEffect(() => {
        const checkCliente = async () => {
            const formData = new FormData()
            formData.append('nombre', formik.values.nombre);
            try {
                const response = await axios({
                    url: Url + 'api/checkProveedor.php',
                    method: 'POST',
                    data: formData,
                })
                if (response.data.results.id !== 0) {
                    setErrorNombre(true)
                }
                else {
                    setErrorNombre(false)
                }
            } catch (e) {
            }
        }
        checkCliente()
    }, [formik.values.nombre])

    const handleReturn = () => {
        setFormView(formPrev)
        setFormPrev(0)
    }

    const addRubroProveedor = async () => {
        if (rubro !== 0) {
            let rData = [...rubrosProveedor]
            let encontrado = 0;
            rData.forEach(element => {
                if (element.id === rubro) {
                    encontrado = 1;
                }
            });
            if (encontrado === 0) {
                rubros.forEach(element => {
                    if (element.id === rubro) {
                        rData.push(element)
                    }
                });
                setRubrosProveedor(rData)
            }
        }
    }

    const delRubroProveedor = async (id) => {
        let rData = [];
        rubrosProveedor.forEach(element => {
            if (element.id !== id) {
                rData.push(element)
            }
        });
        setRubrosProveedor(rData)
    }

    return (
        <form
            className="w-full mx-auto p-5 rounded-2xl"
            onSubmit={formik.handleSubmit}
        >
            <div className="transition-all w-full sm:p-3">
                <h2 className='text-3xl uppercase mb-3 text-center'>Nuevo Proveedor</h2>
                <div className="sm:items-start sm:w-1/2 w-full">
                    <label htmlFor="nombre" className="block mt-5 text-gray-600 uppercase font-bold">Nombre Proveedor</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-white border border-gray-300"
                        id="nombre"
                        placeholder="Ingrese el nombre del Proveedor"
                        type="text"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.nombre && formik.errors.nombre ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.nombre}</p>
                        </div>
                    ) : null}
                </div>
                <label htmlFor="sRubro" className="block mt-5 text-gray-600 uppercase font-bold">Rubros (Puede agregar un rubro o mas)</label>
                <div className='sm:items-start flex'>
                    <select
                        className='p-3 w-full rounded bg-white border border-gray-300 mr-2'
                        id='sRubro'
                        value={rubro}
                        onChange={(e) => setRubro(e.target.value)}
                    >
                        {rubros && (
                            rubros.map(item => (
                                <option key={item.id} value={item.id}>{item.descripcion}</option>))
                        )}
                    </select>
                    <button
                        type='button'
                        className=' bg-violet-600 p-2 rounded-lg text-white flex'
                        onClick={() => addRubroProveedor()}
                    ><FaPlus className='mr-2 mt-1' />Agregar</button>
                </div>
                {rubrosProveedor.length>0 && (
                    <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                        <thead>
                            <tr className='uppercase bg-blue-400 text-white'>
                                <th className='border-gray-400 p-2'>Rubro</th>
                                <th className='border-gray-400 p-2'>Acciones</th>
                            </tr>
                        </thead>
                        <tbody >
                            {
                                rubrosProveedor.map((item, index) => (
                                    <tr
                                        key={item.id}
                                        className={`hover:bg-slate-200 border-b border-gray-200 ${index % 2 === 0 ? 'bg-white' : 'bg-slate-100'}`}
                                    >
                                        <td className='border-gray-400 p-1 text-center'>{item.descripcion}</td>
                                        <td className='p-1 flex justify-center'>
                                            <button
                                                className='bg-red-500 p-3 rounded-md text-white ml-3'
                                                onClick={() => delRubroProveedor(item.id)}
                                            >
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>

                                ))
                            }
                        </tbody>
                    </table>

                )}
                <div className="sm:items-start mt-5">
                    <label htmlFor="contacto" className="block text-gray-600 uppercase font-bold">Contacto (Opcional)</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-white border border-gray-300"
                        id="contacto"
                        placeholder="Contacto"
                        type="text"
                        value={formik.values.contacto}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="telefono" className="block text-gray-600 uppercase font-bold">Nº de Teléfono</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-white border border-gray-300"
                        id="telefono"
                        placeholder="Ingrese Nº de Teléfono"
                        type="text"
                        value={formik.values.telefono}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="email" className="block text-gray-600 uppercase font-bold">E-mail</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-white border border-gray-300"
                        id="email"
                        placeholder="Ingrese E-mail"
                        type="text"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </div>
            </div>
            <div className="pb-1">
                <div className="w-full rounded-lg px-3 flex justify-center">
                    <input
                        type="submit"
                        className="w-1/4 sm:w-1/6 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                        value="Guardar Datos"
                    />
                    {formPrev === 0 ? (
                        <button
                            type="button"
                            className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                            onClick={() => setViewOpen(false)}
                        >
                            Salir
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-green-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                            onClick={() => handleReturn()}
                        >
                            Regresar
                        </button>
                    )}
                </div>
            </div>
        </form>
    )
}

export default FormProveedor