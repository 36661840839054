import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios';
import { Url } from '../Url';

const FEditVenta = () => {
    const [total, setTotal] = useState(0)
    const [productos, setProductos] = useState([])
    const [medios, setMedios] = useState([])
    const [medioPago, setMedioPago] = useState('1')
    const { ventaSelected, setViewOpen, modify, setModify } = useContext(ContextStates)

    useEffect(() => {
        const cargarArray = () => {
            let i = 0;
            let rData = [];
            ventaSelected.productos.forEach(element => {
                rData.push({
                    "index": i,
                    "idproducto": element.idproducto,
                    "codigo": element.codigo,
                    "descripcion": element.descripcion,
                    "descripcionext": element.descripcionext,
                    "cantidad": element.cantidad,
                    "monto": element.monto,
                    "eliminado": 0
                }
                )
                i++;
            });
            setProductos(rData)
            setMedioPago(ventaSelected.idmedio)
        }
        cargarArray();
    }, [])

    useEffect(() => {
        const getMedios = async () => {
            const formData = new FormData()
            try {
                const results = await axios({
                    url: Url + 'api/listmediospago.php',
                    method: 'POST',
                    data: formData,
                })
                setMedios(results.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        getMedios()
    }, [])

    useEffect(() => {
        const calcTotal = () => {
            let t = 0;
            if (typeof productos !== 'undefined') {
                productos.forEach(element => {
                    t = t + parseInt(element.monto)
                });
                setTotal(t)
            }
        }
        calcTotal()
    }, [productos])

    const handleSetMonto = (e, index) => {
        let rData = [...productos]
        rData.forEach(element => {
            if (element.idproducto === index) {
                element.monto = e
                //setModificado(true)
                return
            }
        });
        setProductos(rData)
    }

    const setVenta = async () => {
        const auxVenta = JSON.stringify(productos)
        const formData = new FormData()
        formData.append('venta', auxVenta);
        formData.append('idcomprobante', ventaSelected.idcomprobante);
        formData.append('mediopago', medioPago);
        try {
            const results = await axios({
                url: Url + 'api/setVenta.php',
                method: 'POST',
                data: formData,
            })
            let aux = modify + 1;
            setModify(aux)
            setViewOpen(false)
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <div className="w-full py-5 px-2 md:px-20 rounded-2xl">
            <h2 className='text-center text-gray-700 text-xl'>Venta Nº {ventaSelected.numerocomprobante}</h2>
            {typeof productos !== 'undefined' && (
                <table className='w-full mx-auto'>
                    <thead>
                        <tr className='p-1 bg-blue-400 text-white text-center'>
                            <th>Código</th>
                            <th>Descripción</th>
                            <th>Cantidad</th>
                            <th>Monto</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            productos.map((item, index) => (
                                <tr key={index} className='border border-gray-300 p-2 text-center'>
                                    <td>{item.codigo}</td>
                                    <td>{item.descripcion}</td>
                                    <td>{item.cantidad}</td>
                                    <td>
                                        <input type='text' className='w-full px-2 text-right border border-gray-400' value={item.monto} onChange={(e) => handleSetMonto(e.target.value, item.idproducto)} />
                                    </td>
                                </tr>
                            ))
                        }
                        <tr className='border border-gray-300 p-2 text-center'>
                            <th colSpan={4}>Total</th>
                            <th>${total}</th>
                        </tr>
                    </tbody>
                </table>
            )}
            <div className='bg-gray-50 rounded-lg p-5'>
                {typeof medios !== 'undefined' && medios.map((item, index) =>
                    <div key={index} className="radio">
                        <label>
                            <input
                                type="radio"
                                name="medioPago"
                                value={item.id}
                                checked={medioPago === item.id}
                                onChange={() => setMedioPago(item.id)}
                                className='mr-5'
                            />
                            {item.descripcion}
                        </label>
                    </div>
                )}
            </div>
            <div className='w-1/3 mx-auto'>
                <button type='button'
                    className='w-full p-3 bg-red-500 text-white uppercase font-semibold rounded'
                    onClick={() => setVenta()}
                >
                    Modificar Venta
                </button>
            </div>
        </div>
    )
}

export default FEditVenta