import { useContext } from 'react'
import axios from 'axios'
import ContextStates from '../../context/ContextStates'

import { Url } from '../Url'

const FDelProveedor = () => {
    const { setViewOpen, modify, setModify, formPrev, setFormPrev, setFormView, proveedorSelected } = useContext(ContextStates);

    const delProveedor = async () => {
        const formData = new FormData()
        formData.append('idproveedor', proveedorSelected.id);
        try {
            const response = await axios({
                url: Url + 'api/delProveedor.php',
                method: 'POST',
                data: formData,
            })
            //console.log(response)
            let aux = modify + 1;
            setModify(aux);
            setViewOpen(false)
        } catch (e) {
        }
    }

    return (
        <div className="w-full mx-auto p-5 rounded-2xl">
            <div className="transition-all w-full sm:p-3">
                <h2 className='text-3xl uppercase mb-3 text-center'>¿Seguro desea eliminar el Proveedor?</h2>
                <div className='flex'>
                    <div className="sm:items-start sm:w-1/2 w-full ml-2">
                        <p className="block mt-5 text-gray-600 uppercase font-bold">Nombre Proveedor {proveedorSelected.nombre}</p>
                    </div>
                </div>
                <h3 className="block mt-5 text-gray-600 uppercase font-bold">Rubros</h3>
                {typeof proveedorSelected.rubros !== 'undefined' && (
                    <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                        <thead>
                            <tr className='uppercase bg-blue-400 text-white'>
                                <th className='border-gray-400 p-2'>Rubro</th>
                            </tr>
                        </thead>
                        <tbody >
                            {
                                proveedorSelected.rubros.map((item, index) => (
                                    <tr
                                        key={item.id}
                                        className={`hover:bg-slate-200 border-b border-gray-200 ${index % 2 === 0 ? 'bg-white' : 'bg-slate-100'}`}
                                    >
                                        <td className='border-gray-400 p-1 text-center'>{item.descripcion}</td>
                                    </tr>

                                ))
                            }
                        </tbody>
                    </table>

                )}
            </div>
            <div className="pb-1">
                <div className="w-full rounded-lg px-3 flex justify-center">
                    <button
                        type="button"
                        className="w-1/4 sm:w-1/6 shadow-md p-3 bg-red-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                        onClick={()=>delProveedor()}
                    >Eliminar
                    </button>
                    <button
                        type="button"
                        className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                        onClick={() => setViewOpen(false)}
                    >
                        Salir
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FDelProveedor