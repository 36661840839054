import Layout from '../../components/admin/Layout';
import FViewReporting from '../../components/admin/fViewReporting';

const Reportes = () => {
	return (
		<Layout
			pagina={"Reportes"}
		>
			<div className='w-full p-5 min-h-full'>
				<FViewReporting/>
			</div>
		</Layout>
	)
}

export default Reportes