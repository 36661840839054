import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'


const FormFacturacion = () => {
    const [cuit, setCuit] = useState('')
    const [cuitP, setCuitP] = useState('')
    const [cert, setCert] = useState('')
    const [certP, setCertP] = useState('')
    const [keyAfip, setKeyAfip] = useState('')
    const [keyPAfip, setKeyPAfip] = useState('')
    const [activo, setActivo] = useState(0)
    const [habilitado, setHabilitado] = useState(true)


    useEffect(() => {
        const getDatos = async () => {
            const formData = new FormData()
            formData.append('consulta', 1);
            try {
                const results = await axios({
                    url: Url + 'api/datosFacturacion.php',
                    method: 'POST',
                    data: formData,
                })
                //console.log(results)
                setCuitP(results.data.results.prueba.cuit)
                setCertP(results.data.results.prueba.cert)
                setKeyPAfip(results.data.results.prueba.key)
                if (results.data.results.prueba.activo === '1')
                    setActivo(1)

                setCuit(results.data.results.produccion.cuit)
                setCert(results.data.results.produccion.cert)
                setKeyAfip(results.data.results.produccion.key)
                if (results.data.results.produccion.activo === '1')
                    setActivo(2)
            } catch (e) {
                console.log(e)
            }
        }
        getDatos()
    }, [])

    

    const handleChange = async (val) => {
        setActivo(val)
        const formData = new FormData()
        formData.append('activo', val);
        formData.append('cuit', cuit);
        formData.append('cuitP', cuitP);
        formData.append('cert', cert);
        formData.append('certP', certP);
        formData.append('keyAfip', keyAfip);
        formData.append('keyPAfip', keyPAfip);
        try {
            const results = await axios({
                url: Url + 'api/modoFact.php',
                method: 'POST',
                data: formData,
            })
            console.log(results)
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <div className="transition-all w-full pt-5 sm:p-3 mt-5">
            <h2 className='text-center text-gray-700 text-4xl mb-4'>Datos de facturación</h2>
            <div className='w-full md:w-2/3 mx-auto border-2xl p-3 bg-slate-100 mb-10 rounded-xl'>
                <h3 className='text-center my-4 text-xl font-bold uppercase'>Datos para pruebas</h3>
                <label htmlFor='cuitp' className='uppercase text-md text-black'>CUIT</label>
                <input type='text' id='cuitp' value={cuitP} placeholder='CUIT' className='mb-3 w-full text-sm p-2 rounded' onChange={(e) => setCuitP(e.target.value)} />
                <label htmlFor='cert' className='uppercase text-md text-black'>Certificado Prueba</label>
                <textarea className='w-full h-40' id='cert' value={certP} onChange={(e) => setCert(e.target.value)}></textarea>
                <label htmlFor='key' className='uppercase text-md text-black'>Key Prueba</label>
                <textarea className='w-full h-40' id='key' value={keyPAfip} onChange={(e) => setKeyAfip(e.target.value)}></textarea>
                {activo === 1 && (
                    <div className='mt-4 w-full p-4'>
                        <p className='w-full p-3 bg-green-300 text-center'>Modo prueba activado, la facturación no se realizará</p>
                    </div>
                )}
                {
                    (cuitP !== '' && certP !== '' && keyPAfip !== '') && (

                        <label>
                            <input
                                type="radio"
                                name="facturacion"
                                value='1'
                                checked={activo === 1}
                                onChange={() => handleChange(1)}
                                className='mr-5'
                            />
                            Modo Prueba
                        </label>
                    )
                }
            </div>
            <div className='w-full md:w-2/3 mx-auto border-2xl p-3 bg-blue-100 mb-10 rounded-xl'>
                <h3 className='text-center my-4 text-xl font-bold uppercase'>Datos para producción</h3>
                <label htmlFor='cuit' className='uppercase text-md text-black'>CUIT</label>
                <input type='text' id='cuit' value={cuit} placeholder='CUIT' className='mb-3 w-full text-sm p-2 rounded' onChange={(e) => setCuit(e.target.value)} />
                <label htmlFor='cert' className='uppercase text-md text-black'>Certificado</label>
                <textarea className='w-full h-40' id='cert' value={cert} onChange={(e) => setCert(e.target.value)}></textarea>
                <label htmlFor='key' className='uppercase text-md text-black'>Key</label>
                <textarea className='w-full h-40' id='key' value={keyAfip} onChange={(e) => setKeyAfip(e.target.value)}></textarea>
                {activo === 2 && (
                    <div className='mt-4 w-full p-4'>
                        <p className='w-full p-3 bg-green-300 text-center'>Modo producción activado, la facturación se realizará con los datos ingresados</p>
                    </div>
                )}
                {
                    (cuit !== '' && cert !== '' && keyAfip !== '') && (
                        <label>
                            <input
                                type="radio"
                                name="facturacion"
                                value="2"
                                checked={activo === 2}
                                onChange={() => handleChange(2)}
                                className='mr-5'
                            />
                            Modo Producción
                        </label>
                    )
                }
            </div>
            <div className='w-full md:w-2/3 mx-auto'>
            <button
                className={`text-white p-3 rounded text-center ${habilitado ? 'bg-blue-500' : 'bg-gray-300'}`}
                onClick={() => handleChange(activo)}
            >Actualizar Datos
            </button>
            </div>
        </div>
    )
}

export default FormFacturacion