import { useState, useEffect, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextStates from '../../context/ContextStates'
import { Url } from '../Url'

const FormPunto = () => {
    const { setViewOpen, modify, setModify, formPrev, setFormPrev, setFormView } = useContext(ContextStates);
    const [errorNombre, setErrorNombre] = useState(false);

    const formik = useFormik({
        initialValues: {
            nombre: '',
            descripcion: ''
        },
        validationSchema: Yup.object({
            nombre: Yup.string()
                .required("El nombre es necesario"),
            descripcion: Yup.string()
        }),
        onSubmit: async (valores, { resetForm }) => {
            const formData = new FormData()
            if (!errorNombre) {
                formData.append('nombre', valores.nombre);
                formData.append('descripcion', valores.descripcion);
                try {
                    const response = await axios({
                        url: Url + 'api/altaPunto.php',
                        method: 'POST',
                        data: formData,
                    })
                    console.log(response)
                    let aux = modify + 1;
                    setModify(aux);
                    if (formPrev !== 0)
                        handleReturn();
                    resetForm({ values: '' });
                } catch (e) {
                    console.log(e)
                }
            }
        }
    });

    useEffect(() => {
        const checkPunto = async () => {
            const formData = new FormData()
            formData.append('nombre', formik.values.nombre);
            try {
                const response = await axios({
                    url: Url + 'api/checkPunto.php',
                    method: 'POST',
                    data: formData,
                })
                if (response.data.results.id !== 0) {
                    if (response.data.results.error === '1') {
                        setErrorNombre(true)
                    }
                    else {
                        setErrorNombre(false)
                    }
                }
            }
            catch (e) {
            }
        }
        checkPunto()
    }, [formik.values.nombre])

    const handleReturn = () => {
        setFormView(formPrev)
        setFormPrev(0)
    }

    return (
        <form
            className="w-full mx-auto p-5 rounded-2xl"
            onSubmit={formik.handleSubmit}
        >
            <div className="transition-all w-full sm:p-3">
                <h2 className='text-3xl uppercase mb-3 text-center'>Nuevo Punto de Venta</h2>
                <div className="sm:items-start sm:w-1/2 w-full">
                    <label htmlFor="nombre" className="block mt-5 text-gray-600 uppercase font-bold">Nombre</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="nombre"
                        placeholder="Ingrese el nombre"
                        type="text"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.nombre && formik.errors.nombre ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.nombre}</p>
                        </div>
                    ) : errorNombre && (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>Ya existe un punto de venta con ese nombre</p>
                        </div>
                    )}
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="descripcion" className="block text-gray-600 uppercase font-bold">Descripción</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="descripcion"
                        placeholder="Descripción"
                        type="text"
                        value={formik.values.descripcion}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </div>
            </div>
            <div className="pb-1">
                <div className="w-full rounded-lg px-3 flex justify-center">
                    <input
                        type="submit"
                        className="w-1/4 sm:w-1/6 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                        value="Guardar Datos"
                    />
                    {formPrev === 0 ? (
                        <button
                            type="button"
                            className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                            onClick={() => setViewOpen(false)}
                        >
                            Salir
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-green-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                            onClick={() => handleReturn()}
                        >
                            Regresar
                        </button>
                    )}
                </div>
            </div>
        </form>
    )
}

export default FormPunto