import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'


const FEditLinCompra = () => {
    const [stock, setStock] = useState(0)
    const [stockPrevio, setStockPrevio] = useState(0)
    const [precioCosto, setPrecioCosto] = useState(0)
    const { modify, setModify, setViewOpen, detalleCompra, setDetalleCompra, lineaCompra } = useContext(ContextStates)

    useEffect(() => {
        setStock(lineaCompra.stock)
        setStockPrevio(lineaCompra.stock)
        setPrecioCosto(lineaCompra.preciocosto)
    }, [])

    const modificarLinea = async () => {
        const formData = new FormData()
        formData.append('id', lineaCompra.id);
        formData.append('preciocosto', precioCosto);
        formData.append('stock', stock);
        formData.append('stockPrevio', stockPrevio);
        try {
            const results = await axios({
                url: Url + 'api/setLineaCompra.php',
                method: 'POST',
                data: formData,
            })
            console.log(results)
            let aux = modify + 1;
            setModify(aux)
            let rData = [...detalleCompra];
            rData.forEach(element => {
                if (element.id===lineaCompra.id) {
                    element.stock = stock;
                    element.preciocosto = precioCosto;
                }
            });
            setDetalleCompra(rData)
            setViewOpen(false)
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <div className="transition-all w-full pt-5 sm:p-3 mt-5 mb-5">
            <h2 className='text-center text-gray-700 text-xl'>{lineaCompra.descripcion}</h2>
            <div className='w-full flex'>
                <div className='w-1/3 mx-auto my-20'>
                    <div className='w-full'>
                        <label htmlFor="precioCosto" className="block mt-5 text-gray-600 uppercase">Precio de Costo</label>
                        <input
                            className="py-2 px-3 w-full border border-gray-300 rounded"
                            id="precioCosto"
                            placeholder="Precio de Costo"
                            type="text"
                            value={precioCosto}
                            onChange={(e) => setPrecioCosto(e.target.value)}
                            autoFocus
                        />
                        <label htmlFor="stock" className="block mt-5 text-gray-600 uppercase">Cantidad</label>
                        <input
                            className="py-2 px-3 w-full border border-gray-300 rounded"
                            id="stock"
                            placeholder="Cantidad"
                            type="text"
                            value={stock}
                            onChange={(e) => setStock(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="pb-1">
                <div className="w-full rounded-lg px-3 flex justify-center">
                    <button
                        type='button'
                        className='w-full p-2 bg-red-500 text-white uppercase font-semibold rounded'
                        onClick={() => modificarLinea()}
                    >
                        Modificar datos
                    </button>
                    <button
                        type="button"
                        className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                        onClick={() => setViewOpen(false)}
                    >
                        Salir
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FEditLinCompra