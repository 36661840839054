import { useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'

const FDelRubro = () => {
    const { rubroSelected, setViewOpen, modify, setModify } = useContext(ContextStates)

    const handleDel = async () => {
        const formData = new FormData()
        formData.append('id', rubroSelected.id);
        try {
            const results = await axios({
                url: Url + 'api/delRubro.php',
                method: 'POST',
                data: formData,
            })
            let aux = modify + 1;
            setModify(aux)
            setViewOpen(false)
        } catch (e) {
            console.log(e)
        }
    }



    return (
        <div
            className="w-full py-5 px-20 rounded-2xl"
        >
            <div className="transition-all w-full pt-5 sm:p-3 mt-5">
                <h2 className='text-center text-gray-700 text-xl'>Eliminar Rubro</h2>
                <div className="sm:items-start">
                    <p>{rubroSelected.descripcion}</p>
                </div>
            </div>
            <div className="pb-10">
                <div className="w-full rounded-lg px-3">
                    <button
                        type="button"
                        className="w-full shadow-md p-3 bg-red-600 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5"
                        onClick={() => handleDel()}
                    >
                        Eliminar
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FDelRubro