import { useState, useEffect, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextStates from '../../context/ContextStates'
import { Url } from '../Url'

const FEditCliente = () => {
    const { clienteSelected, setViewOpen, modify, setModify } = useContext(ContextStates);
    const [errorDocumento, setErrorDocumento] = useState(false);
    const [errorCuit, setErrorCuit] = useState(false);

    const formik = useFormik({
        initialValues: {
            apellido: clienteSelected.apellido,
            nombre: clienteSelected.nombre,
            razonSocial: clienteSelected.razonsocial,
            cuit: clienteSelected.cuit,
            domicilio: clienteSelected.domicilio,
            documento: clienteSelected.documento,
            telefono: clienteSelected.telefono
        },
        validationSchema: Yup.object({
            apellido: Yup.string()
                .required("El apellido es necesario"),
            nombre: Yup.string()
                .required("El nombre es necesario"),
            razonSocial: Yup.string(),
            cuit: Yup.string(),
            domicilio: Yup.string(),
            documento: Yup.string(),
            telefono: Yup.string()

        }),
        onSubmit: async (valores, { resetForm }) => {
            const formData = new FormData()
            if (!errorDocumento && !errorCuit) {
                formData.append('id', clienteSelected.id);
                formData.append('apellido', valores.apellido);
                formData.append('nombre', valores.nombre);
                formData.append('razonsocial', valores.razonSocial);
                formData.append('cuit', valores.cuit);
                formData.append('domicilio', valores.domicilio);
                formData.append('documento', valores.documento);
                formData.append('telefono', valores.telefono);
                try {
                    const response = await axios({
                        url: Url + 'api/clientesSet.php',
                        method: 'POST',
                        data: formData,
                    })
                    let aux = modify + 1;
                    setModify(aux);
                    setViewOpen(false)
                } catch (e) {
                    console.log(e)
                }
            }
        }
    });

    useEffect(() => {
        const checkCliente = async () => {
            const formData = new FormData()
            formData.append('documento', formik.values.documento);
            formData.append('cuit', formik.values.cuit);
            try {
                const response = await axios({
                    url: Url + 'api/checkCliente.php',
                    method: 'POST',
                    data: formData,
                })
                if (response.data.results.id !== 0) {
                    switch (response.data.results.error) {
                        case '1':
                            setErrorDocumento(true)
                            break;
                        case '2':
                            setErrorCuit(true)
                            break;
                        case '3':
                            setErrorDocumento(true)
                            setErrorCuit(true)
                            break;

                        default:
                            setErrorDocumento(false)
                            setErrorCuit(false)
                            break;
                    }
                }
            } catch (e) {
            }
        }
        checkCliente()
    }, [formik.values.documento, formik.values.cuit])

    return (
        <form
            className="w-full mx-auto p-5 rounded-2xl"
            onSubmit={formik.handleSubmit}
        >
            <div className="transition-all w-full sm:p-3">
                <h2 className='text-3xl uppercase mb-3 text-center'>Editar datos de Cliente</h2>
                <div className='flex'>
                    <div className="sm:items-start sm:w-1/2 w-full mr-2">
                        <label htmlFor="apellido" className="block mt-5 text-gray-600 uppercase font-bold">Apellido</label>
                        <input
                            className="py-2 px-3 w-full rounded bg-slate-300"
                            id="apellido"
                            placeholder="Ingrese el apellido"
                            type="text"
                            value={formik.values.apellido}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoFocus
                        />
                        {formik.touched.apellido && formik.errors.apellido ? (
                            <div className='w-full block mb-2 text-red-500'>
                                <p>{formik.errors.apellido}</p>
                            </div>
                        ) : null}
                    </div>
                    <div className="sm:items-start sm:w-1/2 w-full ml-2">
                        <label htmlFor="nombre" className="block mt-5 text-gray-600 uppercase font-bold">Nombre</label>
                        <input
                            className="py-2 px-3 w-full rounded bg-slate-300"
                            id="nombre"
                            placeholder="Ingrese el nombre"
                            type="text"
                            value={formik.values.nombre}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.nombre && formik.errors.nombre ? (
                            <div className='w-full block mb-2 text-red-500'>
                                <p>{formik.errors.nombre}</p>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="razonSocial" className="block text-gray-600 uppercase font-bold">Razón Social</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="razonSocial"
                        placeholder="Ingrese Razón Social"
                        type="text"
                        value={formik.values.razonSocial}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="documento" className="block text-gray-600 uppercase font-bold">Documento</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="documento"
                        placeholder="Ingrese Documento"
                        type="text"
                        value={formik.values.documento}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {errorDocumento && (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>El Nº de Documento ya se encuntra ingresado</p>
                        </div>
                    )}
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="cuit" className="block text-gray-600 uppercase font-bold">CUIT</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="cuit"
                        placeholder="Ingrese CUIT"
                        type="text"
                        value={formik.values.cuit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {errorCuit && (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>El CUIT ya se encuentra ingresado</p>
                        </div>
                    )}
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="domicilio" className="block text-gray-600 uppercase font-bold">Domicilio</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="domicilio"
                        placeholder="Ingrese Domicilio"
                        type="text"
                        value={formik.values.domicilio}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="telefono" className="block text-gray-600 uppercase font-bold">Nº de Teléfono</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="telefono"
                        placeholder="Ingrese Nº de Teléfono"
                        type="text"
                        value={formik.values.telefono}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </div>
            </div>
            <div className="pb-1">
                <div className="w-full rounded-lg px-3 flex justify-center">
                    <input
                        type="submit"
                        className="w-1/4 sm:w-1/6 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                        value="Guardar Datos"
                    />
                    <button
                        type="button"
                        className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                        onClick={() => setViewOpen(false)}
                    >
                        Salir
                    </button>
                </div>
            </div>
        </form>
    )
}

export default FEditCliente