import Layout from '../../components/admin/Layout';
import ListadoVentas from '../../components/admin/listadoVentas';

const Ventas = () => {
	return (
		<Layout
			pagina={"Ventas"}
		>
			<div className='w-full p-5 min-h-full'>
				<ListadoVentas/>
			</div>
		</Layout>
	)
}

export default Ventas