import { useContext } from 'react'
import ContextStates from '../context/ContextStates';
import Layout from '../components/Layout'
import FormVentas from '../components/forms/formVentas';
import FormJornadas from '../components/formJornadas';

const Index = () => {
    const { estadoJornada } = useContext(ContextStates)
    return (
        <Layout
            pagina="Inicio"
        >
            <main className='h-full'>
                <div className='w-full h-full bg-white'>
                    {estadoJornada === 0 ? (
                        <FormJornadas />
                    ) : (
                        <FormVentas />
                    )}
                </div>
            </main>

        </Layout>
    )
}

export default Index